/* ResponseRouter receives message from BE and calls callback by cmd field.
Callbacks should be registered in ResponseRouter. */
export default class ResponseRouter {
  constructor() {
    this.callbacks = {}
  }

  // register sets callback in callbacks field by cmd key
  register(cmd, callback, id) {
    // console.log(`register cmd: ${cmd} ${id}`);
    if (!cmd || typeof cmd !== 'string' || cmd.length === 0) {
      throw new Error('invalid cmd param')
    }

    if (!callback || typeof callback !== 'function') {
      throw new Error('invalid callback param')
    }

    if (id && typeof id === 'string' && id.length !== 0) {
      this.callbacks[`${cmd}_${id}`] = callback
      return
    }
    this.callbacks[cmd] = callback
  }

  // unregister sets stub function
  // If stub function is called, it will log that input cmd is unexpected
  unregister(cmd, id) {
    // console.log(`unregister cmd ${cmd}`);
    if (!cmd || typeof cmd !== 'string' || cmd.length === 0) {
      // console.log('invalid cmd param');
      return
    }
    if (id && typeof id === 'string' && id.length !== 0) {
      if (!this.callbacks[`${cmd}_${id}`]) {
        // console.log(`cmd ${cmd}_${id} hasn't been registered`);
      }
      delete this.callbacks[`${cmd}_${id}`]
      return
    }
    if (!this.callbacks[cmd]) {
      // console.log(`cmd ${cmd} hasn't been registered`);
      return
    }
    this.callbacks[cmd] = () => {
      // console.log(`unexpected cmd stub call: ${cmd}`);
    }
  }

  // onMessage is callback which is called when message from BE is received
  onMessage(msg) {
    const data = JSON.parse(msg)
    if (!data.cmd || typeof data.cmd !== 'string' || data.cmd.length === 0) {
      // console.log('invalid cmd param');
      return
    }
    this.call(data)
  }

  // call calls handle function from callbacks by cmd field
  call(data) {
    const { cmd } = data
    const cb = this.callbacks[cmd]
    if (!cb) {
      // console.log(`unexpected cmd call: ${cmd}`);
      return
    }
    cb(data)
  }
}

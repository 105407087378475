var digitsRange = { from: 48, to: 58 };
var latinUpperRange = { from: 65, to: 91 };
var latinLowerRange = { from: 97, to: 123 };
var randomNumberFromTo = function (min, max) { return Math.floor(Math.random() * (max - min) + min); };
var generateRandomSymbols = function () {
    var ranges = [digitsRange, latinUpperRange, latinLowerRange];
    var randomRange = ranges[Math.floor(Math.random() * ranges.length)];
    return ranges.map(function () { return String.fromCharCode(randomNumberFromTo(randomRange.from, randomRange.to)); }).join('');
};
export var randomId = function () {
    var timestamp = Date.now().toString().split('');
    return timestamp.map(function (el) { return "".concat(el).concat(generateRandomSymbols()); }).join('');
};

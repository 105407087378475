var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState } from 'react';
import { useActions } from './useActions';
var firstICECandidate = true;
export var useRTCAudio = function (_a) {
    var errorHandler = _a.errorHandler, configuration = _a.configuration, sendClientAnswer = _a.sendClientAnswer, roomId = _a.roomId, sendClientIceCandidate = _a.sendClientIceCandidate;
    var RTCPeerConnection = window.RTCPeerConnection, RTCSessionDescription = window.RTCSessionDescription;
    window.prc = window.prc || new RTCPeerConnection(configuration);
    var peerConnection = window.prc;
    var _b = useState(null), streams = _b[0], setStream = _b[1];
    var _c = useActions(), setIsOnCall = _c.setIsOnCall, setAgentOffer = _c.setAgentOffer, setAgentStopCall = _c.setAgentStopCall, setRingtoneRef = _c.setRingtoneRef;
    var getRemoteStream = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var remoteAudioStreamContainer, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    remoteAudioStreamContainer = new Audio();
                    remoteAudioStreamContainer.srcObject = event.streams[0];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remoteAudioStreamContainer.play()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    if (errorHandler)
                        errorHandler(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var peerStateChangeCb = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log('Peer con state changed:', peerConnection.iceConnectionState);
                    _a = peerConnection.iceConnectionState;
                    switch (_a) {
                        case 'failed': return [3 /*break*/, 1];
                        case 'disconnected': return [3 /*break*/, 1];
                        case 'connected': return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 4];
                case 1: return [4 /*yield*/, stopCall()];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    {
                        return [3 /*break*/, 5];
                    }
                    _b.label = 4;
                case 4:
                    {
                        return [3 /*break*/, 5];
                    }
                    _b.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var getMedia = function () { return __awaiter(void 0, void 0, void 0, function () {
        var mediaStream;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, navigator.mediaDevices.getUserMedia({ audio: true })];
                case 1:
                    mediaStream = _a.sent();
                    setStream(mediaStream);
                    peerConnection.oniceconnectionstatechange = peerStateChangeCb;
                    peerConnection.onicecandidate = gotIceCandidate;
                    peerConnection.onicecandidateerror = function (e) { return console.log(e); };
                    peerConnection.ontrack = getRemoteStream;
                    mediaStream.getTracks().forEach(function (track) {
                        peerConnection.addTrack(track, mediaStream);
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var gotIceCandidate = function (event) {
        var _a;
        if (event.candidate) {
            if (firstICECandidate) {
                firstICECandidate = false;
                sendClientAnswer({ sdp: peerConnection.localDescription.sdp, roomId: roomId });
            }
            else {
                sendClientIceCandidate({ ice: (_a = event.candidate) === null || _a === void 0 ? void 0 : _a.toJSON(), roomId: roomId });
            }
        }
    };
    var makeAnswer = function (offer) { return __awaiter(void 0, void 0, void 0, function () {
        var answer, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, getMedia()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, peerConnection.setRemoteDescription(new RTCSessionDescription(offer))];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, peerConnection.createAnswer()];
                case 3:
                    answer = _a.sent();
                    return [4 /*yield*/, peerConnection.setLocalDescription(answer)];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    error_2 = _a.sent();
                    if (errorHandler)
                        errorHandler(error_2);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var stopCall = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                if (streams)
                    streams.getTracks().forEach(function (s) { return s.stop(); });
                peerConnection.close();
                window.prc = null;
                setAgentStopCall(null);
                firstICECandidate = true;
                setRingtoneRef(null);
                setIsOnCall(false);
                setAgentOffer(null);
            }
            catch (error) {
                if (errorHandler)
                    errorHandler(error);
            }
            return [2 /*return*/];
        });
    }); };
    return {
        makeAnswer: makeAnswer,
        stopCall: stopCall,
    };
};

export var useImgDownload = function (cors) {
    if (cors === void 0) { cors = false; }
    var downloadImg = function (src) {
        var img = new Image();
        if (cors)
            img.crossOrigin = 'anonymous';
        img.src = src;
        try {
            img.addEventListener('load', function () {
                var a = document.createElement('a');
                a.href = src;
                a.target = '_blank';
                a.click();
            });
        }
        catch (error) {
            console.log(error);
        }
    };
    return { downloadImg: downloadImg };
};

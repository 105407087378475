export default {
    errorOverlay: {
        position: 'fixed',
        boxSizing: 'border-box',
        inset: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        color: 'rgb(232, 232, 232)',
        fontFamily: 'Menlo, Consolas, monospace, sans-serif',
        fontSize: 'large',
        padding: '2rem',
        lineHeight: 1.2,
        whiteSpace: 'pre-wrap',
        overflow: 'auto',
        zIndex: 2147483647,
    },
    message: {
        fontWeight: 'bold',
        color: 'rgb(227, 96, 73)',
    },
    stack: {
        fontFamily: 'monospace',
        marginTop: '2em',
    },
    frame: {
        marginTop: '1em',
    },
    file: {
        fontSize: '0.8em',
        color: 'rgba(255, 255, 255, 0.7)',
    },
    linkToFile: {
        textDecoration: 'none',
        color: 'rgba(255, 255, 255, 0.7)',
    },
};

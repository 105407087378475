var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSelector } from 'react-redux';
import { getStylesAgentMsg } from '@reducers/stylesReducer';
import { useActions } from '../../../../hooks/useActions';
import { ChoiceWrapper } from './styled';
export var Choice = function (_a) {
    var data = _a.data;
    var _b = useSelector(getStylesAgentMsg), messageAgentBorderColor = _b.messageAgentBorderColor, messageAgentBackgroundColor = _b.messageAgentBackgroundColor;
    var sendChoiceMessage = useActions().sendChoiceMessage;
    var lang = JSON.parse(sessionStorage.getItem('AskMeWebLibrary-setLocalLang'));
    return (React.createElement(ChoiceWrapper, __assign({}, { messageAgentBorderColor: messageAgentBorderColor, messageAgentBackgroundColor: messageAgentBackgroundColor }), data.choices.map(function (value, i) { return (React.createElement("div", { key: i, onClick: function () { return sendChoiceMessage({ value: value, lang: lang, id: data.id }); } }, value)); })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { workStatesActionType } from '@type';
export var WORK_APP_REDUCER_NAMESPACE = 'work-states';
var initialWorkAppState = {
    additionalInfo: { pageName: '', url: '' },
    savedClientInfo: {
        name: '',
        email: '',
        lastName: '',
        firstName: '',
        phoneNumber: '',
        invoiceNumber: '',
        accountNumber: '',
    },
    savedCurrentMessage: '',
    isVisibilityModalEndDialog: false,
    isErrorNotify: false,
    typeError: 0,
    isQuoting: {
        state: false,
        data: null,
    },
    isFocusOnChat: true,
    currentAuthStep: 1,
    formData: {},
    isMuted: false,
    captchaRef: null,
    captchaToken: null,
    contextMenuType: null,
};
// eslint-disable-next-line @typescript-eslint/default-param-last
export var workAppReducer = function (state, action) {
    if (state === void 0) { state = initialWorkAppState; }
    switch (action.type) {
        case workStatesActionType.SET_URL_AND_TITLE_PAGE: {
            return __assign(__assign({}, state), { additionalInfo: action.payload });
        }
        case workStatesActionType.SET_VISIBILITY_MODAL_IS_ERROR_NOTIFY: {
            var _a = action.payload, isErrorNotify = _a.isErrorNotify, typeError = _a.typeError;
            return __assign(__assign({}, state), { isErrorNotify: isErrorNotify, typeError: typeError });
        }
        case workStatesActionType.SET_SAVE_CLIENT_INFO: {
            return __assign(__assign({}, state), { savedClientInfo: action.payload });
        }
        case workStatesActionType.SET_SAVE_CURRENT_MESSAGE: {
            return __assign(__assign({}, state), { savedCurrentMessage: action.payload });
        }
        case workStatesActionType.SET_VISIBILITY_MODAL_END_DIALOG: {
            return __assign(__assign({}, state), { isVisibilityModalEndDialog: action.payload });
        }
        case workStatesActionType.SET_IS_FOCUS_ON_CHAT: {
            return __assign(__assign({}, state), { isFocusOnChat: action.payload });
        }
        case workStatesActionType.SET_CURRENT_AUTH_STEP: {
            return __assign(__assign({}, state), { currentAuthStep: action.payload });
        }
        case workStatesActionType.IS_QUOTING: {
            return __assign(__assign({}, state), { isQuoting: action.payload });
        }
        case workStatesActionType.SET_FORM_DATA: {
            return __assign(__assign({}, state), { formData: __assign(__assign({}, state.formData), action.payload) });
        }
        case workStatesActionType.SET_IS_MUTED: {
            return __assign(__assign({}, state), { isMuted: action.payload });
        }
        case workStatesActionType.SET_CAPTCHA_REF: {
            return __assign(__assign({}, state), { captchaRef: action.payload });
        }
        case workStatesActionType.SET_CAPTCHA_TOKEN: {
            return __assign(__assign({}, state), { captchaToken: action.payload });
        }
        case workStatesActionType.SET_CONTEXT_MENU_TYPE: {
            return __assign(__assign({}, state), { contextMenuType: action.payload });
        }
        default: {
            return state;
        }
    }
};
export var getAdditionalInfo = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].additionalInfo; };
export var getSavedClientInfo = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].savedClientInfo; };
export var getSavedCurrentMessage = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].savedCurrentMessage; };
export var getIsVisibilityModalEndDialog = function (state) {
    return state[WORK_APP_REDUCER_NAMESPACE].isVisibilityModalEndDialog;
};
export var getIsErrorNotify = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].isErrorNotify; };
export var getTypeError = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].typeError; };
export var getIsFocusOnChat = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].isFocusOnChat; };
export var getCurrentAuthStep = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].currentAuthStep; };
export var getIsQuoting = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].isQuoting; };
export var getFormData = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].formData; };
export var getIsMuted = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].isMuted; };
export var getCaptchaRef = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].captchaRef; };
export var getCaptchaToken = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].captchaToken; };
export var getContextMenuType = function (state) { return state[WORK_APP_REDUCER_NAMESPACE].contextMenuType; };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import omit from 'lodash/omit';
export var BaseUploader = memo(function (props) {
    var sx = props.sx, inputProps = props.inputProps, children = props.children;
    var _a = useDropzone(omit(props, ['sx', 'children'])), getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
    return (React.createElement(Box, __assign({ sx: sx }, getRootProps({ className: 'dropzone' })),
        React.createElement("input", __assign({}, getInputProps(inputProps))),
        children));
});

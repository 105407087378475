var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { animated, useSpring } from '@react-spring/web';
import { PortalContext } from '../../../PortalContext';
import { SkeletonCustom } from '../Skeleton/Skeleton';
import { ImageViewer } from './ImageViewer';
import { MemoizedLazyImage } from './LazyImage';
var Fade = React.forwardRef(function (props, ref) {
    var open = props.in, children = props.children, onEnter = props.onEnter, onExited = props.onExited, other = __rest(props, ["in", "children", "onEnter", "onExited"]);
    var style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: function () {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: function () {
            if (!open && onExited) {
                onExited();
            }
        },
    });
    return (React.createElement(animated.div, __assign({ ref: ref, style: style }, other), children));
});
export function Image(_a) {
    var src = _a.src, _b = _a.alt, alt = _b === void 0 ? 'ResponseBotImage' : _b, rest = __rest(_a, ["src", "alt"]);
    var _c = React.useState(false), open = _c[0], setOpen = _c[1];
    var portalContext = React.useContext(PortalContext);
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    React.useLayoutEffect(function () {
        var sandboxImg;
        if (src) {
            new Promise(function (resolve, reject) {
                sandboxImg = new window.Image();
                // @ts-ignore
                sandboxImg.crossOrigin = undefined;
                // eslint-disable-next-line unicorn/prefer-add-event-listener
                sandboxImg.onload = function (e) { return resolve(e); };
                // eslint-disable-next-line unicorn/prefer-add-event-listener
                sandboxImg.onerror = function (e) { return reject(e); };
                sandboxImg.src = src;
            })
                // eslint-disable-next-line promise/always-return
                .catch(console.error);
        }
        return function () {
            if (sandboxImg) {
                // @ts-ignore
                sandboxImg.onload = undefined;
                // @ts-ignore
                sandboxImg.onerror = undefined;
                sandboxImg = undefined;
            }
        };
    }, [src]);
    return (React.createElement(Stack, { alignItems: "center", sx: { width: '150px', height: '92px' } },
        React.createElement(MemoizedLazyImage, __assign({ src: src, alt: alt, loadingComponent: React.createElement(SkeletonCustom, null), onClick: handleOpen }, rest, { style: { height: '100%' } })),
        React.createElement(Modal, { disableAutoFocus: true, disableEnforceFocus: true, closeAfterTransition: true, open: open, onClose: handleClose, container: portalContext.current, style: {
                zIndex: 2147483647,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 'auto',
            }, BackdropProps: {
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
                timeout: 500,
            }, BackdropComponent: Backdrop },
            React.createElement(Fade, { in: open },
                React.createElement(ImageViewer, { src: src, handleClose: handleClose })))));
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSendFileType } from '@helpers/getSendFileType';
import { getMsgHistoryStore } from '@reducers/initializationReducer';
import { getStylesFooter } from '@reducers/stylesReducer';
import { getIsLoaderSendFile } from '@reducers/workWSReducer';
import { webSocketActionType, workStatesActionType } from '@type';
import { env } from '@mdca/wde';
import { Spinner } from '../../Body/Loader/Loader';
import { SendFileButtonInput, SendFileButtonInputLabel, SendFileButtonWrapper } from './StyledSendFileBox';
function SendFileBox() {
    var dispatch = useDispatch();
    var messagesHistory = useSelector(getMsgHistoryStore);
    var isLoaderSendFile = useSelector(getIsLoaderSendFile);
    var sendFileImage = useSelector(getStylesFooter).sendFileImage;
    var msgLength = messagesHistory.length;
    var onChangeHandler = React.useCallback(function (e) {
        var content = '';
        var file = e.target.files[0];
        var reader = new FileReader();
        var _a = file.type.split('/'), fileFormat = _a[1];
        if (file.size > 1024 * 1024 * 20) {
            dispatch({
                type: workStatesActionType.SET_VISIBILITY_MODAL_IS_ERROR_NOTIFY,
                payload: {
                    isErrorNotify: true,
                    typeError: 0,
                },
            });
            return;
        }
        if (msgLength < 1) {
            dispatch({
                type: workStatesActionType.SET_VISIBILITY_MODAL_IS_ERROR_NOTIFY,
                payload: {
                    isErrorNotify: true,
                    typeError: 1,
                },
            });
            return;
        }
        dispatch({
            type: workStatesActionType.SET_VISIBILITY_MODAL_IS_ERROR_NOTIFY,
            payload: {
                isErrorNotify: false,
                typeError: 0,
            },
        });
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            content = reader.result;
            dispatch({
                type: webSocketActionType.SEND_FILE,
                payload: { fileName: file.name, content: content, type: getSendFileType(fileFormat) },
            });
        };
    }, [messagesHistory.length]);
    var clearUploadInput = React.useCallback(function (e) { return (e.target.value = null); }, []);
    return (React.createElement(SendFileButtonWrapper, null,
        React.createElement(SendFileButtonInputLabel, { htmlFor: "upload-file" }, isLoaderSendFile ? (React.createElement(Spinner, null)) : (React.createElement("img", { src: sendFileImage || "".concat(env('ICON_WEB_UI'), "/paper-clip.svg"), alt: "Attach file" }))),
        React.createElement(SendFileButtonInput, { type: "file", name: "file", id: "upload-file", "data-testid": "uploader", onClick: clearUploadInput, onChange: onChangeHandler })));
}
export default SendFileBox;

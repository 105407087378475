export var checkObj = function (obj, el) {
    if (obj === void 0) { obj = {}; }
    if (obj === null)
        return;
    if (el === null)
        return;
    var check;
    Object.entries(obj).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        if (key === el) {
            check = value;
        }
    });
    return check;
};
export var changeChannelSettingsGreeting = function (greeting) {
    if (!greeting)
        return;
    var lang = greeting.map(function (el) { return [el.locale, { greeting: el.text, quickPhrases: el.phrases }]; });
    return Object.fromEntries(lang);
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var saveMessageStorage = function (msg, isLocalStorage) {
    var history = isLocalStorage
        ? localStorage.getItem('AskMeWebLibrary-chat-history')
        : sessionStorage.getItem('AskMeWebLibrary-chat-history');
    var historyMessages = history && JSON.parse(history);
    if (historyMessages) {
        var chatHistory = historyMessages && JSON.stringify(__spreadArray(__spreadArray([], historyMessages, true), [msg], false));
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isLocalStorage
            ? localStorage.setItem('AskMeWebLibrary-chat-history', chatHistory)
            : sessionStorage.setItem('AskMeWebLibrary-chat-history', chatHistory);
    }
    else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isLocalStorage
            ? localStorage.setItem('AskMeWebLibrary-chat-history', JSON.stringify([msg]))
            : sessionStorage.setItem('AskMeWebLibrary-chat-history', JSON.stringify([msg]));
    }
};
export var saveMessagesStorage = function (messages, gettingClientHistory) {
    if (!messages)
        return;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    gettingClientHistory === 'localStorage'
        ? localStorage.setItem('AskMeWebLibrary-chat-history', JSON.stringify(messages))
        : sessionStorage.setItem('AskMeWebLibrary-chat-history', JSON.stringify(messages));
};
export var getChatHistoryMessages = function (isLocalStorage) {
    var history = isLocalStorage
        ? localStorage.getItem('AskMeWebLibrary-chat-history')
        : sessionStorage.getItem('AskMeWebLibrary-chat-history');
    var messages = history && JSON.parse(history);
    if (messages === null) {
        return [];
    }
    return messages;
};

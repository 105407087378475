import React from 'react';
import Linkify from 'react-linkify';
import { DownloadFile } from './DownloadFile/DownloadFile';
import { Image } from './Image';
export var linkifyComponentDecorator = function (decoratedHref, decoratedText, key) { return (React.createElement("a", { href: decoratedHref, key: key, rel: "noopener noreferrer", target: "_blank" }, decoratedText)); };
export var printMessage = function (args) {
    var text = args.text, type = args.type;
    switch (type) {
        case 0: {
            return (React.createElement("div", { className: "message-container" },
                React.createElement(Linkify, { componentDecorator: linkifyComponentDecorator }, text)));
        }
        case 1: {
            return (React.createElement("div", { className: "message-container" },
                React.createElement(Linkify, { componentDecorator: linkifyComponentDecorator }, text)));
        }
        case 3: {
            return React.createElement(Image, { src: text });
        }
        case 7: {
            return (React.createElement("div", { className: "message-container" },
                React.createElement(Linkify, { componentDecorator: linkifyComponentDecorator }, text)));
        }
        case 6: {
            return React.createElement(DownloadFile, { text: text });
        }
        case 8: {
            return React.createElement("span", null, "This dialogue was finished");
        }
        case 10: {
            return (React.createElement("div", { className: "message-container" },
                React.createElement(Linkify, { componentDecorator: linkifyComponentDecorator }, text)));
        }
        default: {
            return React.createElement("span", null, "Oops something went wrong");
        }
    }
};

import { useSelector } from 'react-redux';
import { getRingtoneRef } from '@reducers/callReducer';
import { getIsMuted } from '@reducers/workAppReducer';
import { useActions } from './useActions';
export var useMuted = function () {
    var setIsMuted = useActions().setIsMuted;
    var isMuted = useSelector(getIsMuted);
    var r = useSelector(getRingtoneRef);
    var mutedState = localStorage.getItem('AskMeWebLibrary-isMuted');
    if (mutedState)
        setIsMuted(JSON.parse(mutedState));
    var setMute = function () {
        setIsMuted(!isMuted);
        if (r && !isMuted)
            r.volume = 0;
        if (r && isMuted)
            r.volume = 1;
        localStorage.setItem('AskMeWebLibrary-isMuted', JSON.stringify(!isMuted));
    };
    return { isMuted: isMuted, setMute: setMute };
};

export var sendMessageHandler = function (lang, sendNewMessageSagaActions) {
    return function (value) {
        if (value.trim() === '')
            return;
        // eslint-disable-next-line no-param-reassign
        value = value
            .split('\n')
            .filter(function (item) { return item !== ''; })
            .join('\n');
        sendNewMessageSagaActions({ value: value, lang: lang });
    };
};
export var getTime = function () {
    var date = new Date();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    if (hour < 10)
        hour = "0".concat(hour);
    if (minutes < 10)
        minutes = "0".concat(minutes);
    return "".concat(hour, ":").concat(minutes);
};
export var generateString = function (len) {
    var chrs = 'abdehkmnpswxzABDEFGHKMNPQRSTWXZ123456789';
    var str = '';
    // eslint-disable-next-line no-plusplus
    for (var i = 0; i < len; i++) {
        var pos = Math.floor(Math.random() * chrs.length);
        // eslint-disable-next-line unicorn/prefer-string-slice
        str += chrs.substring(pos, pos + 1);
    }
    return str;
};
export var setAlignmentPosition = function (alignment, currentSize, isStorageDisable) {
    var verticalAlignment = alignment.verticalAlignment, horizontalAlignment = alignment.horizontalAlignment, verticalAlignmentType = alignment.verticalAlignmentType, horizontalAlignmentType = alignment.horizontalAlignmentType;
    var windowWidth = document.documentElement.clientWidth;
    var windowHeight = document.documentElement.clientHeight;
    var rightPosition = horizontalAlignmentType === 'right' ? horizontalAlignment : windowWidth - horizontalAlignment - currentSize.width;
    var bottomPosition = verticalAlignmentType === 'bottom' ? verticalAlignment : windowHeight - verticalAlignment - currentSize.height;
    return { rightPosition: rightPosition, bottomPosition: bottomPosition };
};
export var getAlignmentSettings = function (alignment, externalConfig, currentSize) {
    if (alignment) {
        var currentAlignment = setAlignmentPosition(alignment, currentSize);
        return {
            chatIcon: currentAlignment,
            chatWindow: currentAlignment,
        };
    }
    if (externalConfig && Object.keys(externalConfig).length > 0) {
        return {
            chatIcon: externalConfig.chatIconAlignment
                ? setAlignmentPosition(externalConfig.chatIconAlignment, currentSize, true)
                : {},
            chatWindow: externalConfig.chatIconAlignment
                ? setAlignmentPosition(externalConfig.chatWindowAlignment, currentSize)
                : {},
        };
    }
    return {
        chatIcon: {},
        chatWindow: {},
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { initializationActionTypes, webSocketActionType } from '@type';
export var INITIALIZATION_REDUCER_NAMESPACE = 'initialization';
var initialAppState = {
    channelSettings: null,
    objReqField: {},
    greetingAndQuickPhrases: null,
    responseUserID: null,
    agentInfo: {},
    isAuthConnection: false,
    privacyPolicy: {},
    clientExternalId: '',
    clientInfo: {},
    gettingClientHistory: '',
    msgHistory: [],
    isClientBanned: false,
    isLoadingMessages: false,
};
export var initializationReducer = function (state, action) {
    if (state === void 0) { state = initialAppState; }
    switch (action.type) {
        case initializationActionTypes.SET_SETTINGS_CHANNEL: {
            return __assign(__assign({}, state), { channelSettings: action.payload });
        }
        case initializationActionTypes.SET_PRIVACY_POLICY: {
            return __assign(__assign({}, state), { privacyPolicy: action.payload });
        }
        case initializationActionTypes.SET_GETTING_CLIENT_HISTORY: {
            return __assign(__assign({}, state), { gettingClientHistory: action.payload });
        }
        case initializationActionTypes.SAVE_CLIENT_INFO: {
            return __assign(__assign({}, state), { clientInfo: action.payload });
        }
        case initializationActionTypes.SET_IS_CLIENT_BANNED: {
            return __assign(__assign({}, state), { isClientBanned: action.payload });
        }
        case initializationActionTypes.SET_CLIENT_EXTERNAL_ID: {
            return __assign(__assign({}, state), { clientExternalId: action.payload });
        }
        case webSocketActionType.SET_MESSAGES_HISTORY: {
            return __assign(__assign({}, state), { msgHistory: action.payload || [] });
        }
        case webSocketActionType.SET_MESSAGE: {
            return __assign(__assign({}, state), { msgHistory: __spreadArray(__spreadArray([], state.msgHistory, true), [action.payload], false) });
        }
        case initializationActionTypes.PARS_REQUIRED_FIELDS_BRAND: {
            return __assign(__assign({}, state), { objReqField: action.payload });
        }
        case initializationActionTypes.PARS_GREETING_AND_QUICK_PHRASES: {
            return __assign(__assign({}, state), { greetingAndQuickPhrases: action.payload });
        }
        case initializationActionTypes.SET_RESPONSE_USER_ID: {
            return __assign(__assign({}, state), { responseUserID: action.payload });
        }
        case initializationActionTypes.SET_AGENT_INFO: {
            return __assign(__assign({}, state), { agentInfo: action.payload });
        }
        case initializationActionTypes.SET_IS_AUTH_CONNECTION: {
            return __assign(__assign({}, state), { isAuthConnection: action.payload });
        }
        case initializationActionTypes.SET_IS_LOADING_MESSAGES: {
            return __assign(__assign({}, state), { isLoadingMessages: action.payload });
        }
        default: {
            return state;
        }
    }
};
export var getInfoAgentStore = function (state) { return state[INITIALIZATION_REDUCER_NAMESPACE].agentInfo; };
export var getResponseUserID = function (state) { return state[INITIALIZATION_REDUCER_NAMESPACE].responseUserID; };
export var getIsClientBanned = function (state) { return state[INITIALIZATION_REDUCER_NAMESPACE].isClientBanned; };
export var getChannelSetting = function (state) {
    return state[INITIALIZATION_REDUCER_NAMESPACE].channelSettings;
};
export var getIsAuthConnection = function (state) { return state[INITIALIZATION_REDUCER_NAMESPACE].isAuthConnection; };
export var getObjReqFieldChanel = function (state) { return state[INITIALIZATION_REDUCER_NAMESPACE].objReqField; };
export var getGreetingAndQuickPhrases = function (state) {
    return state[INITIALIZATION_REDUCER_NAMESPACE].greetingAndQuickPhrases;
};
export var getPrivacyPolicy = function (state) {
    return state[INITIALIZATION_REDUCER_NAMESPACE].privacyPolicy;
};
export var getGettingClientHistory = function (state) {
    return state[INITIALIZATION_REDUCER_NAMESPACE].gettingClientHistory;
};
export var getMsgHistoryStore = function (state) { return state[INITIALIZATION_REDUCER_NAMESPACE].msgHistory; };
export var getClientExternalId = function (state) { return state[INITIALIZATION_REDUCER_NAMESPACE].clientExternalId; };
export var getClientInfo = function (state) { return state[INITIALIZATION_REDUCER_NAMESPACE].clientInfo; };
export var getIsLoadingMessages = function (state) { return state[INITIALIZATION_REDUCER_NAMESPACE].isLoadingMessages; };
export var getIsFinishedDialogue = function (state) {
    var _a;
    var msgs = state[INITIALIZATION_REDUCER_NAMESPACE].msgHistory;
    return msgs.at(-1) && ((_a = msgs.at(-1)) === null || _a === void 0 ? void 0 : _a.data.type) === 8;
};

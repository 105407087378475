var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { initializationActionTypes } from '@type';
import { createSelector } from 'reselect';
export var STYLES_REDUCER_NAMESPACE = 'styles';
var initialStylesState = {
    icon: {},
    auth: {},
    header: {},
    scroll: {},
    chatWindow: {},
    greeting: {},
    quickPhrases: {},
    clientMsg: {},
    agentMessage: {},
    footer: {},
    typing: {},
    endDialogModal: {},
    moreMenu: {},
};
// eslint-disable-next-line @typescript-eslint/default-param-last
export var customStyleReducer = function (state, action) {
    if (state === void 0) { state = initialStylesState; }
    switch (action.type) {
        case initializationActionTypes.SET_CUSTOM_STYLES: {
            return __assign(__assign({}, state), { icon: action.payload.icon || {}, auth: action.payload.auth || {}, header: action.payload.header || {}, scroll: action.payload.scroll || {}, footer: action.payload.footer || {}, greeting: action.payload.greeting || {}, moreMenu: action.payload.moreMenu || {}, agentMessage: action.payload.agentMessage || {}, clientMsg: action.payload.clientMsg || {}, chatWindow: action.payload.chatWindow || {}, quickPhrases: action.payload.quickPhrases || {}, endDialogModal: action.payload.endDialogModal || {} });
        }
        default: {
            return state;
        }
    }
};
var getHeader = function (state) { return state[STYLES_REDUCER_NAMESPACE].header; };
export var getStylesIcon = function (state) { return state[STYLES_REDUCER_NAMESPACE].icon; };
export var getStylesAuth = function (state) { return state[STYLES_REDUCER_NAMESPACE].auth; };
export var getStylesHeader = createSelector(getHeader, function (header) {
    if (header.disableHeaderLogo === undefined) {
        // eslint-disable-next-line no-param-reassign
        header.disableHeaderLogo = true;
    }
    if (header.disableDraggable === undefined) {
        // eslint-disable-next-line no-param-reassign
        header.disableDraggable = true;
    }
    if (header.disableHeaderFullScreen === undefined) {
        // eslint-disable-next-line no-param-reassign
        header.disableHeaderFullScreen = true;
    }
    if (header.disableHeaderHideChatWindow === undefined) {
        // eslint-disable-next-line no-param-reassign
        header.disableHeaderHideChatWindow = true;
    }
    if (header.disableHeaderShowEndDialogModal === undefined) {
        // eslint-disable-next-line no-param-reassign
        header.disableHeaderShowEndDialogModal = true;
    }
    if (header.headerHeight === undefined) {
        // eslint-disable-next-line no-param-reassign
        header.headerHeight = '60px';
    }
    return __assign({}, header);
});
export var getStylesFooter = function (state) { return state[STYLES_REDUCER_NAMESPACE].footer; };
export var getStylesMoreMenu = function (state) { return state[STYLES_REDUCER_NAMESPACE].moreMenu; };
export var getStylesScroll = function (state) { return state[STYLES_REDUCER_NAMESPACE].scroll; };
export var getStylesGreeting = function (state) { return state[STYLES_REDUCER_NAMESPACE].greeting; };
export var getStylesAgentMsg = function (state) { return state[STYLES_REDUCER_NAMESPACE].agentMessage; };
export var getStylesClientMsg = function (state) { return state[STYLES_REDUCER_NAMESPACE].clientMsg; };
export var getStylesChatWindow = function (state) { return state[STYLES_REDUCER_NAMESPACE].chatWindow; };
export var getStylesQuickPhrases = function (state) {
    return state[STYLES_REDUCER_NAMESPACE].quickPhrases;
};
export var getStylesEndDialogModal = function (state) {
    return state[STYLES_REDUCER_NAMESPACE].endDialogModal;
};

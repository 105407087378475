import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import localization from '@localization';
import { getIsFinishedDialogue } from '@reducers/initializationReducer';
import { getStylesMoreMenu } from '@reducers/stylesReducer';
import { webSocketActionType } from '@type';
import { env } from '@mdca/wde';
import { StyledMenu } from './StyledMenu';
export var Menu = function (_a) {
    var lang = _a.lang, showMenu = _a.showMenu, isFullScreen = _a.isFullScreen, setShowMenu = _a.setShowMenu, setMute = _a.setMute, toggleFullScreen = _a.toggleFullScreen, isMuted = _a.isMuted, isAuthHeader = _a.isAuthHeader;
    var dispatch = useDispatch();
    var isFinishedDialogue = useSelector(getIsFinishedDialogue);
    var ref = useRef(null);
    var _b = useSelector(getStylesMoreMenu), exitIcon = _b.exitIcon, muteIcon = _b.muteIcon, textColor = _b.textColor, unionIcon = _b.unionIcon, unMuteIcon = _b.unMuteIcon, backdropColor = _b.backdropColor, backdropItemHoverColor = _b.backdropItemHoverColor;
    var handleLogOut = function () {
        setShowMenu(!showMenu);
        if (isFinishedDialogue)
            return;
        dispatch({ type: webSocketActionType.END_DIALOG });
    };
    var handleFullScreen = function () {
        toggleFullScreen();
        setShowMenu(!showMenu);
    };
    var handleSetMute = function () {
        setMute();
        setShowMenu(!showMenu);
    };
    return (React.createElement(StyledMenu, { lang: lang, isAuthHeader: isAuthHeader, ref: ref, state: showMenu, textColor: textColor, backdropColor: backdropColor, backdropItemHoverColor: backdropItemHoverColor },
        React.createElement("div", { className: "AskMeWebLibrary-header-menu__item", onClick: handleSetMute },
            React.createElement("img", { src: isMuted ? muteIcon || "".concat(env('ICON_WEB_UI'), "/unMute.svg") : unMuteIcon || "".concat(env('ICON_WEB_UI'), "/mute.svg"), alt: "mute state" }),
            isMuted ? localization[lang]['menu.unmute'] : localization[lang]['menu.mute']),
        React.createElement("div", { className: "AskMeWebLibrary-header-menu__item", onClick: handleFullScreen },
            React.createElement("div", { className: "AskMeWebLibrary-header-menu__item-fullscreen" },
                React.createElement("img", { src: unionIcon || "".concat(env('ICON_WEB_UI'), "/union.svg"), alt: "" }),
                isFullScreen && React.createElement("img", { src: unionIcon || "".concat(env('ICON_WEB_UI'), "/union.svg"), alt: "" })),
            isFullScreen ? localization[lang]['menu.windowedMode'] : localization[lang]['menu.fullscreen']),
        !isAuthHeader && (React.createElement("div", { className: "AskMeWebLibrary-header-menu__item", onClick: handleLogOut },
            React.createElement("img", { src: exitIcon || "".concat(env('ICON_WEB_UI'), "/exit.svg"), alt: "" }),
            localization[lang]['menu.logOut']))));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// eslint-disable-next-line unicorn/filename-case
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import fastDeepEqual from 'fast-deep-equal';
import applySpec from 'ramda/es/applySpec';
import { rawResizableActions } from '../../../redux/actions/resizable';
import { selectChatWindowPadding, selectCurrentPosition, selectCurrentSize, selectIsFullScreen, selectWindowCurrentHeight, selectWindowCurrentWidth, } from '../../../redux/selectors/resizable';
import { Resizable } from './Resizable';
import WindowHOC from './WindowHOC';
var selector = applySpec({
    currentSize: selectCurrentSize,
    isFullScreen: selectIsFullScreen,
    chatWindowPadding: selectChatWindowPadding,
    windowCurrentWidth: selectWindowCurrentWidth,
    windowCurrentHeight: selectWindowCurrentHeight,
    currentPosition: selectCurrentPosition,
});
export function WindowWebUI(props) {
    var dispatch = useDispatch();
    var _a = useSelector(selector, fastDeepEqual), currentSize = _a.currentSize, isFullScreen = _a.isFullScreen, windowCurrentWidth = _a.windowCurrentWidth, windowCurrentHeight = _a.windowCurrentHeight, chatWindowPadding = _a.chatWindowPadding, currentPosition = _a.currentPosition;
    var handleSetSize = React.useCallback(function (size) {
        dispatch(rawResizableActions.setCurrentSize(size));
    }, [dispatch]);
    var handleSetPosition = React.useCallback(function (position) {
        dispatch(rawResizableActions.setCurrentPosition(position));
    }, [dispatch]);
    return (React.createElement("div", null,
        React.createElement(Resizable, { isMobile: isMobile, chatWindowPadding: chatWindowPadding, onSetPosition: handleSetPosition, onSetSize: handleSetSize, fullscreen: isFullScreen, size: currentSize, position: currentPosition, style: {}, windowWidth: windowCurrentWidth, windowHeight: windowCurrentHeight, className: "sbu-Resizable sbu-Chat-window" },
            React.createElement("div", { style: {
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
                } },
                React.createElement(WindowHOC, __assign({}, props))))));
}

import { callActionType, initializationActionTypes, webSocketActionType, workStatesActionType } from '@type';
export var initializationActions = {
    setMsgHistory: function (msgHistory) { return ({
        type: webSocketActionType.SET_MESSAGES_HISTORY,
        payload: msgHistory,
    }); },
    setPrivacyPolicy: function (privacyPolicy) { return ({
        type: initializationActionTypes.SET_PRIVACY_POLICY,
        payload: privacyPolicy,
    }); },
    setChannelSettings: function (channel) { return ({
        type: initializationActionTypes.SET_SETTINGS_CHANNEL,
        payload: channel,
    }); },
    setGettingClientHistory: function (gettingClientHistory) { return ({
        type: initializationActionTypes.SET_GETTING_CLIENT_HISTORY,
        payload: gettingClientHistory,
    }); },
    setIsClientBanned: function (isBanned) { return ({
        type: initializationActionTypes.SET_IS_CLIENT_BANNED,
        payload: isBanned,
    }); },
    setRequiredFields: function (requiredFields) { return ({
        type: initializationActionTypes.PARS_REQUIRED_FIELDS_BRAND,
        payload: requiredFields,
    }); },
    setClientId: function (clientId) { return ({
        type: initializationActionTypes.SET_CLIENT_EXTERNAL_ID,
        payload: clientId,
    }); },
    getClientInfo: function () { return ({
        type: initializationActionTypes.GET_STORAGE_CLIENT_INFO,
    }); },
    saveClientInfo: function (clientInfo) { return ({
        type: initializationActionTypes.SAVE_CLIENT_INFO,
        payload: clientInfo,
    }); },
    setGreetingAndQuickPhrases: function (greeting) { return ({
        type: initializationActionTypes.PARS_GREETING_AND_QUICK_PHRASES,
        payload: greeting,
    }); },
    setResponseAuthUserID: function (userID) { return ({
        type: initializationActionTypes.SET_RESPONSE_USER_ID,
        payload: userID,
    }); },
    setAgent: function (agent) { return ({
        type: initializationActionTypes.SET_AGENT_INFO,
        payload: agent,
    }); },
    setCustomStyles: function (customizations) { return ({
        type: initializationActionTypes.SET_CUSTOM_STYLES,
        payload: customizations,
    }); },
    setIsAuthConnection: function (bool) { return ({
        type: initializationActionTypes.SET_IS_AUTH_CONNECTION,
        payload: bool,
    }); },
    setIsLoadingMessages: function (bool) { return ({
        type: initializationActionTypes.SET_IS_LOADING_MESSAGES,
        payload: bool,
    }); },
};
export var webSocketActions = {
    setMsg: function (message) { return ({
        type: webSocketActionType.SET_MESSAGE,
        payload: message,
    }); },
    getNewMessage: function (message) { return ({
        type: webSocketActionType.GET_MESSAGE,
        payload: message,
    }); },
    getAgentFile: function (payload) { return ({
        type: webSocketActionType.GET_AGENT_FILE,
        payload: payload,
    }); },
    setIsConnectedWS: function (bool) { return ({
        type: webSocketActionType.SET_IS_CONNECTED_WEB_SOCKET,
        payload: bool,
    }); },
    sendNewMessageSagaActions: function (payload) { return ({
        type: webSocketActionType.SEND_NEW_MESSAGE_SAGA,
        payload: payload,
    }); },
    updateMessageStatusActions: function (status) { return ({
        type: webSocketActionType.UPDATE_MESSAGE_STATUS_SAGA,
        payload: status,
    }); },
    updateClientOnlineStatusActions: function (status) { return ({
        type: webSocketActionType.UPDATE_CLIENT_ONLINE_STATUS_SAGA,
        payload: status,
    }); },
    errorResponseServer: function (value) { return ({
        type: webSocketActionType.ERROR_CONNECTION_SAGA,
        payload: value,
    }); },
    sendClientTyping: function () { return ({ type: webSocketActionType.SEND_CLIENT_TYPING }); },
    setAgentOffer: function (data) { return ({
        type: callActionType.SET_AGENT_OFFER,
        payload: data,
    }); },
    gotAgentOffer: function (data) { return ({
        type: callActionType.GOT_AGENT_OFFER,
        payload: data,
    }); },
    setAgentStopCall: function (data) { return ({
        type: callActionType.SET_AGENT_STOP_CALL,
        payload: data,
    }); },
    sendClientAnswer: function (data) { return ({
        type: callActionType.SEND_CLIENT_ANSWER,
        payload: data,
    }); },
    sendClientIceCandidate: function (data) { return ({
        type: callActionType.SEND_CLIENT_ICE_CANDIDATE,
        payload: data,
    }); },
    gotAgentIceCandidate: function (data) { return ({
        type: callActionType.GOT_AGENT_ICE_CANDIDATE,
        payload: data,
    }); },
    setRingtoneRef: function (data) { return ({
        type: callActionType.CLIENT_ANSWERED,
        payload: data,
    }); },
    setIsOnCall: function (data) { return ({
        type: callActionType.SET_IS_ON_CALL,
        payload: data,
    }); },
    sendChoiceMessage: function (data) { return ({
        type: webSocketActionType.SEND_CHOICE_MESSAGE,
        payload: data,
    }); },
};
export var workStatesActions = {
    setIsQuoting: function (isQuoting) { return ({
        type: workStatesActionType.IS_QUOTING,
        payload: isQuoting,
    }); },
    setFormData: function (formData) { return ({
        type: workStatesActionType.SET_FORM_DATA,
        payload: formData,
    }); },
    setCurrentAuthStep: function (currentStep) { return ({
        type: workStatesActionType.SET_CURRENT_AUTH_STEP,
        payload: currentStep,
    }); },
    setIsMuted: function (isMuted) { return ({
        type: workStatesActionType.SET_IS_MUTED,
        payload: isMuted,
    }); },
    setCaptchaRef: function (ref) { return ({
        type: workStatesActionType.SET_CAPTCHA_REF,
        payload: ref,
    }); },
    setCaptchaToken: function (token) { return ({
        type: workStatesActionType.SET_CAPTCHA_TOKEN,
        payload: token,
    }); },
    setContextMenuType: function (type) { return ({
        type: workStatesActionType.SET_CONTEXT_MENU_TYPE,
        payload: type,
    }); },
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { sessionStorageItems } from '@constants';

import { env } from '@mdca/wde';

// eslint-disable-next-line import/extensions
import { App } from './App.tsx';
import { inject } from './load-script';
import hideKeyboard from './logic';

const head = document.querySelectorAll('head')[0];
const meta = document.createElement('meta');

meta.name = 'viewport';
meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

head.append(meta);

const body = document.querySelectorAll('body')[0];

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

window.addEventListener('orientationchange', () => hideKeyboard(body), false);

window.AskMeChatBot = {
  init: async ({ locale = 'en', configUrl, userInfo, configWebUi, alignment, externalConfig }) => {
    const chat = document.querySelector('#AskMeChatBot');

    if (chat) {
      chat.remove();
    }

    const block = document.createElement('div');
    block.id = 'AskMeChatBot';
    body.append(block);

    const nodes = document.querySelectorAll('script');
    // @ts-expect-error: noop
    const url = [...nodes]
      .map((item) => item.attributes.getNamedItem('src')?.value)
      .find((item) => String(item).includes('admin-ask.globalcloudteam.com/web-askme'));

    const isDev = process.env.NODE_ENV === 'development';
    const baseUrl = url ? new URL(url) : { origin: isDev ? `http://localhost:${process.env.WEB_PORT}` : '' };
    const envScriptUrl = `${baseUrl.origin}${process.env.PUBLIC_URL}/__widget_env.js`;

    try {
      await inject(envScriptUrl);
      // await inject(env('STYLE_URL'), { type: 'css' })
    } catch (error) {
      console.error(error);
    }

    let parsResponseCustom = {};

    if (configUrl) {
      const responseCustom = await fetch(configUrl);
      parsResponseCustom = await responseCustom.json();
    }

    if (!configWebUi.keyBrand) {
      console.error({
        initialStatus: 'error',
        error: 'Brand key is not defined',
      });

      throw new Error('Brand key is not defined');
    }

    const apiUrl = `${env('GET_CHANNEL_URL') || ''}/key?key=${configWebUi.keyBrand}`;

    try {
      const response = await fetch(apiUrl);
      const requestData = await response.json();

      if (requestData?.error) {
        console.error({ initialStatus: 'error', error: 'Brand key is not valid' });

        throw new Error('Brand key is not valid');
      }

      const initResult = {
        enabledLang: requestData?.enabledLang,
        initialStatus: 'success',
      };

      const root = createRoot(block);

      root.render(
        <App
          userInfo={userInfo}
          lang={locale}
          settings={parsResponseCustom}
          configWebUi={configWebUi}
          externalConfig={externalConfig}
          alignment={alignment}
        />,
      );

      return initResult;
    } catch (error) {
      console.error(error);
      throw new Error('Widget unknown error');
    }
  },

  clearHistory: () => {
    sessionStorageItems.forEach((item) => {
      sessionStorage.removeItem(item);
    });
  },

  onLogout: () => {
    const chat = document.querySelector('#AskMeChatBot');
    chat?.remove();
    sessionStorageItems.forEach((item) => {
      sessionStorage.removeItem(item);
    });
  },

  getParentContainer: () => {
    const root = document.querySelector('#AskMeChatBot');
    if (root == null) return null;
    const child = root.firstChild;
    if (child == null) return null;
    if (child.classList.contains('AskMeWebLibrary-Chat-window')) return child;
    return null;
  },

  getShowingState: () =>
    JSON.parse(sessionStorage.getItem('AskMeWebLibrary-isEnabled'))?.isEnabled ? 'opened' : 'closed',

  setLocale: (local) => {
    const changeLang = () =>
      new Promise((resolve) => {
        sessionStorage.setItem('AskMeWebLibrary-setLocalLang', JSON.stringify(local));
        resolve(local);
      });
    // eslint-disable-next-line promise/catch-or-return
    changeLang().then(() => {
      // eslint-disable-next-line promise/always-return
      if (window.AskMeChatBot.checkLocalLang === undefined) {
        setTimeout(() => window.AskMeChatBot.setLocale(local), 1);
        return;
      }
      window.AskMeChatBot.checkLocalLang();
    });
  },

  getLocale: async (keyBrand) => {
    if (!keyBrand) {
      return;
    }
    try {
      const url = `${env('GET_CHANNEL_URL')}/key?key=${keyBrand}`;
      const response = await fetch(url);
      const requestData = await response.json();

      // eslint-disable-next-line consistent-return
      return requestData?.enabledLang;
    } catch (error) {
      console.error(error);
    }
  },

  version: process.env.APP_VERSION || '0.0.0',
};
//
// window.AskMeChatBot.init({
//   configWebUi: {
//     keyBrand: '_111111111_777777777',
//   },
//   locale: 'en',
//   // userInfo: {
//   //   userEmail:'qwea14asfWQRQdasasasdrty@mail.com',
//   //   userName:'qqqqqqqqqq',
//   //   firstName: 'wwwwwwwww',
//   //   userId: 'wwwwwwwasdsafafww',
//   //   phone: 'eeeeeeeee',
//   //   lastName: '4rrrrrrrrrr',
//   //   invoiceNumber: '4rrrrrrrrrr',
//   //   accountNumber: '4rrrradsasdadsrrrrrr',
//   // },
//
//   // alignment: {
//   //   verticalAlignment: 200,
//   //   horizontalAlignment: 100,
//   //   verticalAlignmentType: "bottom",
//   //   horizontalAlignmentType: "left"
//   // }
//   externalConfig: {
//     chatWindowAlignment: {
//       verticalAlignment: 10,
//       horizontalAlignment: 10,
//       verticalAlignmentType: 'bottom',
//       horizontalAlignmentType: 'right',
//     },
//     chatIconAlignment: {
//       verticalAlignment: 95,
//       horizontalAlignment: 45,
//       verticalAlignmentType: 'bottom',
//       horizontalAlignmentType: 'right',
//     },
//     chatMobileWindowGap: {
//       top: 100,
//       right: 20,
//       bottom: 100,
//       left: 20,
//     },
//     icon: {
//       iconBackground: 'blue',
//     },
//   },
// });

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGreetingAndQuickPhrases, getIsAuthConnection } from '@reducers/initializationReducer';
import { webSocketActionType } from '@type';
import ButtonQuick from './ButtonQuick/ButtonQuick';
import { translateQuickPhrases } from './localReply';
import './QuickReplyButtons.less';
var QuickReplyButtons = function (_a) {
    var lang = _a.lang;
    var dispatch = useDispatch();
    var isAuthConnection = useSelector(getIsAuthConnection);
    var QuickPhrases = useSelector(getGreetingAndQuickPhrases);
    var translateQuickPhrasesArr = translateQuickPhrases(lang, QuickPhrases);
    var sendReply = function (elem) {
        var data = {
            quickReply: elem,
            lang: lang,
        };
        dispatch({ type: webSocketActionType.SEND_QUICK_REPLY_SAGA, payload: data });
    };
    var quickPhrasesComponent = translateQuickPhrasesArr.map(function (elem, i) { return (React.createElement(ButtonQuick, { key: i, text: elem, onClick: sendReply })); });
    return (React.createElement(React.Fragment, null, isAuthConnection && (React.createElement("div", null,
        React.createElement("div", { className: "AskMeWebLibrary-body__user-message AskMeWebLibrary-user-message" },
            React.createElement("div", { className: "AskMeWebLibrary-quick-reply-buttons" }, quickPhrasesComponent))))));
};
export default QuickReplyButtons;

import React from 'react';
import root from 'react-shadow';
import { env } from '@mdca/wde';
export function ShadowDomContainer(_a) {
    var children = _a.children, isShadowDom = _a.isShadowDom;
    // @ts-ignore
    if (document.head.createShadowRoot || (!!document.head.attachShadow && isShadowDom)) {
        return (React.createElement(root.div, { id: "shadow-host", mode: "open" },
            process.env.IS_DEV ? (React.createElement("link", { rel: "stylesheet", type: "text/css", href: "http://localhost:3003/style.css" })) : (React.createElement("link", { rel: "stylesheet", type: "text/css", href: env('STYLE_URL') })),
            children));
    }
    return (React.createElement("div", null,
        React.createElement("link", { rel: "stylesheet", type: "text/css", href: env('STYLE_URL') }),
        children));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { Main } from './components/Main';
import { ThemeProvider } from './components/theme/ThemeProvider';
import store from './redux/store';
export function App(props) {
    var _a = React.useState(true), shadowDom = _a[0], setIsShadowDom = _a[1];
    React.useEffect(function () {
        var url = new URL(window.location.href);
        var queryParams = url.searchParams;
        var isShadowDom = queryParams.get('isShadowDom');
        if (isShadowDom === 'false') {
            setIsShadowDom(false);
        }
    }, []);
    return (React.createElement(ThemeProvider, { isShadowDom: shadowDom },
        React.createElement(Provider, { store: store },
            React.createElement(Main, __assign({}, props, { isShadowDom: shadowDom })))));
}

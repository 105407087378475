var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { initializationActions, webSocketActions, workStatesActions } from '@actions';
import * as chatWSAPI from '@api/chatWS';
import { getAgents } from '@api/fetchResponse';
import { createMsgOfWS, mappingMsgArr } from '@helpers/createMessages';
import { getUniqueAgentsId } from '@helpers/getUniqueAgentsId';
import { isEnumHasThatValue } from '@helpers/isEnumHasThatValue';
import { agentLocalesFromBrandsLocales } from '@helpers/sagaHelpers/agentLocalesFromBrandsLocales';
import { findAndCompleteChoiceMessage } from '@helpers/sagaHelpers/findAndCompleteChoiceMessage';
import { getLastChoiceMessage } from '@helpers/sagaHelpers/getLastChoiceMessage';
import { isNeedToChangeAgentsInfo } from '@helpers/sagaHelpers/isNeedToChangeAgentsInfo';
import { findAndCheckDeletedMessage } from '@helpers/sagaHelpers/onDeleteMessage';
import { findAndCheckEditedMessage } from '@helpers/sagaHelpers/onEditMessage';
import { getChatHistoryMessages, saveMessagesStorage, saveMessageStorage } from '@helpers/saveMessageStorage';
import { beep } from '@helpers/soundNotification';
import { MessageSenderTypes } from '@models/messages/rawMessages';
import { getChannelSetting, getGettingClientHistory, getInfoAgentStore, getMsgHistoryStore, } from '@reducers/initializationReducer';
import { getAdditionalInfo, getIsFocusOnChat, getIsMuted, getIsQuoting } from '@reducers/workAppReducer';
import { initializationActionTypes, webSocketActionType } from '@type';
import { all, call, put, select, takeEvery } from "redux-saga/effects";
// eslint-disable-next-line @typescript-eslint/default-param-last
export function processingMessagesHistory(msg, agentsInfo) {
    var mapArrMsg, _a, avatar, name, _b, nameLocales, lang, messages;
    if (msg === void 0) { msg = []; }
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, call(mappingMsgArr, msg)];
            case 1:
                mapArrMsg = _c.sent();
                return [4 /*yield*/, select(getInfoAgentStore)];
            case 2:
                _a = _c.sent(), avatar = _a.avatar, name = _a.name, _b = _a.nameLocales, nameLocales = _b === void 0 ? {} : _b;
                return [4 /*yield*/, JSON.parse(sessionStorage.getItem('AskMeWebLibrary-setLocalLang'))];
            case 3:
                lang = _c.sent();
                return [4 /*yield*/, all(mapArrMsg.map(function (el) {
                        var _a, _b, _c;
                        return call(createMsgOfWS, el, ((_a = agentsInfo[el.sender]) === null || _a === void 0 ? void 0 : _a.avatar) || avatar, lang, ((_b = agentsInfo[el.sender]) === null || _b === void 0 ? void 0 : _b.name) || name, ((_c = agentsInfo[el.sender]) === null || _c === void 0 ? void 0 : _c.nameLocales) || nameLocales);
                    }))];
            case 4:
                messages = _c.sent();
                return [4 /*yield*/, put(initializationActions.setMsgHistory(messages))];
            case 5:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export function getMessagesHistory() {
    var gettingClientHistory, msg, _a, rawMsg, agentsInfo, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, select(getGettingClientHistory)];
            case 1:
                gettingClientHistory = _c.sent();
                _c.label = 2;
            case 2:
                _c.trys.push([2, 16, , 18]);
                _a = gettingClientHistory;
                switch (_a) {
                    case 'localStorage': return [3 /*break*/, 3];
                    case 'sessionStorage': return [3 /*break*/, 7];
                }
                return [3 /*break*/, 11];
            case 3: return [4 /*yield*/, call(getChatHistoryMessages, true)];
            case 4:
                msg = _c.sent();
                return [4 /*yield*/, put(initializationActions.setMsgHistory(msg))];
            case 5:
                _c.sent();
                return [4 /*yield*/, call(changeStorageAgentInfo)];
            case 6:
                _c.sent();
                return [3 /*break*/, 15];
            case 7: return [4 /*yield*/, call(getChatHistoryMessages, false)];
            case 8:
                msg = _c.sent();
                return [4 /*yield*/, put(initializationActions.setMsgHistory(msg))];
            case 9:
                _c.sent();
                return [4 /*yield*/, call(changeStorageAgentInfo)];
            case 10:
                _c.sent();
                return [3 /*break*/, 15];
            case 11: return [4 /*yield*/, call(chatWSAPI.getMsgHistory)];
            case 12:
                rawMsg = _c.sent();
                return [4 /*yield*/, call(processingAgents, rawMsg)];
            case 13:
                agentsInfo = _c.sent();
                return [4 /*yield*/, call(processingMessagesHistory, rawMsg, agentsInfo)];
            case 14:
                _c.sent();
                _c.label = 15;
            case 15: return [3 /*break*/, 18];
            case 16:
                _b = _c.sent();
                msg = [];
                return [4 /*yield*/, put(initializationActions.setMsgHistory(msg))];
            case 17:
                _c.sent();
                return [3 /*break*/, 18];
            case 18: return [4 /*yield*/, put(initializationActions.setIsLoadingMessages(false))];
            case 19:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export function assignNewAgent(action) {
    var agent, brandId, nameLocales;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                agent = action.payload;
                return [4 /*yield*/, select(getChannelSetting)];
            case 1:
                brandId = (_a.sent()).brandId;
                return [4 /*yield*/, call(agentLocalesFromBrandsLocales, agent, brandId)];
            case 2:
                nameLocales = _a.sent();
                return [4 /*yield*/, call(setAgentLocales, agent, nameLocales)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function setAgentLocales(agent, nameLocales) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put({
                    type: initializationActionTypes.SET_AGENT_INFO,
                    payload: __assign(__assign({}, agent), { nameLocales: nameLocales }),
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function processingAgents(msg) {
    var agentsId, brandId, i, agentsInfo, agentInfoResponse, _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, call(getUniqueAgentsId, msg)];
            case 1:
                agentsId = _d.sent();
                return [4 /*yield*/, select(getChannelSetting)];
            case 2:
                brandId = (_d.sent()).brandId;
                i = 0;
                agentsInfo = {};
                _d.label = 3;
            case 3:
                if (!(agentsId.length > i)) return [3 /*break*/, 7];
                return [4 /*yield*/, call(getAgents, agentsId[i])];
            case 4:
                agentInfoResponse = _d.sent();
                _a = agentsInfo;
                _b = agentsId[i];
                return [4 /*yield*/, agentInfoResponse.json()];
            case 5:
                _a[_b] = _d.sent();
                _c = agentsInfo[agentsId[i]];
                return [4 /*yield*/, call(agentLocalesFromBrandsLocales, agentsInfo[agentsId[i]], brandId)];
            case 6:
                _c.nameLocales = _d.sent();
                // eslint-disable-next-line no-plusplus
                i++;
                return [3 /*break*/, 3];
            case 7: return [2 /*return*/, agentsInfo];
        }
    });
}
export function setToStorage(messages) {
    var gettingClientHistory;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getGettingClientHistory)];
            case 1:
                gettingClientHistory = _a.sent();
                return [4 /*yield*/, call(saveMessagesStorage, messages, gettingClientHistory)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function onDeleteMessage(action) {
    var messageId, messagesHistory, newHistoryWithDeletedMsg;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                messageId = action.payload.args.messageId;
                return [4 /*yield*/, select(getMsgHistoryStore)];
            case 1:
                messagesHistory = _a.sent();
                return [4 /*yield*/, call(findAndCheckDeletedMessage, messagesHistory, messageId)];
            case 2:
                newHistoryWithDeletedMsg = _a.sent();
                return [4 /*yield*/, put(initializationActions.setMsgHistory(newHistoryWithDeletedMsg))];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(setToStorage, newHistoryWithDeletedMsg)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function onEditMessage(action) {
    var _a, messageId, text, messagesHistory, newHistoryWithEditedMsg;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload.args, messageId = _a.messageId, text = _a.text;
                return [4 /*yield*/, select(getMsgHistoryStore)];
            case 1:
                messagesHistory = _b.sent();
                return [4 /*yield*/, call(findAndCheckEditedMessage, messagesHistory, messageId, text)];
            case 2:
                newHistoryWithEditedMsg = _b.sent();
                return [4 /*yield*/, put(initializationActions.setMsgHistory(newHistoryWithEditedMsg))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(setToStorage, newHistoryWithEditedMsg)];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function getNewMessage(action) {
    var args, gettingClientHistory, _a, avatar, name, id, _b, nameLocales, lang, message, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                args = action.payload.args;
                return [4 /*yield*/, select(getGettingClientHistory)];
            case 1:
                gettingClientHistory = _d.sent();
                return [4 /*yield*/, select(getInfoAgentStore)];
            case 2:
                _a = _d.sent(), avatar = _a.avatar, name = _a.name, id = _a.id, _b = _a.nameLocales, nameLocales = _b === void 0 ? {} : _b;
                if (!isEnumHasThatValue(args.sender, MessageSenderTypes))
                    args.sender = id;
                return [4 /*yield*/, JSON.parse(sessionStorage.getItem('AskMeWebLibrary-setLocalLang'))];
            case 3:
                lang = _d.sent();
                return [4 /*yield*/, call(createMsgOfWS, args, avatar, lang, name, nameLocales)];
            case 4:
                message = _d.sent();
                _c = gettingClientHistory;
                switch (_c) {
                    case 'localStorage': return [3 /*break*/, 5];
                    case 'sessionStorage': return [3 /*break*/, 7];
                }
                return [3 /*break*/, 9];
            case 5: return [4 /*yield*/, call(saveMessageStorage, message, true)];
            case 6:
                _d.sent();
                return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, call(saveMessageStorage, message, false)];
            case 8:
                _d.sent();
                return [3 /*break*/, 9];
            case 9: return [4 /*yield*/, call(soundNotificationForFirstMsg, args.sender)];
            case 10:
                _d.sent();
                return [4 /*yield*/, put(webSocketActions.setMsg(message))];
            case 11:
                _d.sent();
                return [4 /*yield*/, call(shouldUpdateAgentData, message.type)];
            case 12:
                _d.sent();
                return [2 /*return*/];
        }
    });
}
export function shouldUpdateAgentData(senderType) {
    var agent;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getInfoAgentStore)];
            case 1:
                agent = _a.sent();
                if (!(agent.id && senderType === 'agentMessage')) return [3 /*break*/, 3];
                return [4 /*yield*/, call(changeStorageAgentInfo)];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}
export function soundNotificationForFirstMsg(sender) {
    var messagesHistory, isChatEnabled, _a, _b, isFocusOnChat, isMuted;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, select(getMsgHistoryStore)];
            case 1:
                messagesHistory = _c.sent();
                if (!(sender !== 'client' && messagesHistory.filter(function (_a) {
                    var type = _a.type;
                    return type === 'agentMessage';
                }).length + 1 <= 1)) return [3 /*break*/, 5];
                _b = (_a = JSON).parse;
                return [4 /*yield*/, sessionStorage.getItem('AskMeWebLibrary-isEnabled')];
            case 2:
                isChatEnabled = _b.apply(_a, [_c.sent()]).isEnabled;
                return [4 /*yield*/, select(getIsFocusOnChat)];
            case 3:
                isFocusOnChat = _c.sent();
                return [4 /*yield*/, select(getIsMuted)];
            case 4:
                isMuted = _c.sent();
                if ((!isChatEnabled || !isFocusOnChat) && !isMuted)
                    beep();
                _c.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
export function sendMessage(text, lang) {
    var additionalInfo, _a, isQuoting, quotedMessage, data, res, res, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(getAdditionalInfo)];
            case 1:
                additionalInfo = _b.sent();
                return [4 /*yield*/, select(getIsQuoting)];
            case 2:
                _a = _b.sent(), isQuoting = _a.state, quotedMessage = _a.data;
                data = {
                    text: text,
                    language: lang,
                    additionalInfo: additionalInfo,
                };
                _b.label = 3;
            case 3:
                _b.trys.push([3, 11, , 12]);
                if (!isQuoting) return [3 /*break*/, 7];
                data.quotedMessageId = quotedMessage === null || quotedMessage === void 0 ? void 0 : quotedMessage.id;
                return [4 /*yield*/, call(chatWSAPI.sendNewQuote, data)];
            case 4:
                res = _b.sent();
                return [4 /*yield*/, call(getNewMessage, { payload: { args: res } })];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(workStatesActions.setIsQuoting({ data: null, state: false }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 10];
            case 7: return [4 /*yield*/, call(chatWSAPI.sendNewMessage, data)];
            case 8:
                res = _b.sent();
                return [4 /*yield*/, call(getNewMessage, { payload: { args: res } })];
            case 9:
                _b.sent();
                _b.label = 10;
            case 10: return [3 /*break*/, 12];
            case 11:
                error_1 = _b.sent();
                console.log('sendMessage ->', error_1.message);
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function completeChoiceMessage(id) {
    var msgHistory, newMsgHistory, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getMsgHistoryStore)];
            case 1:
                msgHistory = _a.sent();
                return [4 /*yield*/, call(findAndCompleteChoiceMessage, msgHistory, id)];
            case 2:
                newMsgHistory = _a.sent();
                return [4 /*yield*/, put(initializationActions.setMsgHistory(newMsgHistory))];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(setToStorage, newMsgHistory)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5:
                _a.trys.push([5, 7, , 8]);
                return [4 /*yield*/, call(chatWSAPI.completeChoiceMessage, id)];
            case 6:
                _a.sent();
                return [3 /*break*/, 8];
            case 7:
                error_2 = _a.sent();
                console.log(error_2);
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function sendNewMessageSagaWS(action) {
    var _a, value, lang, messagesHistory, choiceMessage;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, value = _a.value, lang = _a.lang;
                return [4 /*yield*/, call(sendMessage, value, lang)];
            case 1:
                _b.sent();
                return [4 /*yield*/, select(getMsgHistoryStore)];
            case 2:
                messagesHistory = _b.sent();
                return [4 /*yield*/, call(getLastChoiceMessage, messagesHistory)];
            case 3:
                choiceMessage = _b.sent();
                if (!choiceMessage) return [3 /*break*/, 5];
                return [4 /*yield*/, call(completeChoiceMessage, choiceMessage.data.id)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
export function sendChoiceMessage(action) {
    var _a, value, lang, id;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, value = _a.value, lang = _a.lang, id = _a.id;
                return [4 /*yield*/, call(sendMessage, value, lang)];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(completeChoiceMessage, id)];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sendQuickReplySagaWS(action) {
    var _a, quickReply, lang;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, quickReply = _a.quickReply, lang = _a.lang;
                return [4 /*yield*/, call(sendMessage, quickReply, lang)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sendFile(action) {
    var gettingClientHistory, response, fileClient, _a, error_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, put({ type: webSocketActionType.SET_LOADER_SEND_FILE, payload: true })];
            case 1:
                _b.sent();
                return [4 /*yield*/, select(getGettingClientHistory)];
            case 2:
                gettingClientHistory = _b.sent();
                _b.label = 3;
            case 3:
                _b.trys.push([3, 13, , 14]);
                return [4 /*yield*/, call(chatWSAPI.sendFile, action.payload)];
            case 4:
                response = _b.sent();
                return [4 /*yield*/, call(createMsgOfWS, response)];
            case 5:
                fileClient = _b.sent();
                _a = gettingClientHistory;
                switch (_a) {
                    case 'sessionStorage': return [3 /*break*/, 6];
                    case 'localStorage': return [3 /*break*/, 8];
                }
                return [3 /*break*/, 10];
            case 6: return [4 /*yield*/, call(saveMessageStorage, fileClient, false)];
            case 7:
                _b.sent();
                return [3 /*break*/, 10];
            case 8: return [4 /*yield*/, call(saveMessageStorage, fileClient, true)];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [4 /*yield*/, put(webSocketActions.setMsg(fileClient))];
            case 11:
                _b.sent();
                return [4 /*yield*/, put({ type: webSocketActionType.SET_LOADER_SEND_FILE, payload: false })];
            case 12:
                _b.sent();
                return [3 /*break*/, 14];
            case 13:
                error_3 = _b.sent();
                console.log('sendFile ->', error_3.message);
                return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}
export function getAgentFile(action) {
    var args, gettingClientHistory, _a, avatar, name, id, _b, nameLocales, lang, fileAgent, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                args = action.payload.args;
                return [4 /*yield*/, select(getGettingClientHistory)];
            case 1:
                gettingClientHistory = _d.sent();
                return [4 /*yield*/, select(getInfoAgentStore)];
            case 2:
                _a = _d.sent(), avatar = _a.avatar, name = _a.name, id = _a.id, _b = _a.nameLocales, nameLocales = _b === void 0 ? {} : _b;
                if (args.sender !== 'client')
                    args.sender = id;
                return [4 /*yield*/, JSON.parse(sessionStorage.getItem('AskMeWebLibrary-setLocalLang'))];
            case 3:
                lang = _d.sent();
                return [4 /*yield*/, call(createMsgOfWS, args, avatar, lang, name, nameLocales)];
            case 4:
                fileAgent = _d.sent();
                _c = gettingClientHistory;
                switch (_c) {
                    case 'sessionStorage': return [3 /*break*/, 5];
                    case 'localStorage': return [3 /*break*/, 7];
                }
                return [3 /*break*/, 9];
            case 5: return [4 /*yield*/, call(saveMessageStorage, fileAgent, false)];
            case 6:
                _d.sent();
                return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, call(saveMessageStorage, fileAgent, true)];
            case 8:
                _d.sent();
                return [3 /*break*/, 9];
            case 9: return [4 /*yield*/, call(soundNotificationForFirstMsg, args.sender)];
            case 10:
                _d.sent();
                return [4 /*yield*/, put(webSocketActions.setMsg(fileAgent))];
            case 11:
                _d.sent();
                return [2 /*return*/];
        }
    });
}
export function changeStorageAgentInfo() {
    var messagesHistory, agentsInfo_1, lang_1, updatedMsgs, agent;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getMsgHistoryStore)];
            case 1:
                messagesHistory = _a.sent();
                if (!(messagesHistory.length > 0)) return [3 /*break*/, 8];
                return [4 /*yield*/, call(processingAgents, messagesHistory)];
            case 2:
                agentsInfo_1 = _a.sent();
                return [4 /*yield*/, JSON.parse(sessionStorage.getItem('AskMeWebLibrary-setLocalLang'))];
            case 3:
                lang_1 = _a.sent();
                if (!isNeedToChangeAgentsInfo(agentsInfo_1, messagesHistory, lang_1)) return [3 /*break*/, 8];
                updatedMsgs = messagesHistory.map(function (el) {
                    var _a, _b, _c, _d;
                    if (el.type !== 'agentMessage')
                        return el;
                    var nameLocales = (_a = agentsInfo_1[el.sender]) === null || _a === void 0 ? void 0 : _a.nameLocales;
                    return __assign(__assign({}, el), { agentName: nameLocales ? nameLocales[lang_1] || ((_b = agentsInfo_1[el.sender]) === null || _b === void 0 ? void 0 : _b.name) : (_c = agentsInfo_1[el.sender]) === null || _c === void 0 ? void 0 : _c.name, avatar: (_d = agentsInfo_1[el.sender]) === null || _d === void 0 ? void 0 : _d.avatar });
                });
                return [4 /*yield*/, select(getInfoAgentStore)];
            case 4:
                agent = _a.sent();
                return [4 /*yield*/, call(setAgentLocales, agent, agentsInfo_1[agent.id].nameLocales)];
            case 5:
                _a.sent();
                return [4 /*yield*/, put(initializationActions.setMsgHistory(updatedMsgs))];
            case 6:
                _a.sent();
                return [4 /*yield*/, call(setToStorage, updatedMsgs)];
            case 7:
                _a.sent();
                _a.label = 8;
            case 8: return [2 /*return*/];
        }
    });
}
export var sagasMsg = [
    takeEvery(webSocketActionType.SEND_FILE, sendFile),
    takeEvery(webSocketActionType.GET_MESSAGE, getNewMessage),
    takeEvery(webSocketActionType.GET_AGENT_FILE, getAgentFile),
    takeEvery(webSocketActionType.SET_DELETED_MESSAGE, onDeleteMessage),
    takeEvery(webSocketActionType.SET_EDITED_MESSAGE, onEditMessage),
    takeEvery(webSocketActionType.GET_MESSAGES_HISTORY, getMessagesHistory),
    takeEvery(webSocketActionType.SEND_NEW_MESSAGE_SAGA, sendNewMessageSagaWS),
    takeEvery(webSocketActionType.SEND_CHOICE_MESSAGE, sendChoiceMessage),
    takeEvery(webSocketActionType.SEND_QUICK_REPLY_SAGA, sendQuickReplySagaWS),
    takeEvery(initializationActionTypes.ASSIGNING_AGENT, assignNewAgent),
];

export var filenameWithoutLoaders = function (filename) {
    if (filename === void 0) { filename = ''; }
    var index = filename.lastIndexOf('!');
    return index < 0 ? filename : filename.slice(index + 1);
};
export var filenameHasLoaders = function (filename) {
    var actualFilename = filenameWithoutLoaders(filename);
    return actualFilename !== filename;
};
export var filenameHasSchema = function (filename) { return /^\w+:/.test(filename); };
export var isFilenameAbsolute = function (filename) {
    var actualFilename = filenameWithoutLoaders(filename);
    return actualFilename.indexOf('/') === 0;
};
export var makeUrl = function (filename, scheme, line, column) {
    var actualFilename = filenameWithoutLoaders(filename);
    if (filenameHasSchema(filename)) {
        return actualFilename;
    }
    var url = "file://".concat(actualFilename);
    if (scheme === 'vscode') {
        url = "".concat(scheme, "://file/").concat(url);
        url = url.replace(/file:\/{3}/, ''); // visual studio code does not need file:/// in its scheme
        if (line && actualFilename === filename) {
            url = "".concat(url, ":").concat(line);
            if (column) {
                url = "".concat(url, ":").concat(column);
            }
        }
    }
    else if (scheme) {
        url = "".concat(scheme, "://open?url=").concat(url);
        if (line && actualFilename === filename) {
            url = "".concat(url, "&line=").concat(line);
            if (column) {
                url = "".concat(url, "&column=").concat(column);
            }
        }
    }
    return url;
};
export var makeLinkText = function (filename, line, column) {
    var text = filenameWithoutLoaders(filename);
    if (line && text === filename) {
        text = "".concat(text, ":").concat(line);
        if (column) {
            text = "".concat(text, ":").concat(column);
        }
    }
    return text;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import localization from '@localization';
import { MessageSenderTypes } from '@models/messages/rawMessages';
import { generateString, getTime } from '../components/ChatWindow/logic/ChatWindowHelper';
import { checkObj } from './checkObj';
import { quoteDateToString, textCustomTimeRenderer } from './textCustomTimeRenderer';
export var END_DIALOG = '{"type":"end_dialog"';
export var CLIENT_START_PAGE = '{"type":"client_start_page"';
export var START_DIALOG = '{"type":"start_dialog"';
export var FINISH_DIALOG = '{"type":"finish_dialog"';
export var CALLING = '{"type":"calling"';
export var CALL_STARTED = '{"type":"call_started"';
export var CALL_REJECTED = '{"type":"call_rejected"';
export var CALL_ENDED = '{"type":"call_ended"';
export var CALL_MISSED = '{"type":"call_missed"';
export var CLIENT_MISSED_CALL = '{"type":"client_missed_call"';
export var CALL_CANCELED = '{"type":"call_canceled"';
var callSysMsgs = [CALL_STARTED, CALL_REJECTED, CALL_ENDED, CALL_MISSED, CLIENT_MISSED_CALL, CALL_CANCELED];
var getIsCallingSysMsgs = function (el) { return callSysMsgs.some(function (msg) { return el.text.includes(msg); }); };
var getFilteredSysMsgs = function (el) {
    return el.text.includes(CLIENT_MISSED_CALL) || el.text.includes(CALL_CANCELED) ? 'call_missed' : null;
};
export var processSystemCallMessage = function (el) {
    if (!getIsCallingSysMsgs(el))
        return el;
    return __assign(__assign({}, el), { text: getFilteredSysMsgs(el) || JSON.parse(el.text).type, type: 9 });
};
export var mappingMsgArr = function (arr) {
    return arr
        .filter(function (el) { return !(el.text.includes(CLIENT_START_PAGE) || el.text.includes(START_DIALOG) || el.text.includes(CALLING)); })
        .map(function (el) {
        if (el.text.includes(END_DIALOG) || el.text.includes(FINISH_DIALOG)) {
            return __assign(__assign({}, el), { type: 8 });
        }
        if (el.type === 9) {
            return processSystemCallMessage(el);
        }
        return el;
    });
};
var getBotName = function (nameLocales, name, lang) {
    return checkObj(nameLocales, lang) ? checkObj(nameLocales, lang) : name || localization[lang]['bot.Name'];
};
export var createMsgOfWS = function (args, avatar, lang, name, nameLocales) {
    if (avatar === void 0) { avatar = ''; }
    if (lang === void 0) { lang = 'en'; }
    if (name === void 0) { name = ''; }
    if (nameLocales === void 0) { nameLocales = {}; }
    var message;
    if (args.sender === MessageSenderTypes.client) {
        message = createUserMessage(args);
    }
    else if (args.sender === MessageSenderTypes.bot) {
        message = createBotMessage(args);
    }
    else {
        message = createAgentMessage({ args: args, avatar: avatar, lang: lang, name: name, nameLocales: nameLocales });
    }
    if (args.type === 7 && args.quotedMessage) {
        message.data.quotedMessage = args.quotedMessage;
        message.data.quotedMessageId = args.quotedMessageId;
        message.data.quotedMessage.time = quoteDateToString(args.quotedMessage.time);
        message.data.quotedMessage.sender =
            message.data.quotedMessage.sender === 'client'
                ? localization[lang]['client.You']
                : getBotName(nameLocales, name, lang);
    }
    return message;
};
var createAgentMessage = function (_a) {
    var _b = _a.avatar, avatar = _b === void 0 ? '' : _b, _c = _a.nameLocales, nameLocales = _c === void 0 ? {} : _c, _d = _a.lang, lang = _d === void 0 ? 'en' : _d, _e = _a.name, name = _e === void 0 ? '' : _e, args = _a.args;
    return ({
        type: 'agentMessage',
        avatar: avatar,
        agentName: getBotName(nameLocales, name, lang),
        sender: args.sender,
        data: {
            type: args.type || 1,
            responseText: args.type === 9 ? processSystemCallMessage(args).text : args.text,
            edited: args === null || args === void 0 ? void 0 : args.edited,
            responseId: generateString(10),
            time: textCustomTimeRenderer(args.time) || getTime(),
            id: args.id || '',
            isRead: args.readStatus === 2,
        },
    });
};
var createUserMessage = function (args) {
    return ({
        type: 'userMessage',
        data: {
            type: args.type || 1,
            responseText: args.text,
            responseId: generateString(10),
            time: textCustomTimeRenderer(args.time) || getTime(),
            id: args.id || '',
            isRead: false,
        },
    });
};
var createBotMessage = function (args) {
    return ({
        type: 'botMessage',
        data: {
            type: args.type || 1,
            responseText: args.type === 10 ? JSON.parse(args.text).title : args.text,
            choice: args.type === 10 ? JSON.parse(args.text) : undefined,
            responseId: generateString(10),
            time: textCustomTimeRenderer(args.time) || getTime(),
            id: args.id || '',
            isRead: false,
            isCompletedChoice: !!(args === null || args === void 0 ? void 0 : args.isCompletedChoice),
        },
    });
};
export var createMsgClient = function (value) { return ({
    type: 'userMessage',
    data: {
        type: 1,
        text: value,
        time: getTime(),
    },
}); };

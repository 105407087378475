export var translateQuickPhrases = function (lang, phrases) {
    var _a;
    var quickPhrasesArr = [];
    if (!phrases)
        return quickPhrasesArr;
    switch (lang) {
        case 'pl': {
            quickPhrasesArr = phrases.pl.quickPhrases;
            break;
        }
        case 'ru': {
            quickPhrasesArr = phrases.ru.quickPhrases;
            break;
        }
        case 'en': {
            quickPhrasesArr = phrases.en.quickPhrases;
            break;
        }
        case 'ua': {
            quickPhrasesArr = phrases.ua.quickPhrases;
            break;
        }
        case 'de': {
            quickPhrasesArr = phrases.de.quickPhrases;
            break;
        }
        case 'es': {
            quickPhrasesArr = phrases.es.quickPhrases;
            break;
        }
        case 'vi': {
            quickPhrasesArr = phrases.vi.quickPhrases;
            break;
        }
        case 'pt': {
            quickPhrasesArr = phrases.pt.quickPhrases;
            break;
        }
        case 'ko': {
            quickPhrasesArr = phrases.ko.quickPhrases;
            break;
        }
        case 'it': {
            quickPhrasesArr = phrases.it.quickPhrases;
            break;
        }
        case 'ar': {
            quickPhrasesArr = phrases.ar.quickPhrases;
            break;
        }
        case 'fr': {
            quickPhrasesArr = ((_a = phrases.fr) === null || _a === void 0 ? void 0 : _a.quickPhrases) || [];
            break;
        }
        default: {
            quickPhrasesArr = phrases.en.quickPhrases;
        }
    }
    return quickPhrasesArr;
};

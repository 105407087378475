var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsClientBanned } from '@reducers/initializationReducer';
import { Body } from '../../Body/Body';
import Footer from '../../Footer';
import { Header } from '../../Header/Header';
import { PortalContext } from '../../PortalContext';
export function Window(props) {
    var sendMessage = props.sendMessage, toggleEnabled = props.toggleEnabled, changeSizeImg = props.changeSizeImg, currentResizeImg = props.currentResizeImg, toggleFullScreen = props.toggleFullScreen, isFullScreen = props.isFullScreen, scrollElement = props.scrollElement, lang = props.lang, currentWidth = props.currentWidth, isRightResize = props.isRightResize;
    var _a = useState(16), inputHeight = _a[0], setInputHeight = _a[1];
    var isBanned = useSelector(getIsClientBanned);
    var portalRef = React.useRef(null);
    return (React.createElement(PortalContext.Provider, { value: portalRef },
        React.createElement(Header, __assign({}, {
            lang: lang,
            isFullScreen: isFullScreen,
            changeSizeImg: changeSizeImg,
            toggleEnabled: toggleEnabled,
            toggleFullScreen: toggleFullScreen,
            currentResizeImg: currentResizeImg,
        }, { isAuthHeader: false })),
        React.createElement(Body, { inputHeight: inputHeight, isFullScreen: isFullScreen, scrollElement: scrollElement, lang: lang }),
        !isBanned && (React.createElement(Footer, { lang: lang, isFullScreen: isFullScreen, isRightResize: isRightResize, currentWidth: currentWidth, setInputHeight: setInputHeight, scrollElement: scrollElement, sendMessage: sendMessage })),
        React.createElement("div", { id: "portal-container", ref: portalRef })));
}

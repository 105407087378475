export var sessionStorageItems = [
    'AskMeWebLibrary-user-email',
    'AskMeWebLibrary-current-position',
    'AskMeWebLibrary-user-phone',
    'AskMeWebLibrary-user-last-name',
    'AskMeWebLibrary-isEnabled',
    'AskMeWebLibrary-user-name',
    'AskMeWebLibrary-user-id',
    'AskMeWebLibrary-ws-connect',
    'AskMeWebLibrary-user-first-name',
    'AskMeWebLibrary-chat-history',
    'AskMeWebLibrary-current-size',
];
export var localStorageItems = [
    'AskMeWebLibrary-user-email',
    'AskMeWebLibrary-user-name',
    'AskMeWebLibrary-user-id',
    'AskMeWebLibrary-chat-history',
];
// margins
export var margin = '20px';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable no-param-reassign,react/static-property-placement */
import React from 'react';
import ErrorStackParser from 'error-stack-parser';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import baseStyle from './styles';
import { isFilenameAbsolute, makeLinkText, makeUrl } from './utils';
var ErrorView = /** @class */ (function (_super) {
    __extends(ErrorView, _super);
    function ErrorView(props) {
        var _this = _super.call(this, props) || this;
        _this.mapOnConstruction(props.error);
        // State is used to store the error mapped to the source map.
        _this.state = { error: null, mapped: false };
        return _this;
    }
    ErrorView.prototype.componentDidMount = function () {
        var mapped = this.state.mapped;
        var error = this.props.error;
        if (!mapped) {
            this.mapError(error);
        }
    };
    // Try to map the error when the component gets constructed, this is possible
    // in some cases like evals.
    ErrorView.prototype.mapOnConstruction = function (error) {
        var _a;
        var stackLines = (_a = error === null || error === void 0 ? void 0 : error.stack) === null || _a === void 0 ? void 0 : _a.split('\n');
        // There's no stack, only the error message.
        if (stackLines && stackLines.length < 2) {
            this.state = { error: error, mapped: true };
            return;
        }
        // Using the “eval” setting on webpack already gives the correct location.
        var isWebpackEval = stackLines && stackLines[1].search(/\(webpack:\/{3}/) !== -1;
        if (isWebpackEval) {
            // No changes are needed here.
            this.state = { error: error, mapped: true };
            return;
        }
        // Other eval follow a specific pattern and can be easily parsed.
        var isEval = stackLines && stackLines[1].search(/\(eval at/) !== -1;
        if (!isEval) {
            // mapping will be deferred until `componentDidMount`
            this.state = { error: error, mapped: false };
            return;
        }
        // The first line is the error message.
        var fixedLines = [stackLines.shift()];
        // The rest needs to be fixed.
        stackLines.forEach(function (stackLine) {
            var evalStackLine = stackLine.match(/(.+)\(eval at (.+) \(.+?\), .+(:\d+:\d+)\)/);
            if (evalStackLine) {
                var atSomething = evalStackLine[1], file = evalStackLine[2], rowColumn = evalStackLine[3];
                fixedLines.push("".concat(atSomething, " (").concat(file).concat(rowColumn, ")"));
            }
            else {
                // TODO: When stack frames of different types are detected, try to load the additional source maps
                fixedLines.push(stackLine);
            }
        });
        error.stack = fixedLines.join('\n');
        this.state = { error: error, mapped: true };
    };
    ErrorView.prototype.mapError = function (error) {
        var _this = this;
        mapStackTrace(error.stack, function (mappedStack) {
            error.stack = mappedStack.join('\n');
            _this.setState({ error: error, mapped: true });
        });
    };
    ErrorView.prototype.renderFrames = function (frames) {
        if (frames === void 0) { frames = []; }
        var _a = this.props, filename = _a.filename, editorScheme = _a.editorScheme, useLines = _a.useLines, useColumns = _a.useColumns, style = _a.style;
        var _b = __assign(__assign({}, baseStyle), style), frame = _b.frame, file = _b.file, linkToFile = _b.linkToFile;
        return frames.map(function (f, index) {
            var text;
            var url;
            if (index === 0 && filename && !isFilenameAbsolute(f.fileName || '')) {
                url = makeUrl(filename, editorScheme);
                text = makeLinkText(filename);
            }
            else {
                var lines = useLines ? f.lineNumber : undefined;
                var columns = useColumns ? f.columnNumber : undefined;
                url = makeUrl(f.fileName || '', editorScheme, lines, columns);
                text = makeLinkText(f.fileName || '', lines, columns);
            }
            return (React.createElement("div", { style: frame, key: "$_".concat(index + 1) },
                React.createElement("div", null, f.functionName),
                React.createElement("div", { style: file },
                    React.createElement("a", { href: url, style: linkToFile }, text))));
        });
    };
    ErrorView.prototype.render = function () {
        // The error is received as a property to initialize state.error, which may
        // be updated when it is mapped to the source map.
        var error = this.state.error;
        var _a = this.props, className = _a.className, style = _a.style;
        var _b = __assign(__assign({}, baseStyle), style), errorOverlay = _b.errorOverlay, message = _b.message, stack = _b.stack, frame = _b.frame;
        var frames;
        var parseError;
        try {
            if (error) {
                frames = ErrorStackParser.parse(error);
            }
        }
        catch (_c) {
            parseError = new Error('Failed to parse stack trace. Stack trace information unavailable.');
        }
        frames = parseError ? (React.createElement("div", { style: frame, key: 0 },
            React.createElement("div", null, parseError.message))) : (this.renderFrames(frames));
        return (React.createElement("div", { style: errorOverlay, className: className },
            React.createElement("div", { style: message }, "".concat(error === null || error === void 0 ? void 0 : error.name, ":").concat(error === null || error === void 0 ? void 0 : error.message)),
            React.createElement("div", { style: stack }, frames)));
    };
    return ErrorView;
}(React.Component));
export { ErrorView };
ErrorView.displayName = 'ErrorView';
ErrorView.defaultProps = {
    filename: undefined,
    useLines: true,
    useColumns: true,
};

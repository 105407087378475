export var translateGreeting = function (lang, greeting) {
    var _a;
    var greetingText = '';
    if (!greeting)
        return greetingText;
    switch (lang) {
        case 'pl': {
            greetingText = greeting.pl.greeting;
            break;
        }
        case 'ru': {
            greetingText = greeting.ru.greeting;
            break;
        }
        case 'en': {
            greetingText = greeting.en.greeting;
            break;
        }
        case 'ua': {
            greetingText = greeting.ua.greeting;
            break;
        }
        case 'de': {
            greetingText = greeting.de.greeting;
            break;
        }
        case 'es': {
            greetingText = greeting.es.greeting;
            break;
        }
        case 'vi': {
            greetingText = greeting.vi.greeting;
            break;
        }
        case 'pt': {
            greetingText = greeting.pt.greeting;
            break;
        }
        case 'ko': {
            greetingText = greeting.ko.greeting;
            break;
        }
        case 'it': {
            greetingText = greeting.it.greeting;
            break;
        }
        case 'ar': {
            greetingText = greeting.ar.greeting;
            break;
        }
        case 'fr': {
            greetingText = ((_a = greeting.fr) === null || _a === void 0 ? void 0 : _a.greeting) || '';
            break;
        }
        default: {
            greetingText = greeting.en.greeting;
        }
    }
    return greetingText;
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Button, Drawer } from 'antd';
var CallDrawer = styled(Drawer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-drawer-body {\n    display: flex;\n    justify-content: space-evenly;\n    align-content: center;\n    align-items: center;\n    padding: 0;\n  }\n  .ant-btn-dangerous.ant-btn-primary {\n    background: #ba0c04;\n    border-color: #ba0c04;\n  }\n  .ant-btn-dangerous.ant-btn-primary:hover {\n    background: #e00e04;\n    border-color: #e00e04;\n  }\n  .ant-btn-dangerous.ant-btn-primary:active {\n    background: #ba0c04;\n    border-color: #ba0c04;\n  }\n  .ant-btn-dangerous.ant-btn-primary:focus {\n    background: #e00e04;\n    border-color: #e00e04;\n  }\n"], ["\n  .ant-drawer-body {\n    display: flex;\n    justify-content: space-evenly;\n    align-content: center;\n    align-items: center;\n    padding: 0;\n  }\n  .ant-btn-dangerous.ant-btn-primary {\n    background: #ba0c04;\n    border-color: #ba0c04;\n  }\n  .ant-btn-dangerous.ant-btn-primary:hover {\n    background: #e00e04;\n    border-color: #e00e04;\n  }\n  .ant-btn-dangerous.ant-btn-primary:active {\n    background: #ba0c04;\n    border-color: #ba0c04;\n  }\n  .ant-btn-dangerous.ant-btn-primary:focus {\n    background: #e00e04;\n    border-color: #e00e04;\n  }\n"])));
var CallButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 47%;\n  border-radius: 4px;\n  height: 35px;\n  line-height: 0;\n  background: ", ";\n  border-color: ", ";\n  &:hover {\n    background: ", ";\n    border-color: ", ";\n  }\n  &:active {\n    background: ", ";\n    border-color: ", ";\n  }\n  &:focus {\n    background: ", ";\n    border-color: ", ";\n  }\n"], ["\n  width: 47%;\n  border-radius: 4px;\n  height: 35px;\n  line-height: 0;\n  background: ", ";\n  border-color: ", ";\n  &:hover {\n    background: ", ";\n    border-color: ", ";\n  }\n  &:active {\n    background: ", ";\n    border-color: ", ";\n  }\n  &:focus {\n    background: ", ";\n    border-color: ", ";\n  }\n"])), function (_a) {
    var danger = _a.danger;
    return (danger ? '#ba0c04' : '#1eb038');
}, function (_a) {
    var danger = _a.danger;
    return (danger ? '#ba0c04' : '#1eb038');
}, function (_a) {
    var danger = _a.danger;
    return (danger ? '#e00e04' : '#24d644');
}, function (_a) {
    var danger = _a.danger;
    return (danger ? '#e00e04' : '#24d644');
}, function (_a) {
    var danger = _a.danger;
    return (danger ? '#ba0c04' : '#1eb038');
}, function (_a) {
    var danger = _a.danger;
    return (danger ? '#ba0c04' : '#1eb038');
}, function (_a) {
    var danger = _a.danger;
    return (danger ? '#e00e04' : '#24d644');
}, function (_a) {
    var danger = _a.danger;
    return (danger ? '#e00e04' : '#24d644');
});
export { CallButton, CallDrawer };
var templateObject_1, templateObject_2;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { webSocketActions } from '@actions';
import { getStylesAgentMsg } from '@reducers/stylesReducer';
import { getIsFocusOnChat } from '@reducers/workAppReducer';
import { scrollDown } from '../../BodyHelper';
import { printMessage } from '../../PrintMessage/PrintMessage';
import QuotedMessage from '../../QuotedMessage/QuotedMessage';
import MessageWrapper from './StyleMessage';
import './Message.less';
var Message = function (props) {
    var dispatch = useDispatch();
    var isFocusOnChat = useSelector(getIsFocusOnChat);
    var value = props.value, hasIcon = props.hasIcon, isFullScreen = props.isFullScreen, scrollElement = props.scrollElement;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var updateMessageStatus = function () {
        if (!value.isRead && value.id > 0) {
            var status = {
                messageId: value.id,
                status: 2,
            };
            dispatch(webSocketActions.updateMessageStatusActions(status));
            value.isRead = true;
        }
    };
    useEffect(function () {
        if (isFocusOnChat) {
            updateMessageStatus();
        }
    }, [isFocusOnChat, updateMessageStatus]);
    useEffect(function () {
        setTimeout(scrollDown(scrollElement), 1);
    }, [scrollElement]);
    var _a = useSelector(getStylesAgentMsg), agentLink = _a.agentLink, agentHoverLink = _a.agentHoverLink, agentActiveLink = _a.agentActiveLink, agentVisitedLink = _a.agentVisitedLink, messageAgentColor = _a.messageAgentColor, messageAgentBorderColor = _a.messageAgentBorderColor;
    return (React.createElement(MessageWrapper, { imgStyle: value.type === 3, isFullScreen: isFullScreen, hasIcon: hasIcon, className: "AskMeWebLibrary-answer__message AskMeWebLibrary-message", messageAgentColor: messageAgentColor, messageAgentBorderColor: messageAgentBorderColor, agentLink: agentLink, agentHoverLink: agentHoverLink, agentActiveLink: agentActiveLink, agentVisitedLink: agentVisitedLink, isEdited: value === null || value === void 0 ? void 0 : value.edited },
        React.createElement(QuotedMessage, __assign({}, value)),
        printMessage({ type: value.type, text: value.responseText })));
};
export default Message;

export var agentLocalesFromBrandsLocales = function (agent, brandId) {
    var _a, _b;
    var nameLocales = {};
    if ((_a = agent === null || agent === void 0 ? void 0 : agent.brandsNamesLocales) === null || _a === void 0 ? void 0 : _a.length) {
        var temp = (_b = agent.brandsNamesLocales.find(function (el) { return el.brandId === brandId; })) === null || _b === void 0 ? void 0 : _b.nameLocales;
        if (!temp)
            return {};
        temp.map(function (el) {
            nameLocales[el.language] = el.name;
        });
    }
    return nameLocales;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { getAgentCallOffer, getIsOnCall } from '@reducers/callReducer';
import fastDeepEqual from 'fast-deep-equal';
import applySpec from 'ramda/es/applySpec';
import { selectChatIconAlignment } from '../../redux/selectors/resizable';
import Avatar from './Avatar/Avatar';
import CallIcon from './CallIcon/CallIcon';
import StyledChatIcon from './StyledChatIcon';
import './Icon.less';
var selector = applySpec({
    offer: getAgentCallOffer,
    isOnCall: getIsOnCall,
    chatIconAlignment: selectChatIconAlignment,
});
export function ChatIcon(_a) {
    var toggleEnabled = _a.toggleEnabled, iconConfig = _a.iconConfig;
    var _b = useSelector(selector, fastDeepEqual), offer = _b.offer, chatIconAlignment = _b.chatIconAlignment, isOnCall = _b.isOnCall;
    return (React.createElement(StyledChatIcon, { className: "AskMeWebLibrary-Chat-icon", rightPosition: chatIconAlignment.rightPosition, bottomPosition: chatIconAlignment.bottomPosition }, !!offer && !isOnCall ? (React.createElement(CallIcon, { className: "AskMeWebLibrary-Chat-icon__avatar", toggleEnabled: toggleEnabled })) : (React.createElement(Avatar, { className: "AskMeWebLibrary-Chat-icon__avatar", iconConfig: iconConfig, toggleEnabled: toggleEnabled }))));
}

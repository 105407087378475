var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { callActionType } from '@type';
export var CALL_REDUCER_NAMESPACE = 'call-reducer';
var initialCallState = {
    agentCallOffer: null,
    ringtoneRef: null,
    isOnCall: false,
    agentStopCall: null,
};
export var callReducer = function (state, action) {
    if (state === void 0) { state = initialCallState; }
    switch (action.type) {
        case callActionType.SET_AGENT_OFFER: {
            return __assign(__assign({}, state), { agentCallOffer: action.payload });
        }
        case callActionType.CLIENT_ANSWERED: {
            return __assign(__assign({}, state), { ringtoneRef: action.payload });
        }
        case callActionType.SET_IS_ON_CALL: {
            return __assign(__assign({}, state), { isOnCall: action.payload });
        }
        case callActionType.SET_AGENT_STOP_CALL: {
            return __assign(__assign({}, state), { agentStopCall: action.payload });
        }
        default: {
            return state;
        }
    }
};
export var getAgentCallOffer = function (state) { return state[CALL_REDUCER_NAMESPACE].agentCallOffer; };
export var getRingtoneRef = function (state) { return state[CALL_REDUCER_NAMESPACE].ringtoneRef; };
export var getIsOnCall = function (state) { return state[CALL_REDUCER_NAMESPACE].isOnCall; };
export var getAgentStopCall = function (state) { return state[CALL_REDUCER_NAMESPACE].agentStopCall; };

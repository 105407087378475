export default (body) => {
  const activeElement = document.activeElement.tagName;
  if (activeElement === 'TEXTAREA' || activeElement === 'INPUT') {
    document.activeElement.blur();
  } else body.focus();
  // eslint-disable-next-line no-param-reassign
  body.style.width = window.innerWidth;
  // eslint-disable-next-line no-param-reassign
  body.style.height = window.innerHeight;
};

export const setScrollStatus = (status) => {
  const body = document.querySelectorAll('body')[0];
  body.style.overflow = status;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workStatesActions } from '@actions';
import { normalizeWidgetPositionTimeOutCall } from '@helpers/normalizeWidgetPosition';
import { beep } from '@helpers/soundNotification';
import { getChannelSetting, getObjReqFieldChanel, getPrivacyPolicy } from '@reducers/initializationReducer';
import { getCaptchaRef, getCurrentAuthStep, getIsMuted } from '@reducers/workAppReducer';
import { initializationActionTypes } from '@type';
import { Steps as ProgressSteps } from 'antd';
import AccountNumber from '../AccountNumber/AccountNumber';
import Email from '../Email/Email';
import FirstName from '../FirstName/FirstName';
import InvoiceNumber from '../InvoiceNumber/InvoiceNumber';
import LastName from '../LastName/LastName';
import Phone from '../Phone/Phone';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import UserName from '../UserName/UserName';
var Steps = function (_a) {
    var lang = _a.lang, setIsRegistrationClient = _a.setIsRegistrationClient;
    var objReqField = useSelector(getObjReqFieldChanel);
    var current = useSelector(getCurrentAuthStep);
    var privacyPolicy = useSelector(getPrivacyPolicy);
    var isMuted = useSelector(getIsMuted);
    var isCaptcha = useSelector(getChannelSetting).isCaptcha;
    var ref = useSelector(getCaptchaRef);
    var dispatch = useDispatch();
    var _b = useState(false), isPrivacyPolicy = _b[0], setIsPrivacyPolicy = _b[1];
    var stepsLength = Object.entries(objReqField)
        .filter(function (el) { return el[1]; })
        .map(function (el) { return [el[0], '']; }).length + 1;
    var saveFormData = function (data) { return dispatch(workStatesActions.setFormData(data)); };
    var onSignUp = function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var email, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isPrivacyPolicy && privacyPolicy.enabled)
                        return [2 /*return*/];
                    email = formData.email, data = __rest(formData, ["email"]);
                    if (!isCaptcha) return [3 /*break*/, 3];
                    return [4 /*yield*/, normalizeWidgetPositionTimeOutCall()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, ref.current.executeAsync()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    dispatch({
                        type: initializationActionTypes.SET_STORAGE_CLIENT_INFO,
                        payload: __assign(__assign({}, data), { userEmail: email }),
                    });
                    setIsRegistrationClient(true);
                    if (!isMuted)
                        beep();
                    return [2 /*return*/];
            }
        });
    }); };
    var inputProps = {
        lang: lang,
        stepsLength: stepsLength,
        saveFormData: saveFormData,
        onSignUp: onSignUp,
        isPrivacyPolicy: isPrivacyPolicy,
        PP: privacyPolicy.enabled && current + 1 === stepsLength && (React.createElement(PrivacyPolicy, __assign({}, { privacyPolicy: privacyPolicy, isPrivacyPolicy: isPrivacyPolicy, lang: lang, setIsPrivacyPolicy: setIsPrivacyPolicy }))),
    };
    var steps = [
        {},
        objReqField.email && React.createElement(Email, __assign({}, inputProps)),
        objReqField.firstName && React.createElement(FirstName, __assign({}, inputProps)),
        objReqField.lastName && React.createElement(LastName, __assign({}, inputProps)),
        objReqField.phone && React.createElement(Phone, __assign({}, inputProps)),
        objReqField.userName && React.createElement(UserName, __assign({}, inputProps)),
        objReqField.accountNumber && React.createElement(AccountNumber, __assign({}, inputProps)),
        objReqField.invoiceNumber && React.createElement(InvoiceNumber, __assign({}, inputProps)),
    ].filter(Boolean);
    return (React.createElement(React.Fragment, null,
        React.createElement(ProgressSteps, { current: current }, steps.map(function (item, index) { return (React.createElement(ProgressSteps.Step, { key: index })); })),
        React.createElement("div", { className: "steps-content_wrap" }, steps[current] || null)));
};
export default Steps;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import stringHash from 'string-hash';
export function inject(content, options) {
    var map = {
        jsInline: { tag: 'script', attrs: { type: 'text/javascript' } },
        jsLink: { tag: 'script', attrs: { type: 'text/javascript', src: content, defer: true } },
        cssInline: { tag: 'style', attrs: {} },
        cssLink: { tag: 'link', attrs: { type: 'text/css', rel: 'stylesheet', href: content } },
    };
    var doc = (options === null || options === void 0 ? void 0 : options.document) || document;
    var type = (options === null || options === void 0 ? void 0 : options.type) || 'js';
    var id = "".concat(type, "-").concat((options === null || options === void 0 ? void 0 : options.id) || String(stringHash(content)));
    // eslint-disable-next-line unicorn/prefer-query-selector
    var tag = doc.getElementById(id);
    if (tag && (options === null || options === void 0 ? void 0 : options.shouldReplace)) {
        tag.remove();
        tag = null;
    }
    return new Promise(function (resolve, reject) {
        var isLink = /(^https?:)|(^\.{0,2}\/)/.test(content);
        var key = "".concat(type).concat(isLink ? 'Link' : 'Inline');
        var info = map[key];
        if (tag) {
            reject(new Error("The ".concat(info.tag, " element already exists.")));
        }
        else {
            tag = doc.createElement(info.tag);
            var attrs_1 = __assign(__assign({}, options === null || options === void 0 ? void 0 : options.attrs), info.attrs);
            Object.keys(attrs_1).forEach(function (attr) {
                if (tag) {
                    tag.setAttribute(attr, String(attrs_1[attr]));
                }
            });
            tag.id = id;
            if (isLink) {
                tag.addEventListener('load', function () { return resolve(tag); });
                tag.addEventListener('error', function (ev) {
                    var err = new Error("The ".concat(info.tag, " element load failed."));
                    Object.assign(err, { event: ev });
                    reject(err);
                });
                // tag.onerror = (ev) => {
                //   const err = new Error(`The ${info.tag} element load failed.`);
                //   Object.assign(err, { event: ev });
                //   reject(err);
                // };
            }
            else {
                tag.innerHTML = content;
            }
            var injectIn = (options === null || options === void 0 ? void 0 : options.injectIn) || 'head';
            var parent = injectIn === 'body' ? doc.body : doc.head;
            parent.append(tag);
            if (!isLink) {
                resolve(tag);
            }
        }
    });
}

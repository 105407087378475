import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChannelSetting, getResponseUserID } from '@reducers/initializationReducer';
import { initializationActionTypes } from '@type';

import { actionSetInitPosition } from '../../redux/actions/saga-external';
import { WindowWebUI } from '../ChatWindow/Windows/WindowWebUI';
import { ChatIcon } from '../Icon/Icon';
import { checkLang, checkLocalLang, throttle, toggleEnabledHandler } from '../logic';
import { ReCaptcha } from '../reCaptcha/ReCaptcha';

import './Chat.less';

export function Chat(props) {
  const scrollElement = useRef(null);
  const dispatch = useDispatch();

  // eslint-disable-next-line react/prop-types
  const { clientData, userInfo, lang, configWebUi, externalConfig, alignment, isShadowDom } = props;
  // eslint-disable-next-line react/prop-types
  const { hideIcon } = configWebUi;

  const [localLang, setLocalLang] = useState(lang);
  // eslint-disable-next-line no-unused-vars
  const [isRegistrationClient, setIsRegistrationClient] = useState(false); // for rerender auth window

  useEffect(() => {
    if (lang) {
      setLocalLang(checkLang(lang));
      sessionStorage.setItem('AskMeWebLibrary-setLocalLang', JSON.stringify(checkLang(lang)));
    } else {
      const lan = JSON.parse(sessionStorage.getItem('AskMeWebLibrary-setLocalLang'));
      if (lan) setLocalLang(checkLang(lan));
    }
    dispatch({ type: initializationActionTypes.GET_CLIENT_EXTERNAL_ID });
  }, [dispatch, lang]);

  const [windowCurrentWidth, setWindowCurrentWidth] = useState(window.innerWidth);
  const [windowCurrentHeight, setWindowCurrentHeight] = useState(window.innerHeight);

  const [isEnabled, setEnabled] = useState(false);
  const toggleEnabled = useCallback(toggleEnabledHandler(setEnabled, isEnabled));

  React.useEffect(() => {
    const sessionStorageIsEnabled =
      sessionStorage.getItem('AskMeWebLibrary-isEnabled') &&
      JSON.parse(sessionStorage.getItem('AskMeWebLibrary-isEnabled')).isEnabled;
    if (typeof sessionStorageIsEnabled === 'boolean') {
      setEnabled(sessionStorageIsEnabled);
    }
  }, [setEnabled]);

  useEffect(() => {
    window.AskMeChatBot.hideChatWindow = toggleEnabledHandler(setEnabled, true);
    window.AskMeChatBot.showChatWindow = toggleEnabledHandler(setEnabled, false).bind(this);
    window.AskMeChatBot.checkLocalLang = checkLocalLang(setLocalLang, checkLang(localLang));
  }, [localLang]);

  useEffect(() => {
    if (userInfo) {
      setIsRegistrationClient(true);
      dispatch({
        type: initializationActionTypes.SET_STORAGE_CLIENT_INFO,
        payload: userInfo,
      });
    }
  }, [dispatch, userInfo]);

  const userRespId = useSelector(getResponseUserID);
  const { isCaptcha } = useSelector(getChannelSetting);

  React.useEffect(() => {
    dispatch(actionSetInitPosition({ externalConfig, alignment, windowCurrentHeight, windowCurrentWidth }));
  }, [externalConfig, alignment, windowCurrentHeight, windowCurrentWidth, dispatch]);

  React.useEffect(() => {
    const onResize = () => {
      setWindowCurrentWidth(window.innerWidth);
      setWindowCurrentHeight(window.innerHeight);
    };
    const onResizeThrottled = throttle(onResize, 100);

    window.addEventListener('resize', onResizeThrottled);
    onResize();

    return () => {
      window.removeEventListener('resize', onResizeThrottled);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!hideIcon && !isEnabled && (
        // eslint-disable-next-line react/prop-types
        <ChatIcon lang={checkLang(localLang)} toggleEnabled={toggleEnabled} iconConfig={externalConfig?.icon || {}} />
      )}
      {isEnabled && (
        <WindowWebUI
          isEnabled={isEnabled}
          setEnabled={setEnabled}
          clientData={clientData}
          isShadowDom={isShadowDom}
          setLocalLang={setLocalLang}
          lang={checkLang(localLang)}
          scrollElement={scrollElement}
          toggleEnabled={toggleEnabled}
          setIsRegistrationClient={setIsRegistrationClient}
        />
      )}
      {!userRespId && isCaptcha && <ReCaptcha />}
    </>
  );
}

import { isEmpty } from 'lodash';
export var isNeedToChangeAgentsInfo = function (agentsInfo, messages, lang) {
    var _a, _b, _c;
    if (isEmpty(agentsInfo))
        return false;
    var i = 0;
    while (i < messages.length) {
        var nameLocales = (_a = agentsInfo[messages[i].sender]) === null || _a === void 0 ? void 0 : _a.nameLocales;
        if (messages[i].avatar !== ((_b = agentsInfo[messages[i].sender]) === null || _b === void 0 ? void 0 : _b.avatar) ||
            messages[i].agentName !== (!nameLocales || nameLocales[lang] || ((_c = agentsInfo[messages[i].sender]) === null || _c === void 0 ? void 0 : _c.name))) {
            return true;
        }
        i++;
    }
    return false;
};

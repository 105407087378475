import pathOr from 'ramda/es/pathOr';
import { resizableSlice } from '../slices/resizable.slice';
var name = resizableSlice.name;
export var selectFromResizable = function (field, defaultValue) { return pathOr(defaultValue, [name, field]); };
export var selectIsFullScreen = selectFromResizable('isFullScreen', false);
export var selectCurrentSize = selectFromResizable('currentSize', {
    width: 350,
    height: 500,
});
export var selectChatIconAlignment = selectFromResizable('chatIconAlignment', {
    rightPosition: 45,
    bottomPosition: 95,
});
export var selectChatWindowAlignment = selectFromResizable('chatWindowAlignment', {
    rightPosition: 45,
    bottomPosition: 95,
});
export var selectChatWindowPadding = selectFromResizable('chatWindowPadding', {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
});
export var selectWindowCurrentWidth = selectFromResizable('windowCurrentWidth', 0);
export var selectWindowCurrentHeight = selectFromResizable('windowCurrentHeight', 0);
export var selectCurrentPosition = selectFromResizable('currentPosition', {
    bottom: 95,
    right: 20,
});

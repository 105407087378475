import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import localization from '@localization';
import { getStylesEndDialogModal } from '@reducers/stylesReducer';
import { webSocketActionType, workStatesActionType } from '@type';
import { env } from '@mdca/wde';
import EndDialogModalWrap from './StyledEndDialogModal';
var EndDialogModal = function (_a) {
    var lang = _a.lang;
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch({
            type: workStatesActionType.SET_VISIBILITY_MODAL_IS_ERROR_NOTIFY,
            payload: {
                isErrorNotify: false,
                typeError: 0,
            },
        });
    }, [dispatch]);
    var showModalEndDialog = useCallback(function (boll) {
        dispatch({
            type: workStatesActionType.SET_VISIBILITY_MODAL_END_DIALOG,
            payload: boll,
        });
    }, [dispatch]);
    var endDialog = useCallback(function () {
        dispatch({ type: webSocketActionType.END_DIALOG });
    }, [dispatch]);
    var _b = useSelector(getStylesEndDialogModal), colorBtn = _b.colorBtn, textColor = _b.textColor, backdropColor = _b.backdropColor, backdropColorHover = _b.backdropColorHover, backdropColorBtn = _b.backdropColorBtn;
    return (React.createElement(EndDialogModalWrap, { colorBtn: colorBtn, textColor: textColor, backdropColor: backdropColor, backdropColorBtn: backdropColorBtn, backdropColorHover: backdropColorHover, lang: lang },
        React.createElement("img", { alt: "close modal", src: "".concat(env('ICON_WEB_UI'), "/close_modal.png"), onClick: function () { return showModalEndDialog(false); } }),
        React.createElement("div", { className: "modal-text" }, localization[lang]['end.dialog.test']),
        React.createElement("div", null,
            React.createElement("div", { className: "modal-btn-wrap" },
                React.createElement("div", { className: "modal-btn-wrap--ok", onClick: endDialog }, "OK")))));
};
export default EndDialogModal;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import { getAgentCallOffer } from '@reducers/callReducer';
import { getGreetingAndQuickPhrases, getIsClientBanned, getIsLoadingMessages, getMsgHistoryStore, } from '@reducers/initializationReducer';
import { getStylesChatWindow, getStylesHeader, getStylesScroll } from '@reducers/stylesReducer';
import { getIsErrorNotify, getIsVisibilityModalEndDialog, getTypeError } from '@reducers/workAppReducer';
import { getIsErrorServer, getIsTypingAgent } from '@reducers/workWSReducer';
import { workStatesActionType } from '@type';
import { useActions } from '../../hooks/useActions';
import Typing from './BotMessage/Typing/Typing';
import EndDialogModal from './EndDialogModal/EndDialogModal';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import ErrorNotify from './ErrorNotify/ErrorNotify';
import FastForwardIcon from './FastForwardButton/FastForwardButton';
import Greeting from './Greeting/GreetingComponnet';
import { translateGreeting } from './Greeting/localGreeting';
import { LoadingBackdrop } from './LoadingBackdrop/LoadingBackdrop';
import PrintHistory from './PrintHistory/PrintHistory';
import QuickReplyButtons from './QuickReplyButtons/QuickReplyButtons';
import { changeVisibilityHandler, checkScroll, scrollDown } from './BodyHelper';
import Call from './Call';
import BodyWrapper from './StyledBody';
import './Body.less';
export function Body(props) {
    var inputHeight = props.inputHeight, isFullScreen = props.isFullScreen, scrollElement = props.scrollElement, lang = props.lang;
    var updateClientOnlineStatusActions = useActions().updateClientOnlineStatusActions;
    var bodyRef = React.useRef(null);
    var _a = React.useState(false), isFastForwardButtonVisible = _a[0], setFastForwardButtonVisibility = _a[1];
    var dispatch = useDispatch();
    var messagesHistory = useSelector(getMsgHistoryStore);
    var QuickPhrases = useSelector(getGreetingAndQuickPhrases);
    var isAgentTyping = useSelector(getIsTypingAgent);
    var isLoadingMessages = useSelector(getIsLoadingMessages);
    var isErrorServer = useSelector(getIsErrorServer);
    var isVisibilityModalEndDialog = useSelector(getIsVisibilityModalEndDialog);
    var headerHeight = useSelector(getStylesHeader).headerHeight;
    var isErrorNotify = useSelector(getIsErrorNotify);
    var typeError = useSelector(getTypeError);
    var isBanned = useSelector(getIsClientBanned);
    var _b = useSelector(getStylesChatWindow), chatWindowsBorderColor = _b.chatWindowsBorderColor, chatWindowsBodyBackground = _b.chatWindowsBodyBackground, chatWindowsLogo = _b.chatWindowsLogo, chatWindowsLogoSize = _b.chatWindowsLogoSize;
    var _c = useSelector(getStylesScroll), scrollWidth = _c.scrollWidth, scrollBackground = _c.scrollBackground, scrollThumbBackground = _c.scrollThumbBackground;
    var changeVisibility = function () { return changeVisibilityHandler(scrollElement, setFastForwardButtonVisibility); };
    var scroll = React.useCallback(function () { return checkScroll(scrollElement, scrollDown); }, [scrollElement]);
    React.useEffect(function () {
        window.addEventListener('orientationchange', scroll);
        return function () { return window.removeEventListener('orientationchange', scroll); };
    }, [scroll]);
    var fastForwardHandler = React.useCallback(function () {
        return setTimeout(function () {
            scrollDown(scrollElement)();
        }, 100);
    }, [scrollElement]);
    var onlineStatus = function () {
        updateClientOnlineStatusActions(2);
    };
    var offlineStatus = function () { return updateClientOnlineStatusActions(1); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var updateClientStatus = function () {
        if (document.visibilityState === 'visible') {
            onlineStatus();
        }
        else {
            offlineStatus();
        }
    };
    React.useEffect(function () {
        var url = window.location.href;
        var pageName = document.title;
        dispatch({ type: workStatesActionType.SET_URL_AND_TITLE_PAGE, payload: { url: url, pageName: pageName } });
    }, [dispatch]);
    var greeting = translateGreeting(lang, QuickPhrases);
    React.useEffect(function () {
        window.addEventListener('visibilitychange', updateClientStatus);
        return function () { return window.removeEventListener('visibilitychange', updateClientStatus); };
    }, [updateClientStatus]);
    var offer = useSelector(getAgentCallOffer);
    return (React.createElement(BodyWrapper, { ref: bodyRef, id: "body-wrapper", className: "AskMeWebLibrary-Chat-window__body AskMeWebLibrary-body", scrollWidth: scrollWidth, scrollBackground: scrollBackground, scrollThumbBackground: scrollThumbBackground, headerHeight: headerHeight, inputHeight: inputHeight, isBanned: isBanned, chatWindowsBorderColor: chatWindowsBorderColor, chatWindowsBodyBackground: chatWindowsBodyBackground, chatWindowsLogo: chatWindowsLogo, chatWindowsLogoSize: chatWindowsLogoSize },
        offer && React.createElement(Call, { lang: lang, offer: offer }),
        isFastForwardButtonVisible && React.createElement(FastForwardIcon, { fastForwardHandler: fastForwardHandler }),
        isVisibilityModalEndDialog ? React.createElement(EndDialogModal, { lang: lang }) : null,
        isErrorNotify || isBanned ? React.createElement(ErrorNotify, { lang: lang }) : null,
        React.createElement(Scrollbar, { ref: scrollElement, onScroll: changeVisibility, tabIndex: 1, style: { outline: 'none' } }, isErrorServer ? (React.createElement(ErrorMessage, { lang: lang })) : typeError === 3 ? null : (React.createElement("div", { id: "body-box", className: "AskMeWebLibrary-body__box", style: { padding: '12px' } },
            React.createElement(LoadingBackdrop, { loading: isLoadingMessages, progressSize: 70 }),
            greeting ? React.createElement(Greeting, { greeting: greeting, lang: lang }) : null,
            messagesHistory.length === 0 ? React.createElement(QuickReplyButtons, { lang: lang }) : null,
            React.createElement(PrintHistory, { lang: lang, isFullScreen: isFullScreen, scrollElement: scrollElement, messagesHistory: messagesHistory }),
            React.createElement(Typing, { id: "typing", isTyping: isAgentTyping, fastForwardHandler: fastForwardHandler }))))));
}

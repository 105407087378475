export default {
    en: {
        'input.placeholder': 'Enter your message',
        'title.text': 'ASK ME',
        'menu.logOut': 'Log out',
        'menu.fullscreen': 'Fullscreen',
        'menu.windowedMode': 'Restore down',
        'menu.mute': 'Mute',
        'menu.unmute': 'Unmute',
        'icon.text': 'Can i help?',
        'btn.auth': 'Sign up',
        'btn.signin': 'Sign in',
        'btn.next': 'Next',
        'btn.back': 'Back',
        'bot.Name': 'Assistant ASK ME',
        'error.message': 'wait please, technical works are underway',
        'firstName.label': 'First name',
        'lastName.label': 'Last name',
        'userName.label': 'User name',
        'client.You': 'You',
        'contextMenu.quote': 'Quote',
        'contextMenu.download': 'Download',
        'phone.label': 'Phone number',
        'email.label': 'Email',
        'invoiceNumber.label': 'Invoice number',
        'firstName.placeholder': 'first name',
        'lastName.placeholder': 'last name',
        'userName.placeholder': 'user name',
        'phone.placeholder': 'phone number',
        'email.placeholder': 'email',
        'invoiceNumber.placeholder': 'invoice number',
        'error.validation': 'This field is not valid!',
        'error.required': 'This field is required!',
        'please.write.your': 'Please write your',
        'end.dialog.test': 'If you want to end the dialogue, click OK',
        'end.dialog.msg': 'This dialogue was finished!',
        'system.call.msg.call_started': 'Call started',
        'system.call.msg.call_rejected': 'Call declined',
        'system.call.msg.call_ended': 'Call finished',
        'system.call.msg.call_missed': 'Call missed',
        'system.call.answer': 'Answer',
        'system.call.decline': 'Decline',
        'system.call.finish_call': 'Finish call',
        'accountNumber.label': 'Account number',
        'accountNumber.placeholder': 'account number',
        'tooltip.close': 'finish dialogue',
    },
    hu: {
        'input.placeholder': 'Írja be az üzenetet',
        'title.text': 'ASK ME',
        'menu.logOut': 'Kijelentkezés',
        'menu.fullscreen': 'Teljes képernyő',
        'menu.windowedMode': 'Állítsa vissza',
        'menu.mute': 'Néma',
        'menu.unmute': 'Némítás feloldása',
        'icon.text': 'Segíthetek?',
        'btn.auth': 'Regisztrálj',
        'btn.signin': 'Bejelentkezés',
        'btn.next': 'Következő',
        'btn.back': 'Vissza',
        'bot.Name': 'Assistant ASK ME',
        'error.message': 'várjon, a műszaki munkák folynak',
        'firstName.label': 'Keresztnév',
        'lastName.label': 'Vezetéknév',
        'userName.label': 'Felhasználónév',
        'client.You': 'Ön',
        'contextMenu.quote': 'Idézet',
        'contextMenu.download': 'Letöltés',
        'phone.label': 'Telefonszám',
        'email.label': 'Email',
        'invoiceNumber.label': 'Számlaszám',
        'firstName.placeholder': 'keresztnév',
        'lastName.placeholder': 'vezetéknév',
        'userName.placeholder': 'felhasználónév',
        'phone.placeholder': 'telefonszám',
        'email.placeholder': 'email',
        'invoiceNumber.placeholder': 'számlaszám',
        'error.validation': 'Ez a mező nem érvényes!',
        'error.required': 'Ez a mező kötelező!',
        'please.write.your': 'Kérlek írd meg',
        'end.dialog.test': 'Ha be szeretné fejezni a párbeszédet, kattintson az OK gombra',
        'end.dialog.msg': 'Ez a párbeszéd véget ért!',
        'system.call.msg.call_started': 'A hívás elindult',
        'system.call.msg.call_rejected': 'A hívás elutasítva',
        'system.call.msg.call_ended': 'A hívás befejeződött',
        'system.call.msg.call_missed': 'Nem fogadott hívás',
        'system.call.answer': 'Válasz',
        'system.call.decline': 'Hanyatlás',
        'system.call.finish_call': 'Fejezze be a hívást',
        'accountNumber.label': 'Számlaszám',
        'accountNumber.placeholder': 'számlaszám',
        'tooltip.close': 'fejezze be a párbeszédet',
    },
    fr: {
        'input.placeholder': 'Tapez votre message',
        'title.text': 'ASK ME',
        'menu.logOut': 'Se déconnecter',
        'menu.fullscreen': 'Plein écran',
        'menu.windowedMode': 'Restaurer',
        'menu.mute': 'muet',
        'menu.unmute': 'réactiver',
        'icon.text': 'Puis-je aider?',
        'btn.auth': "S'inscrire",
        'btn.signin': "S'identifier",
        'btn.next': 'Suivant',
        'btn.back': 'Arrière',
        'bot.Name': 'Assistant ASK ME',
        'error.message': 'attendez svp, les travaux techniques sont en cours',
        'firstName.label': 'Prénom',
        'lastName.label': 'Nom de famille',
        'userName.label': "Nom d'utilisateur",
        'client.You': 'Toi',
        'contextMenu.quote': 'Citation',
        'contextMenu.download': 'Télécharger',
        'phone.label': 'Numéro de téléphone',
        'email.label': 'E-mail',
        'firstName.placeholder': 'prénom',
        'lastName.placeholder': 'nom de famille',
        'userName.placeholder': "nom d'utilisateur",
        'phone.placeholder': 'numéro de téléphone',
        'email.placeholder': 'e-mail',
        'invoiceNumber.label': 'Numéro de facture',
        'invoiceNumber.placeholder': 'invoice number',
        'error.validation': "Ce champ n'est pas valide!",
        'error.required': 'Ce champ est requis!',
        'please.write.your': 'Veuillez écrire votre',
        'end.dialog.test': 'Si vous souhaitez mettre fin au dialogue, cliquez sur OK',
        'end.dialog.msg': 'Ce dialogue était terminé!',
        'system.call.msg.call_started': 'Appel lancé',
        'system.call.msg.call_rejected': 'Appel refusé',
        'system.call.msg.call_ended': 'Appel terminé',
        'system.call.msg.call_missed': 'Appel manqué',
        'system.call.answer': 'Réponse',
        'system.call.decline': 'Déclin',
        'system.call.finish_call': "Terminer l'appel",
        'accountNumber.label': 'Numéro de compte',
        'accountNumber.placeholder': 'numéro de compte',
        'tooltip.close': 'terminer le dialogue',
    },
    pl: {
        'input.placeholder': 'Wpisz swoją wiadomość',
        'title.text': 'ASK ME',
        'menu.logOut': 'Wyloguj',
        'menu.fullscreen': 'pełny ekran',
        'menu.windowedMode': 'Przywróć w dół',
        'menu.mute': 'niemy',
        'menu.unmute': 'wyłącz wyciszenie',
        'icon.text': 'Czy mogę pomóc ?',
        'btn.auth': 'Zarejestrować się',
        'btn.signin': 'Zaloguj się',
        'btn.next': 'Następny',
        'btn.back': 'Z powrotem',
        'bot.Name': 'Asystent ASK ME',
        'error.message': 'proszę czekać, trwają prace techniczne',
        'firstName.label': 'Imię',
        'lastName.label': 'Nazwisko',
        'userName.label': 'Nazwa Użytkownika',
        'client.You': 'Ty',
        'contextMenu.quote': 'Zacytować',
        'contextMenu.download': 'Pobierać',
        'phone.label': 'Telefon numer',
        'email.label': 'E-mail',
        'firstName.placeholder': 'imię',
        'lastName.placeholder': 'nazwisko',
        'userName.placeholder': 'nazwa Użytkownika',
        'phone.placeholder': 'telefon numer',
        'email.placeholder': 'e-mail',
        'invoiceNumber.label': 'Numer faktury',
        'invoiceNumber.placeholder': 'numer faktury',
        'error.validation': 'To pole jest nieprawidłowe!',
        'error.required': 'To pole jest wymagane!',
        'please.write.your': 'Proszę napisz swój',
        'end.dialog.test': 'Jeśli chcesz zakończyć dialog, kliknij OK',
        'end.dialog.msg': 'Ten dialog został zakończony!',
        'system.call.msg.call_started': 'Rozmowa rozpoczęta',
        'system.call.msg.call_rejected': 'Połączenie odrzucone',
        'system.call.msg.call_ended': 'Rozmowa zakończona',
        'system.call.msg.call_missed': 'Nieodebrane połączenie',
        'system.call.answer': 'Odpowiedź',
        'system.call.decline': 'Spadek',
        'system.call.finish_call': 'Zakończ rozmowę',
        'accountNumber.label': 'Numer konta',
        'accountNumber.placeholder': 'numer konta',
        'tooltip.close': 'zakończ dialog',
    },
    ru: {
        'input.placeholder': 'Введите своё сообщение',
        'title.text': 'ASK ME',
        'menu.logOut': 'Выйти',
        'menu.fullscreen': 'Полноэкранный',
        'menu.windowedMode': 'Свернуть в окно',
        'menu.mute': 'Отключить звук',
        'menu.unmute': 'Включить звук',
        'icon.text': 'Могу помочь?',
        'btn.auth': 'Войти',
        'btn.signin': 'Войти',
        'btn.next': 'Дальше',
        'btn.back': 'Назад',
        'bot.Name': 'Помощник ASK ME',
        'error.message': 'подождите пожалуйста, ведутся технические работы',
        'firstName.label': 'Имя',
        'lastName.label': 'Фамилия',
        'userName.label': 'Имя пользователя',
        'client.You': 'Вы',
        'contextMenu.quote': 'Цитировать',
        'contextMenu.download': 'Скачать',
        'phone.label': 'Телефонный номер',
        'email.label': 'Электронная почта',
        'invoiceNumber.label': 'Номер счета',
        'invoiceNumber.placeholder': 'номер счета',
        'firstName.placeholder': 'имя',
        'lastName.placeholder': 'фамилию',
        'userName.placeholder': 'имя пользователя',
        'phone.placeholder': 'телефонный номер',
        'email.placeholder': 'электронную почту',
        'error.validation': 'Это поле не является валидным!',
        'error.required': 'Это поле является обязательным!',
        'please.write.your': 'Пожалуйста введите',
        'end.dialog.test': 'Если вы хотите завершить диалог, нажмите ОК',
        'end.dialog.msg': 'На этом моменте диалог был закончен!',
        'system.call.msg.call_started': 'Звонок начался',
        'system.call.msg.call_rejected': 'Звонок отклонен',
        'system.call.msg.call_ended': 'Звонок завершен',
        'system.call.msg.call_missed': 'Звонок пропущен',
        'system.call.answer': 'Ответить',
        'system.call.decline': 'Отклонить',
        'system.call.finish_call': 'Завершить вызов',
        'accountNumber.label': 'Номер акаунта',
        'accountNumber.placeholder': 'номер акаунта',
        'tooltip.close': 'завершить диалог',
    },
    ua: {
        'input.placeholder': 'Введіть своє повідомлення',
        'title.text': 'ASK ME',
        'menu.logOut': 'Вийти',
        'menu.fullscreen': 'Повноекранний',
        'menu.windowedMode': 'Згорнути у вікно',
        'menu.mute': 'Без звуку',
        'menu.unmute': 'Увімкнути звук',
        'icon.text': 'Можу допомогти?',
        'btn.auth': 'Увійти',
        'btn.signin': 'Увійти',
        'btn.next': 'Далі',
        'btn.back': 'Назад',
        'bot.Name': 'Помічник ASK ME',
        'error.message': 'почекайте, будь ласка, тривають технічні роботи',
        'firstName.label': "Ім'я",
        'lastName.label': 'Прізвище',
        'userName.label': "Ім'я користувача",
        'client.You': 'Ви',
        'contextMenu.quote': 'Цитувати',
        'contextMenu.download': 'Завантажити',
        'phone.label': 'Номер телефону',
        'email.label': 'Електронна пошта',
        'invoiceNumber.label': 'Номер рахунку',
        'invoiceNumber.placeholder': 'номер рахунку',
        'firstName.placeholder': "ім'я",
        'lastName.placeholder': 'прізвище',
        'userName.placeholder': "ім'я користувача",
        'phone.placeholder': 'номер телефону',
        'email.placeholder': 'електронну пошту',
        'error.validation': 'Це поле не є валідним!',
        'error.required': "Це поле є обов'язковим!",
        'please.write.your': 'Будь ласка, введіть',
        'end.dialog.test': 'Якщо ви хочете закінчити діалог, натисніть кнопку OK',
        'end.dialog.msg': 'Цей діалог закінчено!',
        'system.call.msg.call_started': 'Розпочався дзвінок',
        'system.call.msg.call_rejected': 'Дзвінок відхилено',
        'system.call.msg.call_ended': 'Дзвінок закінчено',
        'system.call.msg.call_missed': 'Дзвінок пропущено',
        'system.call.answer': 'Відповісти',
        'system.call.decline': 'Відхилити',
        'system.call.finish_call': 'Завершити дзвінок',
        'accountNumber.label': 'Номер акаунта',
        'accountNumber.placeholder': 'номер акаунта',
        'tooltip.close': 'завершити діалог',
    },
    de: {
        'input.placeholder': 'Gib deine Nachricht ein',
        'title.text': 'ASK ME',
        'menu.logOut': 'Ausloggen',
        'menu.fullscreen': 'Vollbildschirm',
        'menu.windowedMode': 'Unten wiederherstellen',
        'menu.mute': 'stumm',
        'menu.unmute': 'Klang',
        'icon.text': 'Kann ich helfen?',
        'btn.auth': 'Anmelden',
        'btn.signin': 'Einloggen',
        'btn.next': 'Nächster',
        'btn.back': 'Zurück',
        'bot.Name': 'Assistentin ASK ME',
        'error.message': 'warten Sie bitte, technische Arbeiten sind im Gange',
        'firstName.label': 'Vorname',
        'lastName.label': 'Nachname',
        'userName.label': 'Nutzername',
        'client.You': 'Sie',
        'contextMenu.quote': 'Zitat',
        'contextMenu.download': 'Herunterladen',
        'phone.label': 'Telefonnummer',
        'email.label': 'Email',
        'invoiceNumber.label': 'Rechnungsnummer',
        'invoiceNumber.placeholder': 'rechnungsnummer',
        'firstName.placeholder': 'vorname',
        'lastName.placeholder': 'nachname',
        'userName.placeholder': 'nutzername',
        'phone.placeholder': 'telefonnummer',
        'email.placeholder': 'email',
        'error.validation': 'Dieses Feld ist ungültig!',
        'error.required': 'Dieses feld wird benötigt!',
        'please.write.your': 'Bitte schreiben Sie Ihre',
        'end.dialog.test': 'Wenn Sie den Dialog beenden möchten, klicken Sie auf OK',
        'end.dialog.msg': 'Dieser Dialog war beendet!',
        'system.call.msg.call_started': 'Anruf gestartet',
        'system.call.msg.call_rejected': 'Anruf abgelehnt',
        'system.call.msg.call_ended': 'Anruf beendet',
        'system.call.msg.call_missed': 'Anruf verpasst',
        'system.call.answer': 'Antworten',
        'system.call.decline': 'Ablehnen',
        'system.call.finish_call': 'Anruf beenden',
        'accountNumber.label': 'Kontonummer',
        'accountNumber.placeholder': 'kontonummer',
        'tooltip.close': 'Dialog beenden',
    },
    es: {
        'input.placeholder': 'Ingrese su mensaje',
        'title.text': 'ASK ME',
        'menu.logOut': 'Cerrar sesión',
        'menu.fullscreen': 'pantalla completa',
        'menu.windowedMode': 'Restaurar abajo',
        'menu.mute': 'Mudo',
        'menu.unmute': 'activar el sonido',
        'icon.text': '¿Puedo ayudar?',
        'btn.auth': 'Regístrate',
        'btn.signin': 'Registrarse',
        'btn.next': 'Próximo',
        'btn.back': 'Atrás',
        'bot.Name': 'Asistente ASK ME',
        'error.message': 'espere por favor, los trabajos técnicos están en marcha',
        'firstName.label': 'Nombre de pila',
        'lastName.label': 'Apellido',
        'userName.label': 'Nombre de usuario',
        'client.You': 'Tú',
        'contextMenu.quote': 'Cita',
        'contextMenu.download': 'Descargar',
        'phone.label': 'Número de teléfono',
        'email.label': 'Email',
        'invoiceNumber.label': 'Número de factura',
        'invoiceNumber.placeholder': 'número de factura',
        'firstName.placeholder': 'nombre de pila',
        'lastName.placeholder': 'apellido',
        'userName.placeholder': 'nombre de usuario',
        'phone.placeholder': 'número de teléfono',
        'email.placeholder': 'email',
        'error.validation': '¡Este campo no es válido!',
        'error.required': '¡Este campo es obligatorio!',
        'please.write.your': 'Por favor escriba su',
        'end.dialog.test': 'Si desea finalizar el diálogo, haga clic en OK',
        'end.dialog.msg': '¡Este diálogo estaba terminado!',
        'system.call.msg.call_started': 'Llamada iniciada',
        'system.call.msg.call_rejected': 'Llamada rechazada',
        'system.call.msg.call_ended': 'Llamada finalizada',
        'system.call.msg.call_missed': 'Llamada perdida',
        'system.call.answer': 'Respuesta',
        'system.call.decline': 'Disminución',
        'system.call.finish_call': 'Terminar llamada',
        'accountNumber.label': 'Terminar llamada',
        'accountNumber.placeholder': 'número de cuenta',
        'tooltip.close': 'terminar el diálogo',
    },
    vi: {
        'input.placeholder': 'Nhập tin nhắn của bạn',
        'title.text': 'ASK ME',
        'menu.logOut': 'Đăng xuất',
        'menu.fullscreen': 'toàn màn hình',
        'menu.windowedMode': 'Khôi phục xuống',
        'menu.mute': 'người câm',
        'menu.unmute': 'bật tiếng',
        'icon.text': 'Tôi có thể giúp chứ?',
        'btn.auth': 'Đăng ký',
        'btn.signin': 'Đăng nhập',
        'btn.next': 'Kế tiếp',
        'btn.back': 'Trở lại',
        'bot.Name': 'Phụ tá ASK ME',
        'error.message': 'vui lòng đợi, các công việc kỹ thuật đang được tiến hành',
        'firstName.label': 'Tên đầu tiên',
        'lastName.label': 'Họ',
        'userName.label': 'Tên tài khoản',
        'client.You': 'Bạn',
        'contextMenu.quote': 'Trích dẫn',
        'contextMenu.download': 'Tải xuống',
        'phone.label': 'Số điện thoại',
        'email.label': 'Email',
        'invoiceNumber.label': 'Số hóa đơn',
        'invoiceNumber.placeholder': 'số hóa đơn',
        'firstName.placeholder': 'tên đầu tiên',
        'lastName.placeholder': 'họ',
        'userName.placeholder': 'tên tài khoản',
        'phone.placeholder': 'số điện thoại',
        'email.placeholder': 'email',
        'error.validation': 'Trường này không hợp lệ!',
        'error.required': 'Trường này là bắt buộc!',
        'please.write.your': 'Hãy viết của bạn',
        'end.dialog.test': 'Nếu bạn muốn kết thúc cuộc đối thoại, hãy nhấp vào OK',
        'end.dialog.msg': 'Cuộc đối thoại này đã kết thúc!',
        'system.call.msg.call_started': 'Cuộc gọi đã bắt đầu',
        'system.call.msg.call_rejected': 'Cuộc gọi bị từ chối',
        'system.call.msg.call_ended': 'Cuộc gọi đã kết thúc',
        'system.call.msg.call_missed': 'Cuộc gọi nhỡ',
        'system.call.answer': 'Câu trả lời',
        'system.call.decline': 'Từ chối',
        'system.call.finish_call': 'Kết thúc cuộc gọi',
        'accountNumber.label': 'Số tài khoản',
        'accountNumber.placeholder': 'số tài khoản',
        'tooltip.close': 'kết thúc cuộc đối thoại',
    },
    pt: {
        'input.placeholder': 'Digite sua mensagem',
        'title.text': 'ASK ME',
        'menu.logOut': 'Sair',
        'menu.fullscreen': 'tela cheia',
        'menu.windowedMode': 'Restaurar para baixo',
        'menu.mute': 'mudo',
        'menu.unmute': 'não mudo',
        'icon.text': 'Posso ajudar?',
        'btn.auth': 'Inscrever-se',
        'btn.signin': 'Entrar',
        'btn.next': 'Próximo',
        'btn.back': 'Voltar',
        'bot.Name': 'Assistente ASK ME',
        'error.message': 'espere por favor, trabalhos técnicos estão em andamento',
        'firstName.label': 'Primeiro nome',
        'lastName.label': 'Último nome',
        'userName.label': 'Nome do usuário',
        'client.You': 'Vocês',
        'contextMenu.quote': 'Citar',
        'contextMenu.download': 'Baixar',
        'phone.label': 'Número de telefone',
        'email.label': 'O email',
        'invoiceNumber.label': 'Número da fatura',
        'invoiceNumber.placeholder': 'número da fatura',
        'firstName.placeholder': 'primeiro nome',
        'lastName.placeholder': 'ultimo nome',
        'userName.placeholder': 'nome do usuário',
        'phone.placeholder': 'número de telefone',
        'email.placeholder': 'o email',
        'error.validation': 'Este campo não é válido!',
        'error.required': 'Este campo é obrigatório!',
        'please.write.your': 'Por favor escreva o seu',
        'end.dialog.test': 'Se você quiser encerrar o diálogo, clique em OK',
        'end.dialog.msg': 'Este diálogo foi encerrado!',
        'system.call.msg.call_started': 'Chamada iniciada',
        'system.call.msg.call_rejected': 'Chamada recusada',
        'system.call.msg.call_ended': 'Chamada terminada',
        'system.call.msg.call_missed': 'Chamada perdida',
        'system.call.answer': 'Responder',
        'system.call.decline': 'Declínio',
        'system.call.finish_call': 'Terminar chamada',
        'accountNumber.label': 'Número de conta',
        'accountNumber.placeholder': 'número de conta',
        'tooltip.close': 'kết thúc cuộc đối thoại',
    },
    ko: {
        'input.placeholder': '메시지를 입력하세요',
        'title.text': 'ASK ME',
        'menu.logOut': '로그 아웃',
        'menu.fullscreen': '전체 화면',
        'menu.windowedMode': '아래로 복원',
        'menu.mute': '음소거',
        'menu.unmute': '음소거 해제',
        'icon.text': '내가 도움이 될 수 있습니다?',
        'btn.auth': '가입하기',
        'btn.signin': '로그인',
        'btn.next': '다음',
        'btn.back': '뒤',
        'bot.Name': '조수 ASK ME',
        'error.message': '기다려주십시오. 기술 작업이 진행 중입니다.',
        'firstName.label': '이름',
        'lastName.label': '성',
        'userName.label': '사용자 이름',
        'client.You': '당신',
        'contextMenu.quote': '인용문',
        'contextMenu.download': '다운로드',
        'phone.label': '전화 번호',
        'email.label': '이메일',
        'invoiceNumber.label': '송장 번호',
        'invoiceNumber.placeholder': '송장 번호',
        'firstName.placeholder': '이름',
        'lastName.placeholder': '성',
        'userName.placeholder': '사용자 이름',
        'phone.placeholder': '전화 번호',
        'email.placeholder': '이메일',
        'error.validation': '이 필드는 유효하지 않습니다!',
        'error.required': '이 필드는 필수입니다!',
        'please.write.your': '당신의',
        'end.dialog.test': '대화를 끝내려면 OK',
        'end.dialog.msg': '이 대화는 끝났습니다!',
        'system.call.msg.call_started': '통화 시작됨',
        'system.call.msg.call_rejected': '통화 거부됨',
        'system.call.msg.call_ended': '통화 종료',
        'system.call.msg.call_missed': '부재중 전화',
        'system.call.answer': '대답',
        'system.call.decline': '감소',
        'system.call.finish_call': '통화 종료',
        'accountNumber.label': '계좌 번호',
        'accountNumber.placeholder': '계좌 번호',
        'tooltip.close': '대화를 끝내다',
    },
    it: {
        'input.placeholder': 'Scrivi il tuo messaggio',
        'title.text': 'ASK ME',
        'menu.logOut': 'Disconnettersi',
        'menu.fullscreen': 'a schermo intero',
        'menu.windowedMode': 'Ripristino',
        'menu.mute': 'muto',
        'menu.unmute': "riattiva l'audio",
        'icon.text': 'Posso aiutare?',
        'btn.auth': 'Iscriviti',
        'btn.signin': 'Registrazione',
        'btn.next': 'Il prossimo',
        'btn.back': 'Indietro',
        'bot.Name': 'Assistente ASK ME',
        'error.message': 'attendere prego, i lavori tecnici sono in corso',
        'firstName.label': 'Nome di battesimo',
        'lastName.label': 'Cognome',
        'userName.label': 'Nome utente',
        'client.You': 'voi',
        'contextMenu.quote': 'Citazione',
        'contextMenu.download': 'Scarica',
        'phone.label': 'Numero di telefono',
        'email.label': 'E-mail',
        'invoiceNumber.label': 'Numero di fattura',
        'invoiceNumber.placeholder': 'numero di fattura',
        'firstName.placeholder': 'nome di battesimo',
        'lastName.placeholder': 'cognome',
        'userName.placeholder': 'nome utente',
        'phone.placeholder': 'numero di telefono',
        'email.placeholder': 'e-mail',
        'error.validation': 'Questo campo non è valido!',
        'error.required': 'Questo campo è obbligatorio!',
        'please.write.your': 'Per favore scrivi il tuo',
        'end.dialog.test': 'Se desideri terminare il dialogo, fai clic su OK',
        'end.dialog.msg': 'Questo dialogo era finito!',
        'system.call.msg.call_started': 'Chiamata iniziata',
        'system.call.msg.call_rejected': 'Chiamata rifiutata',
        'system.call.msg.call_ended': 'Chiamata terminata',
        'system.call.msg.call_missed': 'Chiamata persa',
        'system.call.answer': 'Risposta',
        'system.call.decline': 'Declino',
        'system.call.finish_call': 'Termina la chiamata',
        'accountNumber.label': 'Numero di conto',
        'accountNumber.placeholder': 'numero di conto',
        'tooltip.close': 'finire il dialogo',
    },
    ar: {
        'input.placeholder': 'أدخل رسالتك',
        'title.text': 'ASK ME',
        'menu.logOut': 'تسجيل خروج',
        'menu.fullscreen': 'شاشة كاملة',
        'menu.windowedMode': 'تصغير',
        'menu.mute': 'كتم الصوت',
        'menu.unmute': 'غير كتم الصوت',
        'icon.text': 'هل يمكنني المساعدة؟',
        'btn.auth': 'سجل',
        'btn.signin': 'تسجيل الدخول',
        'btn.next': 'التالي',
        'btn.back': 'عودة',
        'bot.Name': ' ASK ME مساعد',
        'error.message': 'انتظر من فضلك ، الأعمال الفنية جارية',
        'firstName.label': 'الاسم الاول',
        'lastName.label': 'الكنية',
        'userName.label': 'اسم المستخدم',
        'client.You': 'أنت',
        'contextMenu.quote': 'اقتبس',
        'contextMenu.download': 'تحميل',
        'phone.label': 'رقم الهاتف',
        'email.label': 'البريد الإلكتروني',
        'invoiceNumber.label': 'رقم الفاتورة',
        'invoiceNumber.placeholder': 'رقم الفاتورة',
        'firstName.placeholder': 'اسم الاول',
        'lastName.placeholder': 'الكنية',
        'userName.placeholder': 'اسم المستخدم',
        'phone.placeholder': 'رقم الهاتف',
        'email.placeholder': 'البريد الإلكتروني',
        'error.validation': 'هذا الحقل غير صالح!',
        'error.required': 'هذه الخانة مطلوبه!',
        'please.write.your': 'من فضلك اكتب',
        'end.dialog.test': 'OK إذا كنت تريد إنهاء الحوار ، انقر فوق',
        'end.dialog.msg': 'انتهى هذا الحوار!',
        'system.call.msg.call_started': 'بدأت المكالمة',
        'system.call.msg.call_rejected': 'رفض الاتصال',
        'system.call.msg.call_ended': 'انتهى الاتصال',
        'system.call.msg.call_missed': 'مكالمة فائتة',
        'system.call.answer': 'إجابه',
        'system.call.decline': 'انخفاض',
        'system.call.finish_call': 'إنهاء المكالمة',
        'accountNumber.label': 'رقم حساب',
        'accountNumber.placeholder': 'رقم حساب',
        'tooltip.close': 'إنهاء الحوار',
    },
};

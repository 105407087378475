import React from 'react';
import { useSelector } from 'react-redux';
import { getInfoAgentStore } from '@reducers/initializationReducer';
import { getStylesAgentMsg } from '@reducers/stylesReducer';
import { getAgentOnlineStatus } from '@reducers/workWSReducer';
import { env } from '@mdca/wde';
import BotMessageOnlineStatusIcon from './BotMessageOnlineStatusIcon';
import BotMessageIconWrapper from './StyledBotMessageIcon';
import './BotMessageIcon.less';
var BotMessageIcon = function (_a) {
    var avatar = _a.avatar, sender = _a.sender;
    var _b = useSelector(getStylesAgentMsg), messageIconUrlAgent = _b.messageIconUrlAgent, messageIconBackgroundAgent = _b.messageIconBackgroundAgent;
    var currentAgent = useSelector(getInfoAgentStore).id;
    var isOnline = useSelector(getAgentOnlineStatus);
    return (React.createElement(BotMessageIconWrapper, { className: "AskMeWebLibrary-bot-message__icon", messageIconBackgroundAgent: messageIconBackgroundAgent, title: isOnline ? 'Online' : 'Offline' },
        React.createElement("img", { src: avatar || messageIconUrlAgent || "".concat(env('ICON_WEB_UI'), "/client.svg"), alt: "" }),
        currentAgent === sender && React.createElement(BotMessageOnlineStatusIcon, { isOnline: isOnline })));
};
export default BotMessageIcon;

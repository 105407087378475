import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getStylesClientMsg } from '@reducers/stylesReducer';
import isEqual from 'lodash/isEqual';
import { scrollDown } from '../BodyHelper';
import { MessageBubble } from '../MessageBubble';
import { printMessage } from '../PrintMessage/PrintMessage';
import QuotedMessage from '../QuotedMessage/QuotedMessage';
import Time from './Time/Time';
import Message from './StyledMessage';
import MessageBlock from './StyledMessageBlock';
import MessageWrapper from './StyledMessageWrapper';
import './UserMessage.less';
// eslint-disable-next-line react/function-component-definition
var UserMessage = function (_a) {
    var value = _a.value, scrollElement = _a.scrollElement, hasIcon = _a.hasIcon;
    useEffect(function () {
        setTimeout(scrollDown(scrollElement), 1);
    }, [scrollElement]);
    var _b = useSelector(getStylesClientMsg), clientLink = _b.clientLink, clientHoverLink = _b.clientHoverLink, clientTimeColor = _b.clientTimeColor, clientActiveLink = _b.clientActiveLink, clientVisitedLink = _b.clientVisitedLink, clientMessageColor = _b.clientMessageColor, clientMessageBorderColor = _b.clientMessageBorderColor;
    return (React.createElement(MessageWrapper, { className: "AskMeWebLibrary-body__user-message AskMeWebLibrary-user-message", hasIcon: hasIcon },
        React.createElement(MessageBlock, { className: "AskMeWebLibrary-user-message__wrapper AskMeWebLibrary-wrapper", hasIcon: hasIcon, clientLink: clientLink, clientHoverLink: clientHoverLink, clientActiveLink: clientActiveLink, clientVisitedLink: clientVisitedLink },
            React.createElement(Time, { time: value.time, clientTimeColor: clientTimeColor }),
            React.createElement(MessageBubble, { data: value, isUserMessage: false },
                React.createElement(Message, { imgStyle: value.type === 3, clientMessageBorderColor: clientMessageBorderColor, className: "AskMeWebLibrary-wrapper__message AskMeWebLibrary-message" },
                    React.createElement(QuotedMessage, { type: value.type, quotedMessage: value.quotedMessage }),
                    printMessage({ type: value.type, text: value.responseText }))))));
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(UserMessage, areEqual);

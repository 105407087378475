import React from 'react';
import { useSelector } from 'react-redux';
import { checkObj } from '@helpers/checkObj';
import localization from '@localization';
import { getChannelSetting, getInfoAgentStore } from '@reducers/initializationReducer';
import { getStylesGreeting } from '@reducers/stylesReducer';
import isEqual from 'lodash/isEqual';
import { env } from '@mdca/wde';
import BotMessageOnlineStatusIcon from '../BotMessage/Icon/BotMessageOnlineStatusIcon';
import './Greeting.less';
var Greeting = function (_a) {
    var lang = _a.lang, greeting = _a.greeting;
    var botNameLocales = useSelector(getChannelSetting).botNameLocales;
    var _b = useSelector(getStylesGreeting), messageGreetingColor = _b.messageGreetingColor, colorGreetingBotName = _b.colorGreetingBotName, messageGreetingIconUrlBot = _b.messageGreetingIconUrlBot, messageGreetingBorderColor = _b.messageGreetingBorderColor, messageGreetingBackgroundColor = _b.messageGreetingBackgroundColor, messageGreetingIconBackgroundBot = _b.messageGreetingIconBackgroundBot;
    var currentAgent = useSelector(getInfoAgentStore).id;
    return (React.createElement("div", { className: "AskMeWebLibrary-body__bot-message AskMeWebLibrary-bot-message" },
        React.createElement("div", { className: "AskMeWebLibrary-bot-message__icon", style: { background: messageGreetingIconBackgroundBot || '#F5F5F5' } },
            React.createElement("img", { alt: "Greeting", src: messageGreetingIconUrlBot || "".concat(env('ICON_WEB_UI'), "/client.svg") }),
            !currentAgent && React.createElement(BotMessageOnlineStatusIcon, { isOnline: true })),
        React.createElement("div", { className: "AskMeWebLibrary-bot-message__answer AskMeWebLibrary-answer" },
            React.createElement("p", { className: "greeting-name", style: { color: colorGreetingBotName || '#4f4343' } }, checkObj(botNameLocales, lang) || localization[lang]['bot.Name']),
            React.createElement("div", { className: "AskMeWebLibrary-answer__message AskMeWebLibrary-message", style: {
                    padding: '10px 15px 10px 15px',
                    maxWidth: '100%',
                    color: messageGreetingColor || '#434343',
                    background: messageGreetingBackgroundColor || '#E5E5E5',
                    border: messageGreetingBorderColor ? "1px solid ".concat(messageGreetingBorderColor) : "1px solid #E5E5E5",
                } }, greeting))));
};
export var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(Greeting, areEqual);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import localization from '@localization';
import { getIsAuthConnection } from '@reducers/initializationReducer';
import { getStylesFooter } from '@reducers/stylesReducer';
import { getIsQuoting, getSavedCurrentMessage } from '@reducers/workAppReducer';
import { workStatesActionType } from '@type';
import { env } from '@mdca/wde';
import { useActions } from '../../hooks/useActions';
import Quote from './Quote/Quote';
import SendFileBox from './SendFileBox/SendFileBox';
import { changeHeight, cutValue } from './FooterHelper';
import FooterWrapper from './StyledFooter';
import InputWrapper from './StyledInputWrapper';
import './Footer.less';
var Footer = function (props) {
    var lang = props.lang, sendMessage = props.sendMessage, currentWidth = props.currentWidth, isFullScreen = props.isFullScreen, isRightResize = props.isRightResize, scrollElement = props.scrollElement, setInputHeight = props.setInputHeight, sendClientTyping = props.sendClientTyping;
    var dispatch = useDispatch();
    var setIsQuoting = useActions().setIsQuoting;
    var isAuthConnection = useSelector(getIsAuthConnection);
    var isQuoting = useSelector(getIsQuoting);
    var savedCurrentMessage = useSelector(getSavedCurrentMessage);
    var _a = useSelector(getStylesFooter), sendImage = _a.sendImage, inputColor = _a.inputColor, inputPlaceholder = _a.inputPlaceholder, footerBackground = _a.footerBackground, inputBorderColor = _a.inputBorderColor, footerBorderColor = _a.footerBorderColor, sendButtonIconWidth = _a.sendButtonIconWidth, sendButtonIconHeight = _a.sendButtonIconHeight, footerBackgroundTextArea = _a.footerBackgroundTextArea;
    var msgInput = useRef(null);
    var _b = useState(savedCurrentMessage), inputValue = _b[0], setInputValue = _b[1];
    var _c = useState(0), lastTypingTime = _c[0], setLastTypingTime = _c[1];
    var setInputHandler = useCallback(function (e) {
        scrollElement ? (scrollElement.current.scrollTop = scrollElement.current.scrollHeight) : null;
        setInputValue(e.target.value);
    }, []);
    var keyPressHandler = function (e) {
        var _a, _b;
        if (e.key === 'Enter') {
            var inputLastChar = inputValue.at(-1);
            var inputFirstChar = inputValue[0];
            if (!e.shiftKey) {
                if (inputValue.trim() === '')
                    return;
                sendMessage(inputValue);
                setInputValue('');
                setLastTypingTime(0);
                if (msgInput.current && ((_b = (_a = msgInput.current) === null || _a === void 0 ? void 0 : _a.base) === null || _b === void 0 ? void 0 : _b.focus)) {
                    msgInput.current.base.focus();
                }
            }
            else if ((e.shiftKey && inputLastChar === '\n') || (e.shiftKey && inputFirstChar === '\n')) {
                setInputValue(cutValue(inputValue));
            }
        }
    };
    var changeHeightHandler = changeHeight(setInputHeight, inputValue, setInputValue);
    var buttonClickHandler = function (e) {
        var _a, _b;
        if (inputValue.trim() === '')
            return;
        e.preventDefault();
        sendMessage(inputValue);
        setInputValue('');
        if (msgInput.current && ((_b = (_a = msgInput.current) === null || _a === void 0 ? void 0 : _a.base) === null || _b === void 0 ? void 0 : _b.focus)) {
            msgInput.current.base.focus();
        }
    };
    var sendTyping = function (e) {
        setInputHandler(e);
        if (e.data === null) {
            return;
        }
        if (Date.now() - lastTypingTime < 3000)
            return;
        setLastTypingTime(Date.now());
        sendClientTyping();
    };
    var saveCurrentMessage = function () {
        dispatch({
            type: workStatesActionType.SET_SAVE_CURRENT_MESSAGE,
            payload: inputValue,
        });
    };
    useEffect(function () {
        saveCurrentMessage();
    }, [inputValue]);
    var onCloseHandler = function () {
        setIsQuoting({
            data: null,
            state: false,
        });
    };
    return (React.createElement("div", null,
        React.createElement(FooterWrapper, { id: "footer-wrapper", isFullScreen: isFullScreen, className: "AskMeWebLibrary-Chat-window___footer AskMeWebLibrary-footer", currentWidth: currentWidth, isRightResize: isRightResize, footerBackground: footerBackground, footerBorderColor: footerBorderColor, style: lang === 'ar' ? { direction: 'rtl' } : {} },
            isQuoting.state && isQuoting.data && (React.createElement(Quote, { lang: lang, quotedMessageData: isQuoting.data, onCloseHandler: onCloseHandler })),
            React.createElement(InputWrapper, { id: "footer-input", className: "AskMeWebLibrary-footer__input-wrapper AskMeWebLibrary-input-wrapper", inputBorderColor: inputBorderColor, footerBackgroundTextArea: footerBackgroundTextArea },
                React.createElement(TextareaAutosize, { id: "input-field", className: "AskMeWebLibrary-input-wrapper__input", wrap: "soft", maxRows: 10, name: "input", value: inputValue, onChange: sendTyping, style: { color: inputColor || '#12171e' }, placeholder: inputPlaceholder || localization[lang]['input.placeholder'], onKeyDown: keyPressHandler, onHeightChange: changeHeightHandler, ref: msgInput }),
                React.createElement("div", { className: "send-box-wrapper" },
                    React.createElement(SendFileBox, null),
                    React.createElement("button", { id: "send-button", className: "AskMeWebLibrary-input-wrapper__button", disabled: !isAuthConnection, onClick: buttonClickHandler },
                        React.createElement("img", { src: sendImage || "".concat(env('ICON_WEB_UI'), "/new-send.svg"), alt: "send", style: { height: sendButtonIconHeight || '22px', width: sendButtonIconWidth || '25px' } })))))));
};
export default Footer;

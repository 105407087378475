var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializationActions } from '@actions';
import apiConfig from '@api/apiConfig';
import * as chatWSAPI from '@api/chatWS';
import { getChannelSetting, getClientInfo } from '@reducers/initializationReducer';
import { getIsConnectedWebSocket } from '@reducers/workWSReducer';
import { initializationActionTypes, webSocketActionType } from '@type';
import { env } from '@mdca/wde';
import { useActions } from '../../../hooks/useActions';
import { sendMessageHandler } from '../../logic/ChatWindowHelper';
import { Window } from '../Window/Window';
var ConnectionWS = function (props) {
    var lang = props.lang;
    var channelId = useSelector(getChannelSetting).id;
    var isConnectedWebSocket = useSelector(getIsConnectedWebSocket);
    var _a = useActions(), gotAgentOffer = _a.gotAgentOffer, setAgentStopCall = _a.setAgentStopCall, gotAgentIceCandidate = _a.gotAgentIceCandidate;
    var dispatch = useDispatch();
    var clientInfo = useSelector(getClientInfo);
    var _b = useActions(), setIsConnectedWS = _b.setIsConnectedWS, sendNewMessageSagaActions = _b.sendNewMessageSagaActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var sendMessage = React.useCallback(sendMessageHandler(lang, sendNewMessageSagaActions), [channelId, lang]);
    React.useEffect(function () {
        if (!isConnectedWebSocket) {
            var authConnection_1 = function () {
                var data = __assign(__assign({}, clientInfo), { channelId: channelId, messenger: 'smartbot' });
                dispatch({
                    type: webSocketActionType.AUTH_CONNECTION_SAGA,
                    payload: { channelId: channelId, infoUser: data },
                });
            };
            var getMessagesAgent_1 = function (message) {
                dispatch({
                    type: webSocketActionType.GET_MESSAGE,
                    payload: message,
                });
            };
            var getIsBannedClient_1 = function () {
                dispatch({
                    type: initializationActionTypes.SET_IS_CLIENT_BANNED,
                    payload: true,
                });
            };
            var getAgentOnlineStatus_1 = function (data) {
                dispatch({
                    type: webSocketActionType.SET_AGENT_ONLINE_STATUS,
                    payload: data,
                });
            };
            var onAgentFinishDialog_1 = function () {
                dispatch(initializationActions.setAgent({}));
            };
            var getEditedMessage_1 = function (data) {
                dispatch({
                    type: webSocketActionType.SET_EDITED_MESSAGE,
                    payload: data,
                });
            };
            var getDeletedMessage_1 = function (data) {
                dispatch({
                    type: webSocketActionType.SET_DELETED_MESSAGE,
                    payload: data,
                });
            };
            var getIsUnbannedClient_1 = function () {
                dispatch({
                    type: initializationActionTypes.SET_IS_CLIENT_BANNED,
                    payload: false,
                });
            };
            var getAgentInfo_1 = function (data) {
                var args = data.args;
                dispatch({
                    type: initializationActionTypes.ASSIGNING_AGENT,
                    payload: args,
                });
            };
            var getAgentTyping_1 = function () {
                dispatch({
                    type: webSocketActionType.SET_IS_TYPING_AGENT,
                    payload: true,
                });
            };
            var getFileAgent_1 = function (data) {
                dispatch({
                    type: webSocketActionType.GET_AGENT_FILE,
                    payload: data,
                });
            };
            var getAgentCallOffer_1 = function (data) {
                gotAgentOffer(data.args);
            };
            var gotAgentIceCandidateHandle_1 = function (data) {
                gotAgentIceCandidate(data.args);
            };
            var agentStopCall_1 = function (data) {
                setAgentStopCall(data.args);
            };
            var setup = function () {
                chatWSAPI.newAgent(getAgentInfo_1);
                chatWSAPI.newMessage(getMessagesAgent_1);
                chatWSAPI.newAgentQuote(getMessagesAgent_1);
                chatWSAPI.onDeleteMessage(getDeletedMessage_1);
                chatWSAPI.onAgentCall(getAgentCallOffer_1);
                chatWSAPI.onAgentIceCandidate(gotAgentIceCandidateHandle_1);
                chatWSAPI.onAgentStopCall(agentStopCall_1);
                chatWSAPI.onUpdateAgentStatus(getAgentOnlineStatus_1);
                chatWSAPI.onMessageEdited(getEditedMessage_1);
                chatWSAPI.clientBan(getIsBannedClient_1);
                chatWSAPI.onAgentFinishDialog(onAgentFinishDialog_1);
                chatWSAPI.clientUnban(getIsUnbannedClient_1);
                chatWSAPI.onConnected(authConnection_1);
                chatWSAPI.respTyping(getAgentTyping_1);
                chatWSAPI.respAgentFile(getFileAgent_1);
                chatWSAPI.onDisconnected(function () { return console.log('onDisconnected'); });
                chatWSAPI.start({
                    url: env('BACKEND_WEB_SOCKET'),
                    pingMsg: apiConfig.wsPingMsg,
                    pingWait: apiConfig.wsPingWait,
                    pingInterval: apiConfig.wsPingInterval,
                    reconnectDelay: apiConfig.wsReconnectDelay,
                });
            };
            setIsConnectedWS(true);
            setup();
        }
    }, [channelId, clientInfo, dispatch, gotAgentIceCandidate, gotAgentOffer, isConnectedWebSocket, setAgentStopCall, setIsConnectedWS]);
    return React.createElement(Window, __assign({}, props, { sendMessage: sendMessage }));
};
export default ConnectionWS;

import { setScrollStatus } from '../logic';
export var toggleEnabledHandler = function (setEnabled, isEnabled) { return function () {
    if ((window.innerWidth <= 500 && window.orientation === 0) ||
        (window.innerHeight <= 500 && window.orientation === 90))
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isEnabled ? setScrollStatus('auto') : setScrollStatus('hidden');
    sessionStorage.setItem('AskMeWebLibrary-isEnabled', JSON.stringify({ isEnabled: !isEnabled }));
    setEnabled(!isEnabled);
}; };
export var checkLocalLang = function (setLocalLang, lang) { return function () {
    // @ts-ignore
    var lan = JSON.parse(sessionStorage.getItem('AskMeWebLibrary-setLocalLang'));
    if (lan) {
        // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
        setLocalLang(checkLang(lan));
    }
    else {
        // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
        setLocalLang(checkLang(lang));
    }
}; };
export function throttle(function_, wait) {
    if (typeof function_ !== 'function') {
        throw new TypeError("Expected the first argument to be a `function`, got `".concat(typeof function_, "`."));
    }
    var timeoutId;
    var lastCallTime = 0;
    return function throttled() {
        var _this = this;
        var arguments_ = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            arguments_[_i] = arguments[_i];
        }
        clearTimeout(timeoutId);
        var now = Date.now();
        var timeSinceLastCall = now - lastCallTime;
        var delayForNextCall = wait - timeSinceLastCall;
        if (delayForNextCall <= 0) {
            lastCallTime = now;
            // @ts-expect-error: noop
            function_.apply(this, arguments_);
        }
        else {
            // @ts-ignore
            timeoutId = setTimeout(function () {
                lastCallTime = Date.now();
                // @ts-expect-error: noop
                function_.apply(_this, arguments_);
            }, delayForNextCall);
        }
    };
}
export var checkLang = function (lang) {
    switch (lang) {
        case 'en': {
            return lang;
        }
        case 'pl': {
            return lang;
        }
        case 'ru': {
            return lang;
        }
        case 'ua': {
            return lang;
        }
        case 'de': {
            return lang;
        }
        case 'es': {
            return lang;
        }
        case 'vi': {
            return lang;
        }
        case 'pt': {
            return lang;
        }
        case 'ko': {
            return lang;
        }
        case 'it': {
            return lang;
        }
        case 'ar': {
            return lang;
        }
        case 'fr': {
            return lang;
        }
        case 'hu': {
            return lang;
        }
        default: {
            return 'en';
        }
    }
};

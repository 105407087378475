export var initializationActionTypes;
(function (initializationActionTypes) {
    initializationActionTypes["SET_AGENT_INFO"] = "SET_AGENT_INFO";
    initializationActionTypes["ASSIGNING_AGENT"] = "ASSIGNING_AGENT";
    initializationActionTypes["SAVE_CLIENT_INFO"] = "SAVE_CLIENT_INFO";
    initializationActionTypes["SET_CUSTOM_STYLES"] = "SET_CUSTOM_STYLES";
    initializationActionTypes["SET_SETTINGS_BRAND"] = "SET_SETTINGS_BRAND";
    initializationActionTypes["SET_PRIVACY_POLICY"] = "SET_PRIVACY_POLICY";
    initializationActionTypes["SET_IS_CLIENT_BANNED"] = "SET_IS_CLIENT_BANNED";
    initializationActionTypes["SET_SETTINGS_CHANNEL"] = "SET_SETTINGS_CHANNEL";
    initializationActionTypes["SET_RESPONSE_USER_ID"] = "SET_RESPONSE_USER_ID";
    initializationActionTypes["SET_IS_AUTH_CONNECTION"] = "SET_IS_AUTH_CONNECTION";
    initializationActionTypes["SET_CLIENT_EXTERNAL_ID"] = "SET_CLIENT_EXTERNAL_ID";
    initializationActionTypes["GET_CLIENT_EXTERNAL_ID"] = "GET_CLIENT_EXTERNAL_ID";
    initializationActionTypes["SET_IS_LOADING_MESSAGES"] = "SET_IS_LOADING_MESSAGES";
    initializationActionTypes["SET_STORAGE_CLIENT_INFO"] = "SET_STORAGE_CLIENT_INFO";
    initializationActionTypes["GET_STORAGE_CLIENT_INFO"] = "GET_STORAGE_CLIENT_INFO";
    initializationActionTypes["GET_SETTINGS_CHANNEL_SAGA"] = "GET_SETTINGS_CHANNEL_SAGA";
    initializationActionTypes["SET_GETTING_CLIENT_HISTORY"] = "SET_GETTING_CLIENT_HISTORY";
    initializationActionTypes["PARS_REQUIRED_FIELDS_BRAND"] = "PARS_REQUIRED_FIELDS_BRAND";
    initializationActionTypes["PARS_GREETING_AND_QUICK_PHRASES"] = "PARS_GREETING_AND_QUICK_PHRASES";
})(initializationActionTypes || (initializationActionTypes = {}));
export var workStatesActionType;
(function (workStatesActionType) {
    workStatesActionType["IS_QUOTING"] = "IS_QUOTING";
    workStatesActionType["SET_IS_MUTED"] = "SET_IS_MUTED";
    workStatesActionType["SET_FORM_DATA"] = "SET_FORM_DATA";
    workStatesActionType["SET_CAPTCHA_REF"] = "SET_CAPTCHA_REF";
    workStatesActionType["SET_CAPTCHA_TOKEN"] = "SET_CAPTCHA_TOKEN";
    workStatesActionType["SET_SAVE_CLIENT_INFO"] = "SET_SAVE_CLIENT_INFO";
    workStatesActionType["SET_IS_FOCUS_ON_CHAT"] = "SET_IS_FOCUS_ON_CHAT";
    workStatesActionType["SET_CONTEXT_MENU_TYPE"] = "SET_CONTEXT_MENU_TYPE";
    workStatesActionType["SET_CURRENT_AUTH_STEP"] = "SET_CURRENT_AUTH_STEP";
    workStatesActionType["SET_URL_AND_TITLE_PAGE"] = "SET_URL_AND_TITLE_PAGE";
    workStatesActionType["SET_SAVE_CURRENT_MESSAGE"] = "SET_SAVE_CURRENT_MESSAGE";
    workStatesActionType["SET_VISIBILITY_MODAL_END_DIALOG"] = "SET_VISIBILITY_MODAL_END_DIALOG";
    workStatesActionType["SET_VISIBILITY_MODAL_IS_ERROR_NOTIFY"] = "SET_VISIBILITY_MODAL_IS_ERROR_NOTIFY";
})(workStatesActionType || (workStatesActionType = {}));
export var webSocketActionType;
(function (webSocketActionType) {
    webSocketActionType["SEND_FILE"] = "SEND_FILE";
    webSocketActionType["END_DIALOG"] = "END_DIALOG";
    webSocketActionType["SET_MESSAGE"] = "SET_MESSAGE";
    webSocketActionType["GET_MESSAGE"] = "GET_MESSAGE";
    webSocketActionType["GET_AGENT_FILE"] = "GET_AGENT_FILE";
    webSocketActionType["SET_EDITED_MESSAGE"] = "SET_EDITED_MESSAGE";
    webSocketActionType["SEND_CLIENT_TYPING"] = "SEND_CLIENT_TYPING";
    webSocketActionType["SET_DELETED_MESSAGE"] = "SET_DELETED_MESSAGE";
    webSocketActionType["SET_IS_TYPING_AGENT"] = "SET_IS_TYPING_AGENT";
    webSocketActionType["SEND_CHOICE_MESSAGE"] = "SEND_CHOICE_MESSAGE";
    webSocketActionType["AUTH_CONNECTION_SAGA"] = "AUTH_CONNECTION_SAGA";
    webSocketActionType["GET_MESSAGES_HISTORY"] = "GET_MESSAGES_HISTORY";
    webSocketActionType["SET_MESSAGES_HISTORY"] = "SET_MESSAGES_HISTORY";
    webSocketActionType["SET_LOADER_SEND_FILE"] = "SET_LOADER_SEND_FILE";
    webSocketActionType["ERROR_CONNECTION_SAGA"] = "ERROR_CONNECTION_SAGA";
    webSocketActionType["SEND_NEW_MESSAGE_SAGA"] = "SEND_NEW_MESSAGE_SAGA";
    webSocketActionType["SEND_QUICK_REPLY_SAGA"] = "SEND_QUICK_REPLY_SAGA";
    webSocketActionType["SET_AGENT_ONLINE_STATUS"] = "SET_AGENT_ONLINE_STATUS";
    webSocketActionType["UPDATE_MESSAGE_STATUS_SAGA"] = "UPDATE_MESSAGE_STATUS_SAGA";
    webSocketActionType["SET_IS_CONNECTED_WEB_SOCKET"] = "SET_IS_CONNECTED_WEB_SOCKET";
    webSocketActionType["UPDATE_CLIENT_ONLINE_STATUS_SAGA"] = "UPDATE_CLIENT_ONLINE_STATUS_SAGA";
})(webSocketActionType || (webSocketActionType = {}));
export var callActionType;
(function (callActionType) {
    callActionType["SET_IS_ON_CALL"] = "SET_IS_ON_CALL";
    callActionType["SET_AGENT_OFFER"] = "SET_AGENT_OFFER";
    callActionType["GOT_AGENT_OFFER"] = "GOT_AGENT_OFFER";
    callActionType["CLIENT_ANSWERED"] = "CLIENT_ANSWERED";
    callActionType["SEND_CLIENT_ANSWER"] = "SEND_CLIENT_ANSWER";
    callActionType["SET_AGENT_STOP_CALL"] = "SET_AGENT_STOP_CALL";
    callActionType["GOT_AGENT_ICE_CANDIDATE"] = "GET_AGENT_ICE_CANDIDATE";
    callActionType["SEND_CLIENT_ICE_CANDIDATE"] = "SEND_CLIENT_ICE_CANDIDATE";
})(callActionType || (callActionType = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import accepts from 'attr-accept';
import isEmpty from 'lodash/isEmpty';
import { FileReaderMethods } from '../file-reader-method.enum';
var fileAccepted = function (file, accept) {
    return file.type === 'application/x-moz-file' || accepts(file, accept);
};
var matchMaxSizeSize = function (file, maxSize) { return file.size <= maxSize; };
var matchMinSizeSize = function (file, minSize) { return file.size >= minSize; };
function readFileAsync(file, method) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.addEventListener('load', function () {
            resolve(reader.result);
        });
        reader.onerror = reject;
        reader[method](file);
    });
}
var defaultState = {
    loading: false,
    files: null,
    errors: null,
    arrayBuffers: null,
};
export var useFileReader = function (options) {
    var method = options.method, accept = options.accept, minSize = options.minSize, maxSize = options.maxSize, errorsTKeys = options.errorsTKeys, onloadHook = options.onload;
    var _a = React.useState(function () { return defaultState; }), state = _a[0], setState = _a[1];
    React.useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, result, arrayBuffers_1, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!state.files) return [3 /*break*/, 7];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, , 7]);
                        _a = method;
                        switch (_a) {
                            case FileReaderMethods.readAsText: return [3 /*break*/, 2];
                            case FileReaderMethods.readAsBinaryString: return [3 /*break*/, 2];
                            case FileReaderMethods.readAsDataURL: return [3 /*break*/, 2];
                            case FileReaderMethods.readAsArrayBuffer: return [3 /*break*/, 2];
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, Promise.allSettled(state.files.map(function (file) { return __awaiter(void 0, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _b.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, readFileAsync(file, method)];
                                    case 1: return [2 /*return*/, _b.sent()];
                                    case 2:
                                        _a = _b.sent();
                                        setState(function (prevState) { return (__assign(__assign({}, prevState), { errors: __spreadArray(__spreadArray([], (prevState.errors || []), true), [
                                                { name: 'defaultError', message: errorsTKeys.defaultError, file: null },
                                            ], false) })); });
                                        return [2 /*return*/, null];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }))];
                    case 3:
                        result = _b.sent();
                        arrayBuffers_1 = result.map(function (_a) {
                            var value = _a.value;
                            return value;
                        });
                        if (typeof onloadHook === 'function') {
                            onloadHook(arrayBuffers_1);
                        }
                        setState(function (prevState) { return (__assign(__assign({}, prevState), { arrayBuffers: arrayBuffers_1, loading: false })); });
                        return [3 /*break*/, 5];
                    case 4:
                        {
                            throw new Error('Unknown method');
                        }
                        _b.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _b.sent();
                        setState(function (prevState) { return (__assign(__assign({}, prevState), { errors: __spreadArray(__spreadArray([], (prevState.errors || []), true), [{ name: 'Unknown Error', message: error_1.message, file: null }], false) })); });
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); })();
    }, [errorsTKeys.defaultError, method, onloadHook, state.files]);
    var fileSetter = React.useCallback(function (acceptedFiles) {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { loading: true })); });
        if (isEmpty(acceptedFiles)) {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { loading: false, errors: __spreadArray(__spreadArray([], (prevState.errors || []), true), [
                    { name: 'defaultError', message: errorsTKeys.defaultError, file: null },
                ], false) })); });
            return;
        }
        var validationErrorsAcc = [];
        var filesAcc = [];
        // eslint-disable-next-line no-restricted-syntax
        for (var _i = 0, acceptedFiles_1 = acceptedFiles; _i < acceptedFiles_1.length; _i++) {
            var file = acceptedFiles_1[_i];
            var validationErrors = [];
            if (!matchMaxSizeSize(file, maxSize)) {
                validationErrors.push({ name: 'maxSize', message: errorsTKeys.maxSize, file: file });
            }
            if (!matchMinSizeSize(file, minSize)) {
                validationErrors.push({ name: 'minSize', message: errorsTKeys.minSize, file: file });
            }
            if (accept && !fileAccepted(file, Object.keys(accept || {}))) {
                validationErrors.push({ name: 'fileFormat', message: errorsTKeys.fileFormat, file: file });
            }
            if (isEmpty(validationErrors)) {
                filesAcc.push(file);
            }
            validationErrorsAcc.push.apply(validationErrorsAcc, validationErrors);
        }
        setState(function (prevState) { return (__assign(__assign({}, prevState), { files: filesAcc, errors: __spreadArray(__spreadArray([], (prevState.errors || []), true), validationErrorsAcc, true) })); });
    }, [
        accept,
        errorsTKeys.defaultError,
        errorsTKeys.fileFormat,
        errorsTKeys.maxSize,
        errorsTKeys.minSize,
        maxSize,
        minSize,
    ]);
    var fileReject = React.useCallback(function (rejectedFiles) {
        if (!isEmpty(rejectedFiles)) {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { loading: false, errors: __spreadArray(__spreadArray([], (prevState.errors || []), true), rejectedFiles.map(function (rejectedFile) { return ({
                    name: 'defaultError',
                    message: errorsTKeys.defaultError,
                    file: rejectedFile.file,
                    errors: rejectedFile.errors,
                }); }), true) })); });
        }
    }, [errorsTKeys.defaultError]);
    var reset = function () { return setState(defaultState); };
    return [__assign({}, state), fileSetter, fileReject, reset];
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/static-property-placement */
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { ErrorView } from './ErrorView';
// "Portal" component for actual ErrorView component to
// render to (directly under body). Prevents bugs as in #27.
var ErrorOverlay = /** @class */ (function (_super) {
    __extends(ErrorOverlay, _super);
    function ErrorOverlay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ErrorOverlay.prototype.componentDidMount = function () {
        this.el = document.createElement('div');
        document.body.append(this.el);
        this.renderErrorView();
    };
    ErrorOverlay.prototype.componentDidUpdate = function () {
        this.renderErrorView();
    };
    ErrorOverlay.prototype.componentWillUnmount = function () {
        if (this.el) {
            unmountComponentAtNode(this.el);
            this.el.remove();
            this.el = null;
        }
    };
    ErrorOverlay.prototype.renderErrorView = function () {
        // @ts-ignore
        render(React.createElement(ErrorView, __assign({}, this.props)), this.el);
    };
    // eslint-disable-next-line class-methods-use-this
    ErrorOverlay.prototype.render = function () {
        return null;
    };
    return ErrorOverlay;
}(React.Component));
export { ErrorOverlay };
ErrorOverlay.displayName = 'ErrorOverlay';

import React from 'react';
import { useSelector } from 'react-redux';
import { getStylesQuickPhrases } from '@reducers/stylesReducer';
import ButtonQuickWrapper from './StyledButtonQuick';
import './ButtonQuick.less';
var ButtonQuick = function (_a) {
    var text = _a.text, onClick = _a.onClick;
    var _b = useSelector(getStylesQuickPhrases), quickPhrasesColor = _b.quickPhrasesColor, quickPhrasesColorHover = _b.quickPhrasesColorHover, quickPhrasesBackground = _b.quickPhrasesBackground, quickPhrasesBorderColor = _b.quickPhrasesBorderColor, quickPhrasesBackgroundHover = _b.quickPhrasesBackgroundHover, quickPhrasesBorderColorHover = _b.quickPhrasesBorderColorHover;
    return (React.createElement("div", { className: "AskMeWebLibrary-reply-buttons", onClick: function () { return onClick(text); } },
        React.createElement(ButtonQuickWrapper, { quickPhrasesColor: quickPhrasesColor, quickPhrasesBackground: quickPhrasesBackground, quickPhrasesBorderColor: quickPhrasesBorderColor, quickPhrasesColorHover: quickPhrasesColorHover, quickPhrasesBackgroundHover: quickPhrasesBackgroundHover, quickPhrasesBorderColorHover: quickPhrasesBorderColorHover }, text)));
};
export default ButtonQuick;

import React from 'react';
import './styles.less';
var QuotedMessageDate = function (_a) {
    var date = _a.date, quoteAuthor = _a.quoteAuthor;
    return (React.createElement("div", { className: "AskMeWebLibrary-quoted-message-date" },
        quoteAuthor,
        ", ",
        date));
};
export default QuotedMessageDate;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var QuoteWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &::-webkit-scrollbar {\n    height: 8px;\n    width: 8px;\n  }\n    &::-webkit-scrollbar-track {\n        margin: 10px 0;\n    }\n  &::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    background: ", " !important;\n    );\n  }\n"], ["\n  &::-webkit-scrollbar {\n    height: 8px;\n    width: 8px;\n  }\n    &::-webkit-scrollbar-track {\n        margin: 10px 0;\n    }\n  &::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    background: ", " !important;\n    );\n  }\n"])), function (_a) {
    var scrollBackground = _a.scrollBackground;
    return scrollBackground || '#12171e';
});
var templateObject_1;

import React from 'react';
import { printMessage } from '../PrintMessage/PrintMessage';
import QuotedMessageDate from './QuotedMessageDate';
import './styles.less';
var QuotedMessage = function (_a) {
    var type = _a.type, quotedMessage = _a.quotedMessage;
    return (React.createElement(React.Fragment, null, type === 7 && quotedMessage && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "AskMeWebLibrary-quoted-message" },
            React.createElement("div", { className: "AskMeWebLibrary-quoted-message-symbol" }, "\u201F"),
            printMessage(quotedMessage)),
        React.createElement(QuotedMessageDate, { date: quotedMessage.time, quoteAuthor: quotedMessage.sender }),
        React.createElement("hr", null)))));
};
export default QuotedMessage;

export var getStorageClientInfo = function (isLocalStorage) { return ({
    userId: JSON.parse((isLocalStorage ? localStorage : sessionStorage).getItem('AskMeWebLibrary-user-id')),
    isMuted: JSON.parse(localStorage.getItem('AskMeWebLibrary-isMuted')),
    userName: JSON.parse((isLocalStorage ? localStorage : sessionStorage).getItem('AskMeWebLibrary-user-name')),
    phone: JSON.parse((isLocalStorage ? localStorage : sessionStorage).getItem('AskMeWebLibrary-user-phone')),
    email: JSON.parse((isLocalStorage ? localStorage : sessionStorage).getItem('AskMeWebLibrary-user-email')),
    lastName: JSON.parse((isLocalStorage ? localStorage : sessionStorage).getItem('AskMeWebLibrary-user-lastName')),
    firstName: JSON.parse((isLocalStorage ? localStorage : sessionStorage).getItem('AskMeWebLibrary-user-firstName')),
    invoiceNumber: JSON.parse((isLocalStorage ? localStorage : sessionStorage).getItem('AskMeWebLibrary-user-invoiceNumber')),
    accountNumber: JSON.parse((isLocalStorage ? localStorage : sessionStorage).getItem('AskMeWebLibrary-user-accountNumber')),
}); };

import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { fileDownload } from '@helpers/fileDownload';
import { ContextMenuItemTypes } from '@models/components/contextMenu';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Stack } from '@mui/material';
import { getStylesAgentMsg, getStylesClientMsg } from '@reducers/stylesReducer';
import { useContextMenuActions } from '../ContextMenu/useContextMenuActions';
export function MessageBubble(_a) {
    var children = _a.children, data = _a.data, isUserMessage = _a.isUserMessage;
    var containerRef = useRef(null);
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var id = open ? 'simple-popover' : undefined;
    var handleQuote = useContextMenuActions().handleClick;
    var _c = useSelector(getStylesAgentMsg), messageAgentColor = _c.messageAgentColor, messageAgentBorderColor = _c.messageAgentBorderColor, messageAgentBackgroundColor = _c.messageAgentBackgroundColor;
    var _d = useSelector(getStylesClientMsg), clientMessageColor = _d.clientMessageColor, clientMessageBackground = _d.clientMessageBackground, clientMessageBorderColor = _d.clientMessageBorderColor;
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = React.useCallback(function () {
        setAnchorEl(null);
    }, []);
    var handleClickQuote = React.useCallback(function (e, messageData) {
        handleQuote(e, messageData);
        handleClose();
    }, [handleClose, handleQuote]);
    var handleContextMenu = React.useCallback(function (event) {
        event.preventDefault();
    }, []);
    var handleDownload = React.useCallback(function () {
        var fileUrl = data.responseText;
        var filename = fileUrl.split('/').pop();
        if (!filename) {
            return;
        }
        fileDownload(fileUrl, filename).catch(function (error) { return console.error(error); });
        handleClose();
    }, [handleClose, data.responseText]);
    return (React.createElement(Stack, { direction: "row", justifyContent: isUserMessage ? 'start' : 'end', alignItems: "start", onContextMenu: handleContextMenu, sx: {
            border: isUserMessage
                ? "1px solid ".concat(messageAgentBorderColor || '#E5E5E5')
                : "1px solid ".concat(clientMessageBorderColor || '#eceefa'),
            background: isUserMessage ? messageAgentBackgroundColor : clientMessageBackground,
            color: isUserMessage ? messageAgentColor || '#434343' : clientMessageColor || '#fff7f8',
            borderTopRightRadius: isUserMessage ? '18px' : '0px',
            borderTopLeftRadius: isUserMessage ? '0px' : '18px',
            borderBottomRightRadius: '18px',
            borderBottomLeftRadius: '18px',
        }, ref: containerRef },
        isUserMessage && (React.createElement(IconButton, { onClick: handleClick, sx: { padding: '8px 0px' } },
            React.createElement(MoreVertRoundedIcon, { color: "action" }))),
        children,
        !isUserMessage && (React.createElement(IconButton, { onClick: handleClick, sx: { padding: '8px 0px' } },
            React.createElement(MoreVertRoundedIcon, null))),
        React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            }, container: containerRef.current },
            React.createElement(MenuList, null,
                React.createElement(MenuItem, { onClick: function (event) {
                        return handleClickQuote(event, {
                            type: ContextMenuItemTypes.quote,
                            data: data,
                        });
                    } },
                    React.createElement(ListItemIcon, null,
                        React.createElement(FormatQuoteRoundedIcon, { fontSize: "small" })),
                    React.createElement(ListItemText, null, "Quote")),
                (data.type === 3 || data.type === 6) && (React.createElement(MenuItem, { onClick: handleDownload },
                    React.createElement(ListItemIcon, null,
                        React.createElement(FileDownloadRoundedIcon, { fontSize: "small" })),
                    React.createElement(ListItemText, null, "Download")))))));
}

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsClientBanned } from '@reducers/initializationReducer';
import { getTypeError } from '@reducers/workAppReducer';
import { workStatesActionType } from '@type';
import { env } from '@mdca/wde';
import { useTimer } from '../../../hooks/useTimer';
import ErrorNotifyWrap from './StyledErrorNotify';
var ErrorNotify = function (_a) {
    var lang = _a.lang;
    var dispatch = useDispatch();
    var typeError = useSelector(getTypeError);
    var isBanned = useSelector(getIsClientBanned);
    useEffect(function () {
        dispatch({
            type: workStatesActionType.SET_VISIBILITY_MODAL_END_DIALOG,
            payload: false,
        });
    }, []);
    var showErrorNotify = useCallback(function (boll) {
        dispatch({
            type: workStatesActionType.SET_VISIBILITY_MODAL_IS_ERROR_NOTIFY,
            payload: {
                isErrorNotify: boll,
                typeError: 0,
            },
        });
    }, []);
    var isItSpecialError = !isBanned && typeError !== 3;
    if (isItSpecialError)
        useTimer(15000, function () { return showErrorNotify(false); });
    var message = isBanned
        ? 'Your message has not been delivered.'
        : typeError === 1
            ? 'Please send messages to start a dialogue'
            : typeError === 3
                ? 'We were unable to verify that you are not a robot! Please try it later.'
                : 'Max size is 20MB!';
    return (React.createElement(ErrorNotifyWrap, { isItSpecialError: isItSpecialError, lang: lang },
        isItSpecialError && (React.createElement("img", { alt: "close modal", src: "".concat(env('ICON_WEB_UI'), "/close_modal.png"), onClick: function () { return showErrorNotify(false); } })),
        React.createElement("div", { className: "modal-text" }, message)));
};
export default ErrorNotify;

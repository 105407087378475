import { useEffect, useState } from 'react';
export function useTimer(delay, cb) {
    var _a = useState('inProgress'), status = _a[0], setStatus = _a[1];
    var timerId;
    useEffect(function () {
        timerId = setTimeout(function () {
            cb();
            setStatus('done');
        }, delay);
        return function () { return clearTimeout(timerId); };
    });
    return status;
}

import React, { useEffect } from 'react';
import localization from '@localization';
import { scrollDown } from '../BodyHelper';
import EndDialogMsgWrap from './StyledSystemMessages';
var SystemMessages = function (_a) {
    var lang = _a.lang, type = _a.type, text = _a.text, time = _a.time, scrollElement = _a.scrollElement;
    useEffect(function () {
        setTimeout(scrollDown(scrollElement), 1);
    }, []);
    switch (type) {
        case 9: {
            return (React.createElement(EndDialogMsgWrap, null,
                localization[lang]["system.call.msg.".concat(text)],
                " ",
                React.createElement("span", { style: { color: '#C4C4C4' } }, time)));
        }
        case 8: {
            return React.createElement(EndDialogMsgWrap, null, localization[lang]['end.dialog.msg']);
        }
        default: {
            return React.createElement(EndDialogMsgWrap, null, "Unexpected system message!");
        }
    }
};
export default SystemMessages;

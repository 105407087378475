var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import isEqual from 'lodash/isEqual';
import { MessageBubble } from '../MessageBubble';
import { Choice } from './Choice/Choice';
import BotMessageIcon from './Icon/BotMessageIcon';
import Message from './Message/Message';
import Time from './Message/Time/Time';
import StyledBotMessage from './StyledBotMessage';
import { useBotMessage } from './useBotMessage';
import './BotMessage.less';
// eslint-disable-next-line react/function-component-definition
export var BotMessage = function (props) {
    var lang = props.lang, value = props.value, avatar = props.avatar, hasIcon = props.hasIcon, agentName = props.agentName, isFullScreen = props.isFullScreen, scrollElement = props.scrollElement;
    var data = useBotMessage({ value: value, scrollElement: scrollElement });
    return (React.createElement(StyledBotMessage, { hasIcon: hasIcon, className: "AskMeWebLibrary-body__bot-message AskMeWebLibrary-bot-message" },
        React.createElement(BotMessageIcon, { id: "bot-icon", className: "AskMeWebLibrary-bot-message__icon", avatar: avatar, sender: value.sender }),
        React.createElement("div", { id: "bot-answer", className: "AskMeWebLibrary-bot-message__answer AskMeWebLibrary-answer" },
            React.createElement(Time, { lang: lang, time: value.data.time, agentName: agentName }),
            React.createElement(MessageBubble, { data: value.data, isUserMessage: true },
                React.createElement(Message, { value: value.data, id: "message-answer", className: "AskMeWebLibrary-answer__message", isFullScreen: isFullScreen, hasIcon: hasIcon, scrollElement: scrollElement })),
            React.createElement(Choice, __assign({}, { data: data })))));
};
var areEqual = function (prevProps, nextProps) {
    return isEqual({ value: prevProps.value, lang: prevProps.lang }, { value: nextProps.value, lang: nextProps.lang });
};
export default React.memo(BotMessage, areEqual);

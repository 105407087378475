import React from 'react';
import { useSelector } from 'react-redux';
import { getStylesScroll } from '@reducers/stylesReducer';
import isEqual from 'lodash/isEqual';
import { env } from '@mdca/wde';
import { QuoteWrapper } from './styled';
import './Styles.less';
var Quote = function (_a) {
    var quotedMessageData = _a.quotedMessageData, onCloseHandler = _a.onCloseHandler, lang = _a.lang;
    var scrollBackground = useSelector(getStylesScroll).scrollBackground;
    return (React.createElement("div", { className: "AskMeWebLibrary-quote-wrapper" },
        React.createElement("div", { style: lang === 'ar'
                ? {
                    left: 'auto',
                    marginRight: '5px',
                    transform: 'scale(-1, 1)',
                }
                : {}, className: "AskMeWebLibrary-quote-symbol" }, "\u201F"),
        React.createElement(QuoteWrapper, { scrollBackground: scrollBackground, className: "AskMeWebLibrary-quote" }, quotedMessageData.type === 3 ? (React.createElement("img", { src: quotedMessageData.responseText, alt: "img" })) : (quotedMessageData.responseText)),
        React.createElement("div", { className: "AskMeWebLibrary-quote-close" },
            React.createElement("img", { onClick: onCloseHandler, src: "".concat(env('ICON_WEB_UI'), "/close_modal.png"), alt: "close" }))));
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(Quote, areEqual);

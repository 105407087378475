import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { normalizeWidgetPositionTimeOutCall } from '@helpers/normalizeWidgetPosition';
import { env } from '@mdca/wde';
import { useActions } from '../hooks/useActions';
export var ReCaptcha = function () {
    var _reCaptchaRef = useRef(null);
    var _a = useActions(), setCaptchaRef = _a.setCaptchaRef, setCaptchaToken = _a.setCaptchaToken;
    var handleChange = function (token) {
        if (token)
            setCaptchaToken(token);
    };
    var asyncScriptOnLoad = function () {
        setCaptchaRef(_reCaptchaRef);
    };
    useEffect(function () {
        var destroyListener = normalizeWidgetPositionTimeOutCall();
        return function () { return destroyListener(); };
    }, []);
    return (React.createElement("div", { style: { visibility: 'hidden' } },
        React.createElement(ReCAPTCHA, { size: "invisible", ref: _reCaptchaRef, sitekey: env('CAPTCHA_PUBLIC_KEY'), badge: "bottomleft", 
            // @ts-ignore
            // isolated={true}
            onChange: handleChange, asyncScriptOnLoad: asyncScriptOnLoad })));
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ErrorOverlay } from './ErrorOverlay';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { error: null, errorInfo: null };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({ error: error, errorInfo: errorInfo });
        // You can also log error messages to an error reporting service here
    };
    ErrorBoundary.prototype.render = function () {
        var _a = this.state, error = _a.error, errorInfo = _a.errorInfo;
        console.log('errorInfo', errorInfo);
        // if (errorInfo) {
        //   return (
        //     <div>
        //       <h2>Something went wrong.</h2>
        //       <details style={{ whiteSpace: 'pre-wrap' }}>
        //         {error?.toString()}
        //         <br />
        //         {errorInfo.componentStack}
        //       </details>
        //     </div>
        //   );
        // }
        if (error) {
            return React.createElement(ErrorOverlay, { editorScheme: "webstorm", error: error });
        }
        // Normally, just render children
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export { ErrorBoundary };

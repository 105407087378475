var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export default styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background: ", ";\n  border: 1px solid #fff;\n  position: absolute;\n  right: 0;\n  bottom: 0;\n"], ["\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background: ", ";\n  border: 1px solid #fff;\n  position: absolute;\n  right: 0;\n  bottom: 0;\n"])), function (_a) {
    var isOnline = _a.isOnline;
    return (isOnline ? 'green' : 'red');
});
var templateObject_1;

import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
export function LoadingBackdrop(_a) {
    var loading = _a.loading, progressSize = _a.progressSize, _b = _a.transitionDuration, transitionDuration = _b === void 0 ? 800 : _b, children = _a.children;
    return (React.createElement(Backdrop, { unmountOnExit: true, in: loading, open: loading, transitionDuration: transitionDuration, TransitionComponent: Fade, style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' }, sx: function (_a) {
            var palette = _a.palette, zIndex = _a.zIndex;
            return ({
                zIndex: zIndex.drawer + 1,
                position: 'absolute',
                color: palette.primary.main,
            });
        } }, children || React.createElement(CircularProgress, { size: progressSize, sx: { color: 'inherit' } })));
}

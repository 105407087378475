var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var createScrollStopListener = function (element, callback, timeout) {
    var removed = false;
    var handle;
    var onScroll = function () {
        if (handle)
            clearTimeout(handle);
        handle = setTimeout(callback, timeout || 500);
    };
    element.addEventListener('scroll', onScroll);
    return function () {
        if (removed)
            return;
        removed = true;
        if (handle)
            clearTimeout(handle);
        element.removeEventListener('scroll', onScroll);
    };
};
export var normalizeWidgetPositionTimeOutCall = function () {
    var id = setInterval(function () {
        normalizeWidgetPosition();
    }, 100);
    return function destroy() {
        clearInterval(id);
    };
};
export var normalizeWidgetPosition = function () {
    var _a, _b, _c;
    // @ts-ignore
    var recaptchaWindow = (_c = (_b = (_a = __spreadArray([], document.querySelectorAll('iframe'), true)) === null || _a === void 0 ? void 0 : _a.find(function (x) {
        return x.src.includes('google.com/recaptcha/api2/bframe');
    })) === null || _b === void 0 ? void 0 : _b.parentNode) === null || _c === void 0 ? void 0 : _c.parentNode;
    if (!recaptchaWindow)
        return;
    recaptchaWindow.style.top = "".concat(window.scrollY, "px");
};

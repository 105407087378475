var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSelector } from 'react-redux';
import { getStylesAuth } from '@reducers/stylesReducer';
import { Header } from '../../Header/Header';
import Steps from './components/Steps';
import './Auth.less';
var Auth = function (props) {
    var lang = props.lang, isFullScreen = props.isFullScreen, toggleEnabled = props.toggleEnabled, toggleFullScreen = props.toggleFullScreen, setIsRegistrationClient = props.setIsRegistrationClient;
    var registrationBackgroundColor = useSelector(getStylesAuth).registrationBackgroundColor;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { toggleEnabled: toggleEnabled, lang: lang, isDisableMoreMenu: true, isAuthHeader: true }),
        React.createElement("div", { className: "AskMeWebLibrary-auth-window", ref: function (el) {
                if (el)
                    el.style.setProperty('background-color', registrationBackgroundColor || '#F5F5F5', 'important');
            } },
            React.createElement(Steps, __assign({}, { lang: lang, setIsRegistrationClient: setIsRegistrationClient })))));
};
export default Auth;

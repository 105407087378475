import { cloneDeep } from 'lodash';
export var findAndCompleteChoiceMessage = function (msgHistory, id) {
    return msgHistory.map(function (message) {
        if (message.data.id === id && message.type === 'botMessage') {
            var newMessage = cloneDeep(message);
            newMessage.data.isCompletedChoice = true;
            return newMessage;
        }
        return message;
    });
};

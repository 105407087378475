var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var ChoiceWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  padding-top: 5px;\n  max-width: 100%;\n  & > div {\n    max-width: 100%;\n    margin: 5px 10px 5px 0;\n    border: 1px solid ", ";\n    border-radius: 8px;\n    padding: 8px 11px;\n    color: blue;\n    font-size: 13px;\n    line-height: 14px;\n    cursor: pointer;\n    transition: 0.1s ease;\n    &:last-child {\n      margin-right: 0;\n    }\n    &:hover {\n      background-color: ", ";\n      color: white;\n    }\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  padding-top: 5px;\n  max-width: 100%;\n  & > div {\n    max-width: 100%;\n    margin: 5px 10px 5px 0;\n    border: 1px solid ", ";\n    border-radius: 8px;\n    padding: 8px 11px;\n    color: blue;\n    font-size: 13px;\n    line-height: 14px;\n    cursor: pointer;\n    transition: 0.1s ease;\n    &:last-child {\n      margin-right: 0;\n    }\n    &:hover {\n      background-color: ", ";\n      color: white;\n    }\n  }\n"])), function (_a) {
    var messageAgentBorderColor = _a.messageAgentBorderColor;
    return messageAgentBorderColor || '#E5E5E5';
}, function (_a) {
    var messageAgentBackgroundColor = _a.messageAgentBackgroundColor;
    return messageAgentBackgroundColor || '#1972f5';
});
var templateObject_1;

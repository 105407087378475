var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useSelector } from 'react-redux';
import { clientRejectCall, clientStopCall } from '@api/chatWS';
import { getAgentStopCall, getRingtoneRef } from '@reducers/callReducer';
import { notification } from 'antd';
import { isEmpty } from 'lodash';
import { useActions } from './useActions';
import { useRTCAudio } from './useRTCAudio';
export var useCall = function (offer) {
    var configuration = {
        iceServers: [offer.iceServer],
    };
    var openNotification = function (title, message) {
        notification.config({
            getContainer: function () { return document.querySelectorAll('.ScrollbarsCustom')[0]; },
            rtl: false,
        });
        notification.warning({
            message: title,
            description: message,
            placement: 'topLeft',
            duration: 15,
            style: {
                maxWidth: 250,
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: 10,
                borderTopRightRadius: 10,
            },
        });
    };
    var errorHandler = function (err) {
        if (err.message === 'Permission denied')
            openNotification('There is no access to a microphone', 'Please give access to the microphone for this site.');
        if (err.message === 'server')
            openNotification('Oops!', 'Something has happened to our server please try again later.');
    };
    var _a = useActions(), sendClientAnswer = _a.sendClientAnswer, sendClientIceCandidate = _a.sendClientIceCandidate, setIsOnCall = _a.setIsOnCall, setAgentOffer = _a.setAgentOffer, setRingtoneRef = _a.setRingtoneRef;
    var _b = useRTCAudio({
        configuration: configuration,
        errorHandler: errorHandler,
        roomId: offer.roomId,
        sendClientAnswer: sendClientAnswer,
        sendClientIceCandidate: sendClientIceCandidate,
    }), makeAnswer = _b.makeAnswer, stopCall = _b.stopCall;
    var ringRef = useSelector(getRingtoneRef);
    var agentStop = useSelector(getAgentStopCall);
    var answer = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('client answer');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, makeAnswer({ sdp: offer.sdp, type: 'offer' })];
                case 2:
                    _a.sent();
                    if (ringRef)
                        ringRef.pause();
                    setRingtoneRef(null);
                    setIsOnCall(true);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    errorHandler(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var rejectCall = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log('client rejected');
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    if (ringRef)
                        ringRef.pause();
                    setRingtoneRef(null);
                    setAgentOffer(null);
                    return [4 /*yield*/, clientRejectCall({ roomId: offer.roomId })];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    errorHandler('server');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var stop = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log('client stopped call');
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    setIsOnCall(false);
                    setAgentOffer(null);
                    return [4 /*yield*/, stopCall()];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, clientStopCall({ roomId: offer.roomId })];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    _a = _b.sent();
                    errorHandler('server');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var agentStopCall = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log('agent stopped call');
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    if (ringRef)
                        ringRef.pause();
                    setRingtoneRef(null);
                    setIsOnCall(false);
                    setAgentOffer(null);
                    return [4 /*yield*/, stopCall()];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    errorHandler('server');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (isEmpty(agentStop) && agentStop !== null)
        stop();
    else if (agentStop)
        agentStopCall();
    return { answer: answer, rejectCall: rejectCall, stop: stop };
};

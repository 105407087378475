var _a;
import { CALL_REDUCER_NAMESPACE, callReducer } from '@reducers/callReducer';
// eslint-disable-next-line import/no-cycle
import { INITIALIZATION_REDUCER_NAMESPACE, initializationReducer } from '@reducers/initializationReducer';
import { customStyleReducer, STYLES_REDUCER_NAMESPACE } from '@reducers/stylesReducer';
import { WORK_APP_REDUCER_NAMESPACE, workAppReducer } from '@reducers/workAppReducer';
import { WORK_WS_REDUCER_NAMESPACE, workWSReducer } from '@reducers/workWSReducer';
import { combineReducers } from 'redux';
import { resizableSlice } from './slices/resizable.slice';
var rootReducer = combineReducers((_a = {},
    _a[CALL_REDUCER_NAMESPACE] = callReducer,
    _a[WORK_WS_REDUCER_NAMESPACE] = workWSReducer,
    _a[WORK_APP_REDUCER_NAMESPACE] = workAppReducer,
    _a[STYLES_REDUCER_NAMESPACE] = customStyleReducer,
    _a[INITIALIZATION_REDUCER_NAMESPACE] = initializationReducer,
    _a[resizableSlice.name] = resizableSlice.reducer,
    _a));
export default rootReducer;

import WsConnector from './wsConnector';

const wsConnector = new WsConnector();

export const start = (params) => {
  wsConnector.start(params);
};

export const close = (reason) => {
  wsConnector.close(reason);
};

export const onConnected = (connectedCb) => {
  wsConnector.onConnected(connectedCb);
};

export const onDisconnected = (disconnectedCb) => {
  wsConnector.onDisconnected(disconnectedCb);
};

export const newMessage = (callback) => {
  wsConnector.register('new_message', callback);
};

export const newAgentQuote = (callback) => {
  wsConnector.register('agent_quote', callback);
};

export const onMessageEdited = (editedCb) => {
  wsConnector.register('agent_edit_message', editedCb);
};

export const onDeleteMessage = (callback) => {
  wsConnector.register('agent_delete_message', callback);
};

export const onAgentCall = (callback) => {
  wsConnector.register('agent_offer', callback);
};

export const onAgentIceCandidate = (callback) => {
  wsConnector.register('agent_ice', callback);
};

export const onAgentStopCall = (callback) => {
  wsConnector.register('agent_stop_call', callback);
};

export const clientAnswerCall = (args) =>
  wsConnector.requestProcessing({
    requestCmd: 'client_answer_call',
    requestArgs: args,
    responseCmd: 'client_answer_call_response',
  });

export const clientSendIceCandidate = (args) =>
  wsConnector.requestProcessing({
    requestCmd: 'client_ice',
    requestArgs: args,
    responseCmd: 'client_ice_response',
  });

export const clientRejectCall = (args) =>
  wsConnector.requestProcessing({
    requestCmd: 'client_offer_decline',
    requestArgs: args,
    responseCmd: 'client_offer_decline_response',
  });

export const clientStopCall = (args) =>
  wsConnector.requestProcessing({
    requestCmd: 'client_stop_call',
    requestArgs: args,
    responseCmd: 'client_stop_call_response',
  });

export const clientBan = (callback) => {
  wsConnector.register('ban', callback);
};

export const clientUnban = (callback) => {
  wsConnector.register('unban', callback);
};

export const onUpdateAgentStatus = (callback) => {
  wsConnector.register('changed_online_status', callback);
};

export const onAgentFinishDialog = (callback) => {
  wsConnector.register('agent_finish_dialog', callback);
};

export const newAgent = (callback) => {
  wsConnector.register('agent_info', callback);
};

export const respTyping = (callback) => {
  wsConnector.register('agent_typing', callback);
};

export const respAgentFile = (callback) => {
  wsConnector.register('agent_file', callback);
};

export const authConnection = (userName) =>
  wsConnector.requestProcessing({
    requestCmd: 'auth_connection',
    requestArgs: userName, // obj
    responseCmd: 'auth_connection_response',
  });

export const getMsgHistory = () =>
  wsConnector.requestProcessing({
    requestCmd: 'get_client_dialog_messages',
    requestArgs: { isEarlier: false, fromMsgID: 0, count: 0 },
    responseCmd: 'get_client_dialog_messages_response',
  });

export const clearDialogHistory = () =>
  wsConnector.requestProcessing({
    requestCmd: 'clear_dialog_history',
    requestArgs: { isEarlier: false, fromMsgID: 0, count: 0 },
    responseCmd: 'clear_dialog_history_response',
  });

export const sendNewMessage = (message) =>
  wsConnector.requestProcessing({
    requestCmd: 'new_message',
    requestArgs: message, // obj
    responseCmd: 'new_message_response',
  });

export const sendNewQuote = (message) =>
  wsConnector.requestProcessing({
    requestCmd: 'client_quote',
    requestArgs: message, // obj
    responseCmd: 'client_quote_response',
  });

export const updateMessageStatus = (status) =>
  wsConnector.requestProcessing({
    requestCmd: 'update_message_status',
    requestArgs: status, // obj
    responseCmd: 'update_message_status_response',
  });

export const updateClientOnlineStatus = (status) =>
  wsConnector.requestProcessing({
    requestCmd: 'update_online_status',
    requestArgs: status, // obj
    responseCmd: 'update_online_status_response',
  });

export const sendTyping = () =>
  wsConnector.requestProcessing({
    requestCmd: 'typing',
    responseCmd: 'typing_response',
  });

export const endDialog = () =>
  wsConnector.requestProcessing({
    requestCmd: 'end_dialog',
    responseCmd: 'end_dialog_response',
  });

export const sendFile = ({ type, fileName, content, locale }) =>
  wsConnector.requestProcessing({
    requestCmd: 'new_file',
    requestArgs: { type, fileName, content, locale },
    responseCmd: 'new_file_response',
  });

export const completeChoiceMessage = (id) =>
  wsConnector.requestProcessing({
    requestCmd: 'complete_choice_message',
    requestArgs: { id },
    responseCmd: 'complete_choice_message_response',
  });

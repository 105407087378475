var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { env } from '@mdca/wde';
export var requestOptions = function (type) { return ({
    method: type || 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
}); };
export var getChannelSettings = function (channel) {
    return fetch("".concat(channel), { method: 'GET' });
};
export var getAgentInfo = function (agent) { return fetch("".concat(agent), { method: 'GET' }); };
export var getAgents = function (agentId) {
    return fetch("".concat(env('GET_AGENTS_INFO_URl'), "/").concat(agentId), { method: 'GET' });
};
export var initializationUserFetchChatSystem = function (value) {
    return fetch("".concat(env('POST_URL_CLIENT_AUTH')), __assign(__assign({}, requestOptions()), { body: JSON.stringify(value) }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { initializationActions, webSocketActions } from '@actions';
import * as chatWSAPI from '@api/chatWS';
import { clientRejectCall } from '@api/chatWS';
import { getAgentInfo, getChannelSettings, initializationUserFetchChatSystem, } from '@api/fetchResponse';
import checkIsClientBanned from '@helpers/checkIsClientBanned';
import { changeChannelSettingsGreeting } from '@helpers/checkObj';
import { ring } from '@helpers/ringCallSound';
import { getAgentCallOffer, getIsOnCall, getRingtoneRef } from '@reducers/callReducer';
import { getChannelSetting, getResponseUserID } from '@reducers/initializationReducer';
import { getCaptchaToken, getIsMuted } from '@reducers/workAppReducer';
import { getTimeNow } from '@reducers/workWSReducer';
import { callActionType, initializationActionTypes, webSocketActionType, workStatesActionType } from '@type';
import { call, delay, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { env } from '@mdca/wde';
export function getChannelSettingsSaga(action) {
    var keyChannel, url, response, settingChannel, greetingObj, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                keyChannel = action.payload.keyChannel;
                url = "".concat(env('GET_CHANNEL_URL'), "/key?key=").concat(keyChannel);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 11, , 12]);
                return [4 /*yield*/, call(getChannelSettings, url)];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 3:
                settingChannel = _a.sent();
                return [4 /*yield*/, call(changeChannelSettingsGreeting, settingChannel === null || settingChannel === void 0 ? void 0 : settingChannel.greeting)];
            case 4:
                greetingObj = _a.sent();
                return [4 /*yield*/, put(initializationActions.setChannelSettings(settingChannel))];
            case 5:
                _a.sent();
                return [4 /*yield*/, put(initializationActions.setGettingClientHistory(settingChannel.gettingClientHistory))];
            case 6:
                _a.sent();
                return [4 /*yield*/, put(initializationActions.setRequiredFields(settingChannel === null || settingChannel === void 0 ? void 0 : settingChannel.requiredFields))];
            case 7:
                _a.sent();
                return [4 /*yield*/, put(initializationActions.setGreetingAndQuickPhrases(greetingObj))];
            case 8:
                _a.sent();
                return [4 /*yield*/, put(initializationActions.setCustomStyles((settingChannel === null || settingChannel === void 0 ? void 0 : settingChannel.customizations) || {}))];
            case 9:
                _a.sent();
                return [4 /*yield*/, put(initializationActions.setPrivacyPolicy(settingChannel.privacyPolicy))];
            case 10:
                _a.sent();
                return [3 /*break*/, 12];
            case 11:
                error_1 = _a.sent();
                console.log('getBrandsSaga', error_1);
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function authWSMiddleWare(action) {
    var infoUser, isCaptcha, captchaToken, error_2, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                infoUser = action.payload.infoUser;
                return [4 /*yield*/, select(getChannelSetting)];
            case 1:
                isCaptcha = (_a.sent()).isCaptcha;
                captchaToken = null;
                if (!isCaptcha) return [3 /*break*/, 5];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, select(getCaptchaToken)];
            case 3:
                captchaToken = _a.sent();
                return [3 /*break*/, 5];
            case 4:
                error_2 = _a.sent();
                console.log(error_2);
                return [3 /*break*/, 5];
            case 5: return [4 /*yield*/, call(initializationUserFetchChatSystem, __assign(__assign({}, infoUser), { captchaToken: captchaToken }))];
            case 6:
                response = _a.sent();
                if (!(isCaptcha && response.status === 401)) return [3 /*break*/, 9];
                webSocketActions.setIsConnectedWS(false);
                return [4 /*yield*/, call(chatWSAPI.close, 3001)];
            case 7:
                _a.sent();
                return [4 /*yield*/, put({
                        type: workStatesActionType.SET_VISIBILITY_MODAL_IS_ERROR_NOTIFY,
                        payload: {
                            isErrorNotify: true,
                            typeError: 3,
                        },
                    })];
            case 8:
                _a.sent();
                sessionStorage.clear();
                localStorage.clear();
                return [3 /*break*/, 12];
            case 9: return [4 /*yield*/, put(initializationActions.setIsLoadingMessages(true))];
            case 10:
                _a.sent();
                return [4 /*yield*/, call(authConnectionSagaWS, action, response)];
            case 11:
                _a.sent();
                _a.label = 12;
            case 12: return [2 /*return*/];
        }
    });
}
export function authConnectionSagaWS(action, response) {
    var channelId, responsePars, isBanned, userId, error, channel, clientId, urlGetAgent, responseAgentInfo, agentInfo, error, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                channelId = action.payload.channelId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 21, , 22]);
                if (!(response.status === 200)) return [3 /*break*/, 8];
                return [4 /*yield*/, response.json()];
            case 2:
                responsePars = _a.sent();
                return [4 /*yield*/, call(checkIsClientBanned, responsePars.status)];
            case 3:
                isBanned = _a.sent();
                return [4 /*yield*/, put(initializationActions.setIsClientBanned(isBanned))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(initializationActions.setResponseAuthUserID(responsePars.id))];
            case 5:
                _a.sent();
                return [4 /*yield*/, select(getResponseUserID)];
            case 6:
                userId = _a.sent();
                return [4 /*yield*/, call(chatWSAPI.authConnection, { userId: userId, channelId: channelId })];
            case 7:
                _a.sent();
                return [3 /*break*/, 10];
            case 8:
                error = response.json();
                console.log(error);
                return [4 /*yield*/, put(webSocketActions.errorResponseServer(true))];
            case 9:
                _a.sent();
                _a.label = 10;
            case 10: return [4 /*yield*/, select(getChannelSetting)];
            case 11:
                channel = _a.sent();
                return [4 /*yield*/, select(getResponseUserID)];
            case 12:
                clientId = _a.sent();
                urlGetAgent = "".concat(env('GET_AGENT_URL'), "/").concat(channel.id, "/clients/").concat(clientId, "/assigned_agent");
                return [4 /*yield*/, call(getAgentInfo, urlGetAgent)];
            case 13:
                responseAgentInfo = _a.sent();
                if (!(responseAgentInfo.status === 200)) return [3 /*break*/, 16];
                return [4 /*yield*/, responseAgentInfo.json()];
            case 14:
                agentInfo = _a.sent();
                return [4 /*yield*/, put(initializationActions.setAgent(agentInfo))];
            case 15:
                _a.sent();
                return [3 /*break*/, 17];
            case 16:
                error = response.json();
                console.log(error);
                _a.label = 17;
            case 17: return [4 /*yield*/, put(initializationActions.setIsAuthConnection(true))];
            case 18:
                _a.sent();
                return [4 /*yield*/, fork(typingWorker)];
            case 19:
                _a.sent();
                return [4 /*yield*/, put({ type: webSocketActionType.GET_MESSAGES_HISTORY })];
            case 20:
                _a.sent();
                return [3 /*break*/, 22];
            case 21:
                error_3 = _a.sent();
                console.log('sendAuthUsersSaga', error_3);
                return [3 /*break*/, 22];
            case 22: return [2 /*return*/];
        }
    });
}
export function updateMessageStatusSagaWS(action) {
    var _a, messageId, status, error_4;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, messageId = _a.messageId, status = _a.status;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, call(chatWSAPI.updateMessageStatus, { id: messageId, status: status })];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                error_4 = _b.sent();
                console.log(error_4.message);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export function updateClientOnlineStatusSagaWS(action) {
    var statusUser, userId, id, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                statusUser = action.payload;
                if (!(statusUser === 1)) return [3 /*break*/, 2];
                return [4 /*yield*/, put({ type: workStatesActionType.SET_IS_FOCUS_ON_CHAT, payload: false })];
            case 1:
                _a.sent();
                return [3 /*break*/, 4];
            case 2:
                if (!(statusUser === 2)) return [3 /*break*/, 4];
                return [4 /*yield*/, put({ type: workStatesActionType.SET_IS_FOCUS_ON_CHAT, payload: true })];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [4 /*yield*/, select(getResponseUserID)];
            case 5:
                userId = _a.sent();
                return [4 /*yield*/, select(getChannelSetting)];
            case 6:
                id = (_a.sent()).id;
                _a.label = 7;
            case 7:
                _a.trys.push([7, 9, , 10]);
                return [4 /*yield*/, call(chatWSAPI.updateClientOnlineStatus, { onlineStatus: statusUser, id: userId, channelId: id })];
            case 8:
                _a.sent();
                return [3 /*break*/, 10];
            case 9:
                error_5 = _a.sent();
                console.log(error_5.message);
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function sentTypingClientSagaWS() {
    var error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, call(chatWSAPI.sendTyping)];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                error_6 = _a.sent();
                console.log(error_6.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}
export function typingWorker() {
    var time, timeNow;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 5];
                return [4 /*yield*/, select(getTimeNow)];
            case 1:
                time = _a.sent();
                timeNow = Date.now();
                if (!(Math.abs(timeNow - time) / 1000 > 15)) return [3 /*break*/, 3];
                return [4 /*yield*/, put({
                        type: webSocketActionType.SET_IS_TYPING_AGENT,
                        payload: false,
                    })];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [4 /*yield*/, delay(5000)];
            case 4:
                _a.sent();
                return [3 /*break*/, 0];
            case 5: return [2 /*return*/];
        }
    });
}
function rejectCallSaga() {
    var ringRef, offer, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                return [4 /*yield*/, select(getRingtoneRef)];
            case 1:
                ringRef = _a.sent();
                return [4 /*yield*/, select(getAgentCallOffer)];
            case 2:
                offer = _a.sent();
                if (ringRef)
                    ringRef.pause();
                return [4 /*yield*/, put(webSocketActions.setRingtoneRef(null))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(webSocketActions.setAgentOffer(null))];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(clientRejectCall, { roomId: offer === null || offer === void 0 ? void 0 : offer.roomId })];
            case 5:
                _a.sent();
                return [3 /*break*/, 7];
            case 6:
                error_7 = _a.sent();
                console.log(error_7.message);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function stopCallSaga() {
    var error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, put(webSocketActions.setAgentStopCall({}))];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                error_8 = _a.sent();
                console.log(error_8.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}
export function endDialog() {
    var offer, isOnCall, error_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 9, , 10]);
                return [4 /*yield*/, select(getAgentCallOffer)];
            case 1:
                offer = _a.sent();
                return [4 /*yield*/, select(getIsOnCall)];
            case 2:
                isOnCall = _a.sent();
                if (!(!isOnCall && offer)) return [3 /*break*/, 4];
                return [4 /*yield*/, call(rejectCallSaga)];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4:
                if (!(isOnCall && offer)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(stopCallSaga)];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [4 /*yield*/, delay(500)];
            case 7:
                _a.sent();
                return [4 /*yield*/, call(chatWSAPI.endDialog)];
            case 8:
                _a.sent();
                return [3 /*break*/, 10];
            case 9:
                error_9 = _a.sent();
                console.log(error_9.message);
                return [3 /*break*/, 10];
            case 10: return [4 /*yield*/, put({
                    type: workStatesActionType.SET_VISIBILITY_MODAL_END_DIALOG,
                    payload: false,
                })];
            case 11:
                _a.sent();
                return [4 /*yield*/, put(initializationActions.setAgent({}))];
            case 12:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function setAgentOffer(action) {
    var offer;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getAgentCallOffer)];
            case 1:
                offer = _a.sent();
                if (offer) {
                    console.log('offer is exist');
                    return [2 /*return*/];
                }
                return [4 /*yield*/, put(webSocketActions.setAgentOffer(action.payload))];
            case 2:
                _a.sent();
                return [4 /*yield*/, call(playRingSoundOnAgentCall2)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function playRingSoundOnAgentCall2() {
    var flag, offer, r, isMuted, isOnCall, s, p;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                flag = true;
                _a.label = 1;
            case 1:
                if (!flag) return [3 /*break*/, 10];
                return [4 /*yield*/, select(getAgentCallOffer)];
            case 2:
                offer = _a.sent();
                return [4 /*yield*/, select(getRingtoneRef)];
            case 3:
                r = _a.sent();
                return [4 /*yield*/, select(getIsMuted)];
            case 4:
                isMuted = _a.sent();
                return [4 /*yield*/, select(getIsOnCall)];
            case 5:
                isOnCall = _a.sent();
                if (!(!!offer && !isOnCall)) return [3 /*break*/, 7];
                s = r || ring();
                return [4 /*yield*/, put(webSocketActions.setRingtoneRef(s))];
            case 6:
                _a.sent();
                if (isMuted) {
                    s.pause();
                }
                else {
                    p = s.play();
                    // eslint-disable-next-line @typescript-eslint/no-loop-func,promise/always-return
                    p.then(function () {
                        flag = false;
                    }).catch(function () { });
                }
                return [3 /*break*/, 8];
            case 7: return [3 /*break*/, 10];
            case 8: return [4 /*yield*/, delay(2000)];
            case 9:
                _a.sent();
                return [3 /*break*/, 1];
            case 10: return [2 /*return*/];
        }
    });
}
export function clientAnswer(action) {
    var payload, agentOffer, error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                return [4 /*yield*/, select(getAgentCallOffer)];
            case 1:
                agentOffer = _a.sent();
                if (!agentOffer) return [3 /*break*/, 6];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, call(chatWSAPI.clientAnswerCall, payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                error_10 = _a.sent();
                console.log(error_10);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 7];
            case 6:
                console.log('There is no calling!');
                _a.label = 7;
            case 7: return [2 /*return*/];
        }
    });
}
export function sendIceCandidate(action) {
    var data, error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                data = action.payload;
                return [4 /*yield*/, delay(1000)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, call(chatWSAPI.clientSendIceCandidate, data)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                error_11 = _a.sent();
                console.log(error_11);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function gotAgentIceCandidate(action) {
    var data, flag, isOnCall, agentOffer, peerConnection, iceCandidate, error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                data = action.payload;
                flag = true;
                _a.label = 1;
            case 1:
                if (!flag) return [3 /*break*/, 12];
                return [4 /*yield*/, select(getIsOnCall)];
            case 2:
                isOnCall = _a.sent();
                return [4 /*yield*/, select(getAgentCallOffer)];
            case 3:
                agentOffer = _a.sent();
                if (!(agentOffer !== null && isOnCall)) return [3 /*break*/, 10];
                _a.label = 4;
            case 4:
                _a.trys.push([4, 8, , 9]);
                peerConnection = window.prc;
                if (!peerConnection) return [3 /*break*/, 6];
                iceCandidate = new RTCIceCandidate(data.ice);
                return [4 /*yield*/, peerConnection.addIceCandidate(iceCandidate)];
            case 5:
                _a.sent();
                return [3 /*break*/, 7];
            case 6:
                console.log('peerConnection is null!');
                _a.label = 7;
            case 7: return [3 /*break*/, 9];
            case 8:
                error_12 = _a.sent();
                console.log(error_12);
                return [3 /*break*/, 9];
            case 9:
                flag = false;
                _a.label = 10;
            case 10: return [4 /*yield*/, delay(1000)];
            case 11:
                _a.sent();
                return [3 /*break*/, 1];
            case 12: return [2 /*return*/];
        }
    });
}
export var sagasWork = [
    takeLatest(webSocketActionType.AUTH_CONNECTION_SAGA, authWSMiddleWare),
    takeLatest(webSocketActionType.UPDATE_MESSAGE_STATUS_SAGA, updateMessageStatusSagaWS),
    takeLatest(initializationActionTypes.GET_SETTINGS_CHANNEL_SAGA, getChannelSettingsSaga),
    takeLatest(webSocketActionType.UPDATE_CLIENT_ONLINE_STATUS_SAGA, updateClientOnlineStatusSagaWS),
    takeLatest(webSocketActionType.SEND_CLIENT_TYPING, sentTypingClientSagaWS),
    takeLatest(webSocketActionType.END_DIALOG, endDialog),
    takeEvery(callActionType.SEND_CLIENT_ANSWER, clientAnswer),
    takeEvery(callActionType.GOT_AGENT_OFFER, setAgentOffer),
    takeEvery(callActionType.SEND_CLIENT_ICE_CANDIDATE, sendIceCandidate),
    takeEvery(callActionType.GOT_AGENT_ICE_CANDIDATE, gotAgentIceCandidate),
];

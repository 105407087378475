var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { Resizable as LibResizable, } from 're-resizable';
var ResizableStyled = styled(React.forwardRef(function (props, ref) { return (React.createElement(LibResizable, __assign({}, props, { ref: function (instance) {
        if ((instance === null || instance === void 0 ? void 0 : instance.resizable) && ref) {
            // @ts-expect-error: noop
            ref(instance === null || instance === void 0 ? void 0 : instance.resizable);
        }
    } }))); }))(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: transparent !important;\n"], ["\n  background: transparent !important;\n"])));
function getStyles(properties, gap) {
    var fullscreen = properties.fullscreen;
    if ((gap === null || gap === void 0 ? void 0 : gap.isMobile) && fullscreen) {
        return {
            position: 'fixed',
            top: gap.top,
            right: gap.right,
            bottom: gap.bottom,
            left: gap.left,
            zIndex: 99999998,
        };
    }
    if (fullscreen) {
        return { position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 99999998 };
    }
    return __assign(__assign({ position: 'fixed' }, properties), { zIndex: 99999998 });
}
var getMinSize = function (windowWidth, windowHeight, size) {
    var minWidth = 350;
    var minHeight = 550;
    if (size.width >= windowWidth) {
        minWidth = windowWidth - 50;
    }
    if (size.height >= windowHeight) {
        minHeight = windowHeight - 50;
    }
    minWidth = Math.min(minWidth, size.width);
    minHeight = Math.min(minHeight, size.height);
    return { minWidth: minWidth, minHeight: minHeight };
};
export var BaseResizable = React.forwardRef(function (props, ref) {
    var fullscreen = props.fullscreen, size = props.size, position = props.position, windowWidth = props.windowWidth, windowHeight = props.windowHeight, className = props.className, style = props.style, chatWindowPadding = props.chatWindowPadding, isMobile = props.isMobile, onSetSize = props.onSetSize, onSetPosition = props.onSetPosition, children = props.children;
    var _a = React.useState(false), isRightResize = _a[0], setRightResize = _a[1];
    var _b = React.useState(false), isRightTopResize = _b[0], setRightTopResize = _b[1];
    var _c = React.useState(false), isLeftBottomResize = _c[0], setLeftBottomResize = _c[1];
    React.useEffect(function () {
        var right = position.right, bottom = position.bottom;
        var width = size === null || size === void 0 ? void 0 : size.width;
        var height = size === null || size === void 0 ? void 0 : size.height;
        var negativeDeltaRight = windowWidth - (right + width);
        var negativeDeltaBottom = windowHeight - (bottom + height);
        var isMoveRight = negativeDeltaRight < 0;
        var isMoveBottom = negativeDeltaBottom < 0;
        if (!fullscreen && (isMoveRight || isMoveBottom)) {
            onSetPosition({
                right: isMoveRight && right >= 0 ? right + negativeDeltaRight : right,
                bottom: isMoveBottom && bottom >= 0 ? bottom + negativeDeltaBottom : bottom,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullscreen, windowHeight, windowWidth, onSetPosition]);
    var onResizeHandler = React.useCallback(function (_e, _dir, innerRef) {
        onSetSize({
            width: Number.parseInt(innerRef.style.width, 10),
            height: Number.parseInt(innerRef.style.height, 10),
        });
    }, [onSetSize]);
    var onResizeStartHandler = React.useCallback(function (_e, dir) {
        var width = size === null || size === void 0 ? void 0 : size.width;
        var height = size === null || size === void 0 ? void 0 : size.height;
        var right = position.right, bottom = position.bottom;
        switch (dir) {
            case 'bottom':
            case 'right':
            case 'bottomRight': {
                setRightResize(true);
                onSetPosition(__assign(__assign({}, position), { top: windowHeight - bottom - height, left: windowWidth - right - width }));
                break;
            }
            case 'topRight': {
                setRightTopResize(true);
                onSetPosition(__assign(__assign({}, position), { left: windowWidth - right - width }));
                break;
            }
            case 'bottomLeft': {
                setLeftBottomResize(true);
                onSetPosition(__assign(__assign({}, position), { top: windowHeight - bottom - height }));
                break;
            }
            default: {
                setRightResize(false);
            }
        }
    }, [onSetPosition, position, size === null || size === void 0 ? void 0 : size.height, size === null || size === void 0 ? void 0 : size.width, windowHeight, windowWidth]);
    var onResizeStopHandler = React.useCallback(function (_e, dir, innerRef, delta) {
        var width = delta.width, height = delta.height;
        var right = position.right, bottom = position.bottom;
        var newPosition;
        switch (dir) {
            case 'bottom':
            case 'right':
            case 'bottomRight': {
                newPosition = { bottom: bottom - height, right: right - width };
                break;
            }
            case 'topRight': {
                newPosition = { bottom: bottom, right: right - width };
                break;
            }
            case 'bottomLeft': {
                newPosition = { bottom: bottom - height, right: right };
                break;
            }
            default: {
                newPosition = position;
                break;
            }
        }
        onSetSize({
            width: Number.parseInt(innerRef.style.width, 10),
            height: Number.parseInt(innerRef.style.height, 10),
        });
        onSetPosition(newPosition);
        sessionStorage.setItem('AskMeWebLibrary-current-position', JSON.stringify(newPosition));
        sessionStorage.setItem('AskMeWebLibrary-current-size', JSON.stringify({
            width: Number.parseInt(innerRef.style.width, 10),
            height: Number.parseInt(innerRef.style.height, 10),
        }));
        setRightResize(false);
        setRightTopResize(false);
        setLeftBottomResize(false);
    }, [onSetPosition, onSetSize, position]);
    var maxWidth = position.right <= 0 ? windowWidth + position.right : windowWidth;
    var maxHeight = position.bottom <= 0 ? windowHeight + position.bottom : windowHeight;
    var _d = getMinSize(windowWidth, windowHeight, size), minWidth = _d.minWidth, minHeight = _d.minHeight;
    var isRightTop = isRightResize || isRightTopResize;
    var isLeftBottom = isRightResize || isLeftBottomResize;
    var sizeProp = React.useMemo(function () {
        if (isMobile && fullscreen) {
            return {
                width: windowWidth - (chatWindowPadding.left + chatWindowPadding.right),
                height: windowHeight - (chatWindowPadding.top + chatWindowPadding.bottom),
            };
        }
        return {
            width: fullscreen ? windowWidth : size === null || size === void 0 ? void 0 : size.width,
            height: fullscreen ? windowHeight : size === null || size === void 0 ? void 0 : size.height,
        };
    }, [
        isMobile,
        fullscreen,
        windowWidth,
        chatWindowPadding.left,
        chatWindowPadding.right,
        chatWindowPadding.top,
        chatWindowPadding.bottom,
        size === null || size === void 0 ? void 0 : size.width,
        size === null || size === void 0 ? void 0 : size.height,
        windowHeight,
    ]);
    var styleProp = React.useMemo(function () { return (__assign(__assign({}, getStyles({
        fullscreen: fullscreen,
        minWidth: minWidth,
        minHeight: minHeight,
        right: isRightTop ? 'initial' : position.right,
        bottom: isLeftBottom ? 'initial' : position.bottom,
        left: isRightTop ? position.left : 'initial',
        top: isLeftBottom ? position.top : 'initial',
    }, __assign(__assign({}, chatWindowPadding), { isMobile: isMobile }))), style)); }, [
        fullscreen,
        minWidth,
        minHeight,
        isRightTop,
        position.right,
        position.bottom,
        position.left,
        position.top,
        isLeftBottom,
        chatWindowPadding,
        isMobile,
        style,
    ]);
    var enableProp = React.useMemo(function () { return ({
        top: !fullscreen,
        left: !fullscreen,
        topLeft: !fullscreen,
        bottomRight: !fullscreen,
        bottom: !fullscreen,
        right: !fullscreen,
        topRight: !fullscreen,
        bottomLeft: !fullscreen,
    }); }, [fullscreen]);
    return (React.createElement(ResizableStyled, { ref: ref, className: clsx('Resizable', className), bounds: "window", boundsByDirection: true, size: sizeProp, style: styleProp, enable: enableProp, minWidth: minWidth, minHeight: minHeight, maxWidth: fullscreen ? windowWidth : maxWidth, maxHeight: fullscreen ? windowHeight : maxHeight, onResize: onResizeHandler, onResizeStop: onResizeStopHandler, onResizeStart: onResizeStartHandler }, children));
});
export var Resizable = React.memo(BaseResizable);
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
var footer = function (isBanned, footerHeight) { return (isBanned ? 0 : footerHeight + 51); };
var calcBodyHeight = function (footerHeight, isBanned, headerHeight) {
    if (headerHeight === void 0) { headerHeight = '50'; }
    return "".concat(footer(isBanned, footerHeight) + Number.parseInt(headerHeight, 10), "px");
};
export default styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: calc(100% - ", ");\n  background: ", ";\n  background-image: ", ";\n  background-size: ", ";\n  background-repeat: no-repeat;\n  background-position: center;\n\n  .ScrollbarsCustom-Track {\n    background: ", " !important;\n    width: ", " !important;\n    border-radius: 5px !important;\n  }\n\n  .ScrollbarsCustom-Thumb {\n    transition: 0.1s !important;\n    background: ", " !important;\n  }\n\n  border: 1px solid ", ";\n"], ["\n  height: calc(100% - ", ");\n  background: ", ";\n  background-image: ", ";\n  background-size: ", ";\n  background-repeat: no-repeat;\n  background-position: center;\n\n  .ScrollbarsCustom-Track {\n    background: ", " !important;\n    width: ", " !important;\n    border-radius: 5px !important;\n  }\n\n  .ScrollbarsCustom-Thumb {\n    transition: 0.1s !important;\n    background: ", " !important;\n  }\n\n  border: 1px solid ", ";\n"])), function (props) { return calcBodyHeight(props.inputHeight, props.isBanned, props.headerHeight); }, function (props) { return props.chatWindowsBodyBackground || '#F5F5F5'; }, function (props) { return (props.chatWindowsLogo ? "url(".concat(props.chatWindowsLogo, ")") : null); }, function (props) { return props.chatWindowsLogoSize || '250px'; }, function (props) { return props.scrollThumbBackground || '#F5F5F5'; }, function (props) { return props.scrollWidth || '10px'; }, function (props) { return props.scrollBackground || '#12171e'; }, function (props) { return props.chatWindowsBorderColor || 'none'; });
var templateObject_1;

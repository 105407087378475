import React from 'react';
import { useSelector } from 'react-redux';
import { getStylesIcon } from '@reducers/stylesReducer';
import { env } from '@mdca/wde';
import AvatarWrapper from './StyledAvatar';
import './Avatar.less';
var Avatar = function (_a) {
    var toggleEnabled = _a.toggleEnabled, iconConfig = _a.iconConfig;
    var _b = useSelector(getStylesIcon), iconUrl = _b.iconUrl, iconBackground = _b.iconBackground;
    return (React.createElement(AvatarWrapper, { onClick: toggleEnabled, className: "AskMeWebLibrary-Chat-icon__avatar", iconBackground: (iconConfig === null || iconConfig === void 0 ? void 0 : iconConfig.iconBackground) || iconBackground },
        React.createElement("img", { src: iconUrl || "".concat(env('ICON_WEB_UI'), "/icon.svg"), alt: "iconAvatar" })));
};
export default Avatar;

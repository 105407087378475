import React from 'react';

function PrivacyPolicy({ privacyPolicy, isPrivacyPolicy, lang, setIsPrivacyPolicy }) {
  return (
    <div className="privacy-policy-wrap">
      <input
        type="checkbox"
        checked={isPrivacyPolicy}
        onChange={() => setIsPrivacyPolicy(!isPrivacyPolicy)}
        style={{
          backgroundColor: 'initial',
          cursor: 'default',
          appearance: 'auto',
          boxSizing: 'border-box',
          margin: '0 3px 0 4px',
          padding: 'initial',
          border: 'initial',
          height: '15px',
          width: '15px',
        }}
      />
      <div className="privacy-policy-wrap__text">
        {privacyPolicy.data[lang].text}
        <a href={privacyPolicy.data[lang].link} target="_blank" rel="noreferrer">
          {` ${privacyPolicy.data[lang].linkText}`}
        </a>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

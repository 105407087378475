import React from 'react';
import createCache from '@emotion/cache';
import { CacheProvider as EmotionCacheProvider, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import MuiThemeProvider from '@mui/styles/ThemeProvider';
import { ShadowDomContainer } from '../ShadowDomContainer';
export function ThemeProvider(_a) {
    var children = _a.children, isShadowDom = _a.isShadowDom;
    var _b = React.useState(), emotionCache = _b[0], setEmotionCache = _b[1];
    function setEmotionStyles(ref) {
        if (ref && !emotionCache) {
            var createdEmotionWithRef = createCache({
                key: 'askme',
                container: ref,
            });
            setEmotionCache(createdEmotionWithRef);
        }
    }
    function setShadowRefs(ref) {
        setEmotionStyles(ref);
    }
    return (React.createElement(ShadowDomContainer, { isShadowDom: isShadowDom },
        React.createElement("div", { ref: setShadowRefs }),
        isShadowDom && emotionCache ? (React.createElement(MuiThemeProvider, { theme: {} },
            React.createElement(EmotionThemeProvider, { theme: {} },
                React.createElement(EmotionCacheProvider, { value: emotionCache },
                    React.createElement(ScopedCssBaseline, null, children))))) : (children)));
}

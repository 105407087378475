var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getStorageClientInfo } from '@helpers/getStorageClientInfo';
import { beep } from '@helpers/soundNotification';
import { getChannelSetting } from '@reducers/initializationReducer';
import { initializationActionTypes } from '@type';
import { rawResizableActions } from '../redux/actions/resizable';
import { Chat } from './Chat/Chat';
import { useMuted } from './hooks/useMuted';
export function Main(props) {
    var _a;
    var keyBrand = props.configWebUi.keyBrand;
    var setMute = useMuted().setMute;
    var dispatch = useDispatch();
    var channelSettings = useSelector(getChannelSetting);
    var isMutedFromBack = (_a = props.configWebUi) === null || _a === void 0 ? void 0 : _a.isMuted;
    var isMuted = getStorageClientInfo(true).isMuted;
    var playOnce = React.useCallback(function () {
        var soundPromise = beep();
        soundPromise.catch(function () {
            setTimeout(function () {
                playOnce();
            }, 2000);
        });
    }, []);
    React.useEffect(function () {
        if (isMuted === null && isMutedFromBack) {
            setMute();
        }
        else if (!isMuted) {
            playOnce();
        }
    }, [isMuted, isMutedFromBack, playOnce, setMute]);
    React.useEffect(function () {
        dispatch({
            type: initializationActionTypes.GET_SETTINGS_CHANNEL_SAGA,
            payload: { keyChannel: keyBrand },
        });
    }, [dispatch, keyBrand]);
    React.useEffect(function () {
        if (isMobile) {
            dispatch(rawResizableActions.setIsFullScreen(true));
        }
    }, [dispatch]);
    return React.createElement(React.Fragment, null, channelSettings && React.createElement(Chat, __assign({}, props)));
}

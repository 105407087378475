import React from 'react';
import { useSelector } from 'react-redux';
import { checkObj } from '@helpers/checkObj';
import localization from '@localization';
import { getChannelSetting } from '@reducers/initializationReducer';
import { getStylesAgentMsg } from '@reducers/stylesReducer';
import StyledTime from './StyledTime';
var Time = function (props) {
    var time = props.time, agentName = props.agentName, lang = props.lang;
    var botNameLocales = useSelector(getChannelSetting).botNameLocales;
    var colorAgentNameAndTime = useSelector(getStylesAgentMsg).colorAgentNameAndTime;
    return (React.createElement(StyledTime, { colorAgentNameAndTime: colorAgentNameAndTime },
        time,
        " ",
        agentName || checkObj(botNameLocales, lang) || localization[lang]['bot.Name']));
};
export default Time;

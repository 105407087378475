import React, { useEffect } from 'react';
import StyledTyping from './StyledTyping';
import './Typing.less';
var Typing = function (_a) {
    var isTyping = _a.isTyping, fastForwardHandler = _a.fastForwardHandler;
    useEffect(function () {
        fastForwardHandler();
    }, [isTyping]);
    return (React.createElement(React.Fragment, null, isTyping && (React.createElement(StyledTyping, { className: "AskMeWebLibrary-message__typing" },
        React.createElement("div", { className: "AskMeWebLibrary-circle" },
            React.createElement("div", { className: "AskMeWebLibrary-circleG AskMeWebLibrary-first" }),
            React.createElement("div", { className: "AskMeWebLibrary-circleG AskMeWebLibrary-second" }),
            React.createElement("div", { className: "AskMeWebLibrary-circleG AskMeWebLibrary-third" }))))));
};
export default Typing;

var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { initializationActions } from '@actions';
import { getStorageClientInfo } from '@helpers/getStorageClientInfo';
import { setStorageClientInfo } from '@helpers/setStorageClientInfo';
import { getGettingClientHistory } from '@reducers/initializationReducer';
import { initializationActionTypes } from '@type';
import { call, put, select, takeEvery } from 'redux-saga/effects';
export function setClientSessionStorage(data) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(setStorageClientInfo, data, false)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function setClientLocalStorage(data) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(setStorageClientInfo, data, true)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function setClient(action) {
    var gettingClientHistory, userId, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(getGettingClientHistory)];
            case 1:
                gettingClientHistory = _b.sent();
                _a = gettingClientHistory;
                switch (_a) {
                    case 'localStorage': return [3 /*break*/, 2];
                }
                return [3 /*break*/, 5];
            case 2: return [4 /*yield*/, call(setClientLocalStorage, action.payload)];
            case 3:
                _b.sent();
                return [4 /*yield*/, JSON.parse(localStorage.getItem('AskMeWebLibrary-user-id'))];
            case 4:
                userId = _b.sent();
                return [3 /*break*/, 8];
            case 5: return [4 /*yield*/, call(setClientSessionStorage, action.payload)];
            case 6:
                _b.sent();
                return [4 /*yield*/, JSON.parse(sessionStorage.getItem('AskMeWebLibrary-user-id'))];
            case 7:
                userId = _b.sent();
                _b.label = 8;
            case 8: return [4 /*yield*/, put(initializationActions.setClientId(userId))];
            case 9:
                _b.sent();
                return [4 /*yield*/, put(initializationActions.getClientInfo())];
            case 10:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function getClientExternalId() {
    var gettingClientHistory, userId, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(getGettingClientHistory)];
            case 1:
                gettingClientHistory = _b.sent();
                _a = gettingClientHistory;
                switch (_a) {
                    case 'localStorage': return [3 /*break*/, 2];
                }
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, JSON.parse(localStorage.getItem('AskMeWebLibrary-user-id'))];
            case 3:
                userId = _b.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, JSON.parse(sessionStorage.getItem('AskMeWebLibrary-user-id'))];
            case 5:
                userId = _b.sent();
                _b.label = 6;
            case 6: return [4 /*yield*/, put(initializationActions.setClientId(userId))];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(initializationActions.getClientInfo())];
            case 8:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function saveClientInfo() {
    var gettingClientHistory, clientInfo, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(getGettingClientHistory)];
            case 1:
                gettingClientHistory = _b.sent();
                _a = gettingClientHistory;
                switch (_a) {
                    case 'localStorage': return [3 /*break*/, 2];
                }
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, call(getStorageClientInfo, true)];
            case 3:
                clientInfo = _b.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, call(getStorageClientInfo, false)];
            case 5:
                clientInfo = _b.sent();
                _b.label = 6;
            case 6: return [4 /*yield*/, put(initializationActions.saveClientInfo(clientInfo))];
            case 7:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export var sagasClient = [
    takeEvery(initializationActionTypes.SET_STORAGE_CLIENT_INFO, setClient),
    takeEvery(initializationActionTypes.GET_CLIENT_EXTERNAL_ID, getClientExternalId),
    takeEvery(initializationActionTypes.GET_STORAGE_CLIENT_INFO, saveClientInfo),
];

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSelector } from 'react-redux';
import localization from '@localization';
import { getStylesAuth } from '@reducers/stylesReducer';
import { Steps as ProgressSteps } from 'antd';
import { Header } from '../ChatWindow/Header/Header';
var CaptchaSignIn = function (props) {
    var toggleEnabled = props.toggleEnabled, changeSizeImg = props.changeSizeImg, currentResizeImg = props.currentResizeImg, toggleFullScreen = props.toggleFullScreen, isFullScreen = props.isFullScreen, lang = props.lang, drug = props.drug, currentWidth = props.currentWidth, isRightResize = props.isRightResize, handleCaptcha = props.handleCaptcha;
    var _a = useSelector(getStylesAuth), registrationBackgroundColor = _a.registrationBackgroundColor, signUpColor = _a.signUpColor, signUpBackground = _a.signUpBackground;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, {
            lang: lang,
            isRightResize: isRightResize,
            currentWidth: currentWidth,
            isFullScreen: isFullScreen,
            changeSizeImg: changeSizeImg,
            toggleEnabled: toggleEnabled,
            toggleFullScreen: toggleFullScreen,
            currentResizeImg: currentResizeImg,
        }, { isAuthHeader: true })),
        React.createElement("div", { className: "AskMeWebLibrary-auth-window", ref: function (el) {
                if (el)
                    el.style.setProperty('background-color', registrationBackgroundColor || '#F5F5F5', 'important');
            } },
            React.createElement(ProgressSteps, { current: 1 }, [0, 1].map(function (item, index) { return (React.createElement(ProgressSteps.Step, { key: index })); })),
            React.createElement("div", { className: "steps-content_wrap" },
                React.createElement("div", { className: "steps-btn" },
                    React.createElement("div", { className: "steps-btn__next", onClick: handleCaptcha, style: {
                            color: signUpColor || '#fff',
                            backgroundColor: signUpBackground || '#4285F4',
                        } }, localization[lang]['btn.signin']))))));
};
export default CaptchaSignIn;

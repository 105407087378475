import { useEffect } from 'react';
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export var useKeypress = function (key, action) {
    useEffect(function () {
        function onKeyup(e) {
            if (e.key === key) {
                action();
            }
        }
        window.addEventListener('keyup', onKeyup);
        return function () { return window.removeEventListener('keyup', onKeyup); };
    }, [action, key]);
};

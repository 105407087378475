import React from 'react';
import { fileDownload } from '@helpers/fileDownload';
import { env } from '@mdca/wde';
export function DownloadFile(_a) {
    var text = _a.text;
    var fileType = React.useState(function () {
        var _a;
        if (!text) {
            return '';
        }
        return ((_a = text.match(/\.([A-Za-z]+)$/)) === null || _a === void 0 ? void 0 : _a[1]) || '';
    })[0];
    var fileName = React.useState(function () {
        if (!text) {
            return '';
        }
        return text.split('/').pop();
    })[0];
    var fileIcons = React.useMemo(function () { return ({
        docIcon: "".concat(env('ICON_WEB_UI'), "/doc-light.svg"),
        docxIcon: "".concat(env('ICON_WEB_UI'), "/docx-light.svg"),
        fileIcon: "".concat(env('ICON_WEB_UI'), "/file-light.svg"),
        pdfIcon: "".concat(env('ICON_WEB_UI'), "/pdf-light.svg"),
        pptIcon: "".concat(env('ICON_WEB_UI'), "/ppt-light.svg"),
        pptxIcon: "".concat(env('ICON_WEB_UI'), "/pptx-light.svg"),
        sendIcon: "".concat(env('ICON_WEB_UI'), "/send-light.svg"),
        txtIcon: "".concat(env('ICON_WEB_UI'), "/txt-light.svg"),
        xlsIcon: "".concat(env('ICON_WEB_UI'), "/xls-light.svg"),
        xlsxIcon: "".concat(env('ICON_WEB_UI'), "/xlsx-light.svg"),
    }); }, []);
    var getIcon = React.useMemo(function () {
        if (text.endsWith('.doc'))
            return fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.docIcon;
        if (text.endsWith('.docx'))
            return fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.docxIcon;
        if (text.endsWith('.pdf'))
            return fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.pdfIcon;
        if (text.endsWith('.ppt'))
            return fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.pptIcon;
        if (text.endsWith('.pptx'))
            return fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.pptxIcon;
        if (text.endsWith('.txt'))
            return fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.txtIcon;
        if (text.endsWith('.xls'))
            return fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.xlsIcon;
        if (text.endsWith('.xlsx'))
            return fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.xlsxIcon;
        return fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.fileIcon;
    }, [
        fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.docIcon,
        fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.docxIcon,
        fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.fileIcon,
        fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.pdfIcon,
        fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.pptIcon,
        fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.pptxIcon,
        fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.txtIcon,
        fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.xlsIcon,
        fileIcons === null || fileIcons === void 0 ? void 0 : fileIcons.xlsxIcon,
        text,
    ]);
    var handleDownload = React.useCallback(function () {
        var fileUrl = text;
        if (!fileName) {
            return;
        }
        fileDownload(fileUrl, fileName).catch(function (error) { return console.error(error); });
    }, [fileName, text]);
    return (React.createElement("div", { role: "button", tabIndex: 0, style: {
            display: 'flex',
            float: 'left',
            textDecoration: 'none',
            cursor: 'pointer',
        }, onClick: function () { return handleDownload(); }, onKeyDown: function () { return handleDownload(); } },
        React.createElement("div", { style: {
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
            } },
            React.createElement("img", { src: getIcon, alt: "file", style: { width: '25px', height: '25px' } }),
            fileType && (React.createElement("span", { style: {
                    fontSize: '12px',
                    marginLeft: '5px',
                } }, "".concat((fileName === null || fileName === void 0 ? void 0 : fileName.slice(0, 10)) || '', "... .").concat(fileType))))));
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var Img = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: 10800;\n  margin: auto;\n  max-width: 100%;\n"], ["\n  z-index: 10800;\n  margin: auto;\n  max-width: 100%;\n"])));
export var ZoomButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 46px;\n  height: 46px;\n  background: #222;\n  border-radius: 50%;\n  margin: 0 20px;\n  border: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n\n  &:hover {\n    background: #fff;\n    svg {\n      fill: #222;\n    }\n  }\n  transition: 0.3s ease;\n\n  & svg {\n    height: 36px;\n    width: 36px;\n    fill: #fff;\n  }\n"], ["\n  width: 46px;\n  height: 46px;\n  background: #222;\n  border-radius: 50%;\n  margin: 0 20px;\n  border: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n\n  &:hover {\n    background: #fff;\n    svg {\n      fill: #222;\n    }\n  }\n  transition: 0.3s ease;\n\n  & svg {\n    height: 36px;\n    width: 36px;\n    fill: #fff;\n  }\n"])));
export var ZoomButtonsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  left: 50%;\n  bottom: 30px;\n  transform: translateX(-50%);\n  z-index: 1;\n  display: flex;\n"], ["\n  position: absolute;\n  left: 50%;\n  bottom: 30px;\n  transform: translateX(-50%);\n  z-index: 1;\n  display: flex;\n"])));
export var ClosedButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 0;\n  width: 3.5em;\n  height: 3.5em;\n  text-align: left;\n  background: #222;\n  border-radius: 50%;\n  position: fixed;\n  z-index: 2147483647;\n  top: 2.5em;\n  right: 2.5em;\n  transition: color 0.3s ease;\n  outline: 0;\n  cursor: pointer;\n\n  &:before,\n  :after {\n    width: 2px;\n    background-color: #fff;\n    content: '';\n    position: absolute;\n    top: 20%;\n    bottom: 20%;\n    left: 50%;\n    margin-left: -1px;\n  }\n\n  &:before {\n    transform: rotate(45deg);\n  }\n\n  &:after {\n    transform: rotate(-45deg);\n  }\n\n  &:hover,\n  :focus {\n    background-color: #fff;\n  }\n\n  &:hover:before,\n  :hover:after,\n  :focus:before,\n  :focus:after {\n    background-color: #222;\n  }\n\n  @media all and (max-width: 500px) and (orientation: portrait),\n    all and (max-height: 430px) and (orientation: landscape) {\n    width: 1.75em;\n    height: 1.75em;\n    top: 15px;\n    right: 10px;\n  }\n"], ["\n  border: 0;\n  width: 3.5em;\n  height: 3.5em;\n  text-align: left;\n  background: #222;\n  border-radius: 50%;\n  position: fixed;\n  z-index: 2147483647;\n  top: 2.5em;\n  right: 2.5em;\n  transition: color 0.3s ease;\n  outline: 0;\n  cursor: pointer;\n\n  &:before,\n  :after {\n    width: 2px;\n    background-color: #fff;\n    content: '';\n    position: absolute;\n    top: 20%;\n    bottom: 20%;\n    left: 50%;\n    margin-left: -1px;\n  }\n\n  &:before {\n    transform: rotate(45deg);\n  }\n\n  &:after {\n    transform: rotate(-45deg);\n  }\n\n  &:hover,\n  :focus {\n    background-color: #fff;\n  }\n\n  &:hover:before,\n  :hover:after,\n  :focus:before,\n  :focus:after {\n    background-color: #222;\n  }\n\n  @media all and (max-width: 500px) and (orientation: portrait),\n    all and (max-height: 430px) and (orientation: landscape) {\n    width: 1.75em;\n    height: 1.75em;\n    top: 15px;\n    right: 10px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
// export const ModalWrapper = styled.div`
//   & div {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin: auto;
//   }
// `;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useRef } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import RestartAlt from '@mui/icons-material/RestartAlt';
import { ClosedButton, Img, ZoomButton, ZoomButtonsContainer } from './styled';
import { useKeypress } from './useKeypress';
var minScale = 1;
var maxScale = 3;
var defaultPosition = { x: 0, y: 0, scale: minScale, animated: true };
var zoomCoefficient = 0.2;
export function ImageViewer(props) {
    var src = props.src, handleClose = props.handleClose;
    useKeypress('Escape', handleClose);
    var _a = React.useState(defaultPosition), position = _a[0], setPosition = _a[1];
    var nodeRef = useRef(null);
    var quickPinchZoomRef = useRef(null);
    var onUpdate = useCallback(function (_a) {
        var x = _a.x, y = _a.y, pinchScale = _a.scale;
        if (nodeRef.current) {
            var value = make3dTransformValue({ x: x, y: y, scale: pinchScale });
            setPosition(__assign(__assign({}, position), { x: x, y: y, scale: pinchScale }));
            nodeRef.current.style.setProperty('transform', value);
        }
    }, [position]);
    var onZoomIn = function () {
        var _a;
        var scale = position.scale;
        if (scale < maxScale) {
            setPosition(__assign(__assign({}, position), { scale: scale + zoomCoefficient }));
            (_a = quickPinchZoomRef.current) === null || _a === void 0 ? void 0 : _a.scaleTo(__assign(__assign({}, position), { scale: scale + zoomCoefficient }));
        }
    };
    var onZoomOut = function () {
        var _a;
        var scale = position.scale;
        if (scale > minScale) {
            setPosition(__assign(__assign({}, position), { scale: scale - zoomCoefficient }));
            (_a = quickPinchZoomRef.current) === null || _a === void 0 ? void 0 : _a.scaleTo(__assign(__assign({}, position), { scale: scale - zoomCoefficient }));
        }
    };
    var onReset = useCallback(function () {
        var _a;
        (_a = quickPinchZoomRef.current) === null || _a === void 0 ? void 0 : _a.scaleTo(defaultPosition);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ClosedButton, { onClick: handleClose, title: "exit" }),
        React.createElement(ZoomButtonsContainer, null,
            React.createElement(ZoomButton, { onClick: onZoomOut },
                React.createElement(Remove, null)),
            React.createElement(ZoomButton, { onClick: onZoomIn },
                React.createElement(Add, null)),
            React.createElement(ZoomButton, { onClick: onReset },
                React.createElement(RestartAlt, null))),
        React.createElement(QuickPinchZoom, { ref: quickPinchZoomRef, enabled: position.scale > minScale, inertia: false, containerProps: {
                style: {
                    touchAction: 'auto',
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    overflow: 'hidden',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
            }, wheelScaleFactor: 100, shouldInterceptWheel: function () { return false; }, maxZoom: maxScale, minZoom: minScale, zoomOutFactor: 1, onUpdate: onUpdate },
            React.createElement(Img, { ref: nodeRef, src: src, alt: "ResponseBotImage" }))));
}

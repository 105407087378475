import React from 'react';
import { useSelector } from 'react-redux';
import { getStylesIcon } from '@reducers/stylesReducer';
import CallIconWrapper from './styledCallIcon';
import '../Avatar/Avatar.less';
var CallIcon = function (_a) {
    var toggleEnabled = _a.toggleEnabled;
    var iconBackground = useSelector(getStylesIcon).iconBackground;
    return (React.createElement(CallIconWrapper, { iconBackground: iconBackground },
        React.createElement("div", { className: "ring" },
            React.createElement("div", { className: "AskMeWebLibrary-alo-phone AskMeWebLibrary-alo-green AskMeWebLibrary-alo-show" },
                React.createElement("div", { className: "AskMeWebLibrary-alo-ph-circle" }),
                React.createElement("div", { className: "AskMeWebLibrary-alo-ph-circle-fill" }),
                React.createElement("div", { onClick: toggleEnabled, className: "AskMeWebLibrary-alo-ph-img-circle" })))));
};
export default CallIcon;

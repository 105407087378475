export var textCustomTimeRenderer = function (stringDate) {
    if (!stringDate)
        return;
    var date = new Date(stringDate);
    var hours = date.getHours() >= 10 ? date.getHours() : "0".concat(date.getHours());
    var minutes = date.getMinutes() >= 10 ? date.getMinutes() : "0".concat(date.getMinutes());
    return "".concat(hours, ":").concat(minutes);
};
export var quoteDateToString = function (stringDate) {
    if (!stringDate)
        return;
    var time = textCustomTimeRenderer(stringDate);
    return "".concat(new Date(stringDate).toLocaleDateString(), ", ").concat(time);
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMsgHistoryStore } from '@reducers/initializationReducer';
import { webSocketActionType } from '@type';
import { scrollDown } from '../BodyHelper';
export var useBotMessage = function (_a) {
    var scrollElement = _a.scrollElement, value = _a.value;
    var messageHistory = useSelector(getMsgHistoryStore);
    var dispatch = useDispatch();
    useEffect(function () {
        setTimeout(scrollDown(scrollElement), 5000);
    }, []);
    useEffect(function () {
        dispatch({ type: webSocketActionType.SET_IS_TYPING_AGENT, payload: false });
    }, [value.data]);
    var findLastChoiceMessageId = function () {
        for (var i = messageHistory.length - 1; i >= 0; i--) {
            var message = messageHistory[i];
            if (message.data.type === 10)
                return message.data.id;
        }
        return -1;
    };
    var getChoices = function () {
        var _a, _b, _c;
        if (((_a = value.data.choice) === null || _a === void 0 ? void 0 : _a.choices) && !((_b = value.data) === null || _b === void 0 ? void 0 : _b.isCompletedChoice) && value.data.id === findLastChoiceMessageId()) {
            return Object.keys((_c = value.data.choice) === null || _c === void 0 ? void 0 : _c.choices)
                .filter(function (el) { return el !== 'DISABLE'; })
                .map(function (el) { return el; });
        }
        return [];
    };
    var choices = getChoices();
    var id = choices.length > 0 ? value.data.id : null;
    return {
        choices: choices,
        id: id,
    };
};

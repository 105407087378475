export var changeVisibilityHandler = function (scrollElement, setFastForwardButtonVisibility) { return function () {
    if (scrollElement.current) {
        setFastForwardButtonVisibility(scrollElement.current.scrollTop < scrollElement.current.scrollHeight - 1000);
    }
}; };
export var scrollDown = function (scrollElement) { return function () {
    scrollElement && (scrollElement.current.scrollTop = scrollElement.current.scrollHeight);
}; };
export var checkScroll = function (scrollElement, scrollDown) {
    scrollElement.current.scrollHeight - scrollElement.current.scrollTop < 1024 ||
        setTimeout(scrollDown(scrollElement), 100);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStylesHeader } from '@reducers/stylesReducer';
import fastDeepEqual from 'fast-deep-equal';
import applySpec from 'ramda/es/applySpec';
import { env } from '@mdca/wde';
import { rawResizableActions } from '../../../redux/actions/resizable';
import { selectCurrentPosition, selectCurrentSize, selectIsFullScreen, selectWindowCurrentHeight, selectWindowCurrentWidth, } from '../../../redux/selectors/resizable';
import { useMuted } from '../../hooks/useMuted';
import { Menu } from './Menu/Menu';
import { KebabMenu } from './kebab_menu';
import { HeaderWrapper } from './StyledHeader';
var selector = applySpec({
    currentSize: selectCurrentSize,
    windowCurrentWidth: selectWindowCurrentWidth,
    windowCurrentHeight: selectWindowCurrentHeight,
    currentPosition: selectCurrentPosition,
    isFullScreen: selectIsFullScreen,
    stylesHeader: getStylesHeader,
});
export function Header(props) {
    var _a, _b;
    var lang = props.lang, isAuthHeader = props.isAuthHeader, toggleEnabled = props.toggleEnabled, _c = props.isDisableMoreMenu, isDisableMoreMenu = _c === void 0 ? false : _c;
    var dispatch = useDispatch();
    var divRef = React.useRef(null);
    var startMousePosition = React.useRef({ x: 0, y: 0 });
    var _d = useMuted(), isMuted = _d.isMuted, setMute = _d.setMute;
    var _e = React.useState(false), showMenu = _e[0], setShowMenu = _e[1];
    var _f = useSelector(selector, fastDeepEqual), currentSize = _f.currentSize, windowCurrentWidth = _f.windowCurrentWidth, windowCurrentHeight = _f.windowCurrentHeight, currentPosition = _f.currentPosition, isFullScreen = _f.isFullScreen, stylesHeader = _f.stylesHeader;
    var logoUrl = stylesHeader.logoUrl, moreIcon = stylesHeader.moreIcon, headerText = stylesHeader.headerText, minimizeIcon = stylesHeader.minimizeIcon, headerHeight = stylesHeader.headerHeight, headerBackground = stylesHeader.headerBackground, disableDraggable = stylesHeader.disableDraggable, headerBorderColor = stylesHeader.headerBorderColor, disableHeaderLogo = stylesHeader.disableHeaderLogo, disableHeaderHideChatWindow = stylesHeader.disableHeaderHideChatWindow;
    var handleDrag = React.useCallback(function (e) {
        var distanceY = startMousePosition.current.y - e.pageY;
        var distanceX = startMousePosition.current.x - e.pageX;
        var newPositionY = currentPosition.bottom + distanceY > 0 ? currentPosition.bottom + distanceY : 0;
        var newPositionX = currentPosition.right + distanceX > 0 ? currentPosition.right + distanceX : 0;
        if (windowCurrentHeight - (newPositionY + currentSize.height) < 0) {
            newPositionY = windowCurrentHeight - currentSize.height;
        }
        if (windowCurrentWidth - (newPositionX + currentSize.width) < 0) {
            newPositionX = windowCurrentWidth - currentSize.width;
        }
        dispatch(rawResizableActions.setCurrentPosition({ bottom: newPositionY, right: newPositionX }));
        sessionStorage.setItem('AskMeWebLibrary-current-position', JSON.stringify({
            bottom: newPositionY,
            right: newPositionX,
        }));
    }, [
        currentPosition.bottom,
        currentPosition.right,
        currentSize.height,
        currentSize.width,
        dispatch,
        windowCurrentHeight,
        windowCurrentWidth,
    ]);
    var handleMouseMove = React.useCallback(function (event) {
        if (event.buttons !== 1) {
            return;
        }
        handleDrag(event);
    }, [handleDrag]);
    var handleMouseDown = React.useCallback(function (event) {
        if (event.buttons !== 1 || isFullScreen) {
            return;
        }
        startMousePosition.current = { x: event.pageX, y: event.pageY };
        var controller = new AbortController();
        var handleMouseUp = function (e) {
            // eslint-disable-next-line no-bitwise
            if (e.buttons & 1) {
                return;
            }
            controller.abort();
        };
        window.addEventListener('mousemove', handleMouseMove, { signal: controller.signal });
        window.addEventListener('mouseup', handleMouseUp, { signal: controller.signal });
    }, [handleMouseMove, isFullScreen]);
    React.useEffect(function () {
        var element = divRef.current;
        if (element) {
            element.addEventListener('mousedown', handleMouseDown);
        }
        return function () {
            if (element) {
                element.removeEventListener('mousedown', handleMouseDown);
            }
        };
    }, [handleMouseDown]);
    var handelFullScreen = React.useCallback(function () {
        dispatch(rawResizableActions.setIsFullScreen(!isFullScreen));
    }, [isFullScreen, dispatch]);
    return (React.createElement(HeaderWrapper, __assign({}, { headerHeight: headerHeight, headerBackground: headerBackground, disableDraggable: disableDraggable, isFullScreen: isFullScreen, headerBorderColor: headerBorderColor, showMenu: showMenu, lang: lang }),
        React.createElement(Menu, __assign({}, { lang: lang, showMenu: showMenu, setShowMenu: setShowMenu, isMuted: isMuted, isAuthHeader: isAuthHeader, isFullScreen: isFullScreen, setMute: setMute }, { toggleFullScreen: handelFullScreen })),
        React.createElement("div", { className: "AskMeWebLibrary-header" },
            React.createElement("div", { className: "AskMeWebLibrary-header__drug", onDoubleClick: handelFullScreen, ref: divRef }),
            React.createElement("div", { className: "AskMeWebLibrary-header__logo" },
                disableHeaderLogo && React.createElement("img", { alt: "log", src: logoUrl || "".concat(env('ICON_WEB_UI'), "/logo.svg"), width: "60" }),
                !disableHeaderLogo && headerText && (React.createElement("div", { className: "header-text" },
                    React.createElement("p", null, (_a = headerText[lang]) === null || _a === void 0 ? void 0 : _a.headerTextTop),
                    React.createElement("p", null, (_b = headerText[lang]) === null || _b === void 0 ? void 0 : _b.headerTextBottom)))),
            React.createElement("div", { className: "AskMeWebLibrary-header__buttons" },
                !isDisableMoreMenu &&
                    (moreIcon ? (React.createElement("div", { role: "button", tabIndex: 0, className: "AskMeWebLibrary-header__buttons-more", onClick: function () { return setShowMenu(function (prev) { return !prev; }); }, onKeyDown: function (e) {
                            if (e.key === 'Enter' || e.key === ' ') {
                                setShowMenu(function (prev) { return !prev; });
                            }
                        } },
                        React.createElement("img", { id: "more-button", src: moreIcon, alt: "more-button" }))) : (React.createElement("div", { role: "button", tabIndex: 0, className: "AskMeWebLibrary-header__buttons-menu", onClick: function () { return setShowMenu(function (prev) { return !prev; }); }, onKeyDown: function (e) {
                            if (e.key === 'Enter' || e.key === ' ') {
                                setShowMenu(function (prev) { return !prev; });
                            }
                        } },
                        React.createElement(KebabMenu, null)))),
                disableHeaderHideChatWindow && (React.createElement("div", { role: "button", tabIndex: 0, className: "AskMeWebLibrary-header__buttons-minimize", onClick: function () { return toggleEnabled(); }, onKeyDown: function (e) {
                        if (e.key === 'Enter' || e.key === ' ') {
                            toggleEnabled();
                        }
                    } },
                    React.createElement("img", { id: "minimize-button", src: minimizeIcon || "".concat(env('ICON_WEB_UI'), "/minimizeIcon.svg"), alt: "minimize" })))))));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { webSocketActionType } from '@type';
export var WORK_WS_REDUCER_NAMESPACE = 'work-ws';
var initialWorkWSState = {
    isConnectedWebSocket: false,
    isErrorServer: false,
    isTypingAgent: false,
    timeNow: null,
    isLoaderSendFile: false,
    isAgentOnline: true,
};
// eslint-disable-next-line @typescript-eslint/default-param-last
export var workWSReducer = function (state, action) {
    var _a, _b;
    if (state === void 0) { state = initialWorkWSState; }
    switch (action.type) {
        case webSocketActionType.ERROR_CONNECTION_SAGA: {
            return __assign(__assign({}, state), { isErrorServer: action.payload });
        }
        case webSocketActionType.SET_IS_CONNECTED_WEB_SOCKET: {
            return __assign(__assign({}, state), { isConnectedWebSocket: action.payload });
        }
        case webSocketActionType.SET_IS_TYPING_AGENT: {
            return __assign(__assign({}, state), { isTypingAgent: action.payload, timeNow: Date.now() });
        }
        case webSocketActionType.SET_LOADER_SEND_FILE: {
            return __assign(__assign({}, state), { isLoaderSendFile: action.payload });
        }
        case webSocketActionType.SET_AGENT_ONLINE_STATUS: {
            return __assign(__assign({}, state), { isAgentOnline: ((_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.onlineStatus) === 2 });
        }
        default: {
            return state;
        }
    }
};
export var getTimeNow = function (state) { return state[WORK_WS_REDUCER_NAMESPACE].timeNow; };
export var getAgentOnlineStatus = function (state) { return state[WORK_WS_REDUCER_NAMESPACE].isAgentOnline; };
export var getIsErrorServer = function (state) { return state[WORK_WS_REDUCER_NAMESPACE].isErrorServer; };
export var getIsTypingAgent = function (state) { return state[WORK_WS_REDUCER_NAMESPACE].isTypingAgent; };
export var getIsConnectedWebSocket = function (state) { return state[WORK_WS_REDUCER_NAMESPACE].isConnectedWebSocket; };
export var getIsLoaderSendFile = function (state) { return state[WORK_WS_REDUCER_NAMESPACE].isLoaderSendFile; };

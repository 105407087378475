import React from 'react';
import { processedSenderTypes } from '@models/messages/processedMessages';
import isEqual from 'lodash/isEqual';
import BotMessage from '../BotMessage';
import { getIconStatus } from '../logic';
import SystemMessages from '../SystemMessages/SystemMessages';
import UserMessage from '../UserMessage/UserMessage';
var PrintHistory = function (_a) {
    var messagesHistory = _a.messagesHistory, lang = _a.lang, scrollElement = _a.scrollElement, isFullScreen = _a.isFullScreen;
    return (React.createElement(React.Fragment, null, messagesHistory &&
        messagesHistory.map(function (message, index, array) { return (React.createElement("div", { key: message.data.responseId, style: lang === 'ar' ? { textAlign: 'right' } : {} },
            message.type === processedSenderTypes.userMessage &&
                (message.data.type === 1 ||
                    message.data.type === 3 ||
                    message.data.type === 6 ||
                    message.data.type === 7) &&
                !message.data.deleted && (React.createElement(UserMessage, { value: message.data, hasIcon: getIconStatus(message, array[index - 1]), scrollElement: scrollElement, key: "".concat(index, "userMessage") })),
            message.type === processedSenderTypes.agentMessage &&
                (message.data.type === 1 ||
                    message.data.type === 3 ||
                    message.data.type === 6 ||
                    message.data.type === 7) &&
                !message.data.deleted && (React.createElement(BotMessage, { value: message, hasIcon: getIconStatus(message, array[index - 1]), isFullScreen: isFullScreen, scrollElement: scrollElement, lang: lang, avatar: message.avatar, agentName: message.agentName })),
            message.type === processedSenderTypes.botMessage && !message.data.deleted && (React.createElement(BotMessage, { value: message, hasIcon: getIconStatus(message, array[index - 1]), isFullScreen: isFullScreen, scrollElement: scrollElement, lang: lang, avatar: message.avatar, agentName: message.agentName })),
            message.data.type === 8 && React.createElement(SystemMessages, { scrollElement: scrollElement, type: 8, lang: lang }),
            message.data.type === 9 && (React.createElement(SystemMessages, { scrollElement: scrollElement, type: 9, time: message.data.time, text: message.data.responseText, lang: lang })))); })));
};
var areEqual = function (prevProps, nextProps) {
    return isEqual({ msgs: prevProps.messagesHistory, lang: prevProps.lang }, { msgs: nextProps.messagesHistory, lang: nextProps.lang });
};
export default React.memo(PrintHistory, areEqual);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { workStatesActions } from '@actions';
import localization from '@localization';
import { getStylesAuth } from '@reducers/stylesReducer';
import { getCurrentAuthStep, getFormData } from '@reducers/workAppReducer';
import AuthInput from '../components/AuthInput/AuthInput';
import Buttons from '../components/Buttons';
import ErrorValidMessage from '../components/ErrorValidMessage/ErrorValidMessage';
var Phone = function (_a) {
    var _b, _c, _d;
    var lang = _a.lang, stepsLength = _a.stepsLength, saveFormData = _a.saveFormData, onSignUp = _a.onSignUp, isPrivacyPolicy = _a.isPrivacyPolicy, PP = _a.PP;
    var formData = useSelector(getFormData);
    var current = useSelector(getCurrentAuthStep);
    var _e = useSelector(getStylesAuth), labelColor = _e.labelColor, inputColor = _e.inputColor, labelErrorColor = _e.labelErrorColor, inputBackground = _e.inputBackground, signUpColor = _e.signUpColor, signUpBackground = _e.signUpBackground;
    var dispatch = useDispatch();
    var _f = useForm({ defaultValues: formData }), register = _f.register, errors = _f.formState.errors, getValues = _f.getValues, handleSubmit = _f.handleSubmit;
    var onSubmit = function (data) {
        saveFormData(data);
        if (current + 1 === stepsLength) {
            onSignUp(data);
            return;
        }
        dispatch(workStatesActions.setCurrentAuthStep(current + 1));
    };
    var prev = function () {
        dispatch(workStatesActions.setCurrentAuthStep(current - 1));
        saveFormData({ phone: getValues('phone') });
    };
    var text = localization[lang]['phone.label'];
    if (((_b = errors.phone) === null || _b === void 0 ? void 0 : _b.type) === 'required')
        text = localization[lang]['error.required'];
    if (((_c = errors.phone) === null || _c === void 0 ? void 0 : _c.type) === 'pattern')
        text = localization[lang]['error.validation'];
    if (((_d = errors.phone) === null || _d === void 0 ? void 0 : _d.type) === 'minLength')
        text = localization[lang]['error.validation'];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "steps-content" },
            React.createElement(ErrorValidMessage, { text: text, lang: lang, style: errors.phone ? { color: labelErrorColor || 'red' } : { color: labelColor || '#434343' } }),
            React.createElement(AuthInput, { maxLength: 14, placeholder: "* ".concat(localization[lang]['please.write.your'], " ").concat(localization[lang]['phone.placeholder']), register: __assign({}, register('phone', {
                    required: true,
                    pattern: /^(\s*)?(\+)?([ ()+:=_-]?\d[ ()+:=_-]?){10,14}(\s*)?$/,
                    minLength: 10,
                })), style: errors.phone
                    ? {
                        border: '1px solid red',
                        color: inputColor || 'rgba(0, 0, 0, 0.65)',
                        backgroundColor: inputBackground || '#fff',
                    }
                    : {
                        color: inputColor || 'rgba(0, 0, 0, 0.65)',
                        backgroundColor: inputBackground || '#fff',
                    } })),
        PP,
        React.createElement("div", { className: "steps-btn" },
            React.createElement(Buttons, __assign({}, {
                lang: lang,
                current: current,
                stepsLength: stepsLength,
                signUpColor: signUpColor,
                signUpBackground: signUpBackground,
                handleSubmit: handleSubmit,
                onSubmit: onSubmit,
                prev: prev,
                isPrivacyPolicy: isPrivacyPolicy,
            })))));
};
export default Phone;

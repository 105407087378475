import { randomId } from './generateUniqueId';
export var setStorageClientInfo = function (userInfo, isLocalStorage) {
    var clientInfo = {
        phone: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.phone) || null,
        userId: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.userId) || randomId(),
        lastName: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.lastName) || null,
        userName: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.userName) || null,
        firstName: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.firstName) || null,
        userEmail: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.userEmail) || null,
        invoiceNumber: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.invoiceNumber) || null,
        accountNumber: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.accountNumber) || null,
    };
    (isLocalStorage ? localStorage : sessionStorage).setItem('AskMeWebLibrary-user-phone', JSON.stringify(clientInfo.phone));
    (isLocalStorage ? localStorage : sessionStorage).setItem('AskMeWebLibrary-user-name', JSON.stringify(clientInfo.userName));
    (isLocalStorage ? localStorage : sessionStorage).setItem('AskMeWebLibrary-user-email', JSON.stringify(clientInfo.userEmail));
    (isLocalStorage ? localStorage : sessionStorage).setItem('AskMeWebLibrary-user-lastName', JSON.stringify(clientInfo.lastName));
    (isLocalStorage ? localStorage : sessionStorage).setItem('AskMeWebLibrary-user-firstName', JSON.stringify(clientInfo.firstName));
    (isLocalStorage ? localStorage : sessionStorage).setItem('AskMeWebLibrary-user-id', JSON.stringify(clientInfo.userId.toString()));
    (isLocalStorage ? localStorage : sessionStorage).setItem('AskMeWebLibrary-user-invoiceNumber', JSON.stringify(clientInfo.invoiceNumber ? clientInfo.invoiceNumber.toString() : clientInfo.invoiceNumber));
    (isLocalStorage ? localStorage : sessionStorage).setItem('AskMeWebLibrary-user-accountNumber', JSON.stringify(clientInfo.accountNumber ? clientInfo.accountNumber.toString() : clientInfo.accountNumber));
};

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isFullScreen: false,
    chatIconAlignment: {
        rightPosition: 45,
        bottomPosition: 95,
    },
    chatWindowAlignment: {
        rightPosition: 45,
        bottomPosition: 95,
    },
    windowCurrentWidth: 0,
    windowCurrentHeight: 0,
    currentSize: {
        width: 350,
        height: 550,
    },
    chatWindowPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    currentPosition: {
        bottom: 95,
        right: 20,
    },
};
var name = 'resizableRuntime';
export var resizableSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        setIsFullScreen: function (draft, _a) {
            var payload = _a.payload;
            draft.isFullScreen = payload;
        },
        setChatIconAlignment: function (draft, _a) {
            var payload = _a.payload;
            draft.chatIconAlignment = payload;
        },
        setChatWindowAlignment: function (draft, _a) {
            var payload = _a.payload;
            draft.chatWindowAlignment = payload;
        },
        setWindowCurrentWidth: function (draft, _a) {
            var payload = _a.payload;
            draft.windowCurrentWidth = payload;
        },
        setWindowCurrentHeight: function (draft, _a) {
            var payload = _a.payload;
            draft.windowCurrentHeight = payload;
        },
        setCurrentSize: function (draft, _a) {
            var payload = _a.payload;
            draft.currentSize = payload;
        },
        setChatWindowPadding: function (draft, _a) {
            var payload = _a.payload;
            draft.chatWindowPadding = payload;
        },
        setCurrentPosition: function (draft, _a) {
            var payload = _a.payload;
            draft.currentPosition = payload;
        },
    },
});

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export default styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n  margin-left: ", ";\n  margin-top: ", ";\n  word-wrap: break-word;\n  white-space: pre-line;\n  font-family: Roboto, sans-serif;\n  font-size: 13px;\n  line-height: 14px;\n  border-top-left-radius: 0;\n  border-bottom-right-radius: 10px;\n  border-bottom-left-radius: 10px;\n  max-width: 100%;\n  position: relative;\n\n  a {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  a:visited {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  a:hover {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  a:active {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  ", ";\n"], ["\n  padding: ", ";\n  margin-left: ", ";\n  margin-top: ", ";\n  word-wrap: break-word;\n  white-space: pre-line;\n  font-family: Roboto, sans-serif;\n  font-size: 13px;\n  line-height: 14px;\n  border-top-left-radius: 0;\n  border-bottom-right-radius: 10px;\n  border-bottom-left-radius: 10px;\n  max-width: 100%;\n  position: relative;\n\n  a {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  a:visited {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  a:hover {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  a:active {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  ", ";\n"])), function (props) { return (props.imgStyle ? '10px 10px 10px 0px' : '10px 10px 10px 0px'); }, function (props) { return (props.hasIcon ? '5px' : '60px'); }, function (props) { return (props.hasIcon ? '30px' : '5px'); }, function (props) { return props.agentLink || '#021D61'; }, function (props) { return props.agentVisitedLink || '#00aced'; }, function (props) { return props.agentHoverLink || '#f87007'; }, function (props) { return props.agentActiveLink || '#1972f5'; }, function (props) {
    return props.isEdited &&
        " \n  &::before {\n    content: \"\\270E\";\n    position: absolute;\n    right: -25px;\n    top: calc(50% - 9px);\n    font-size: 18px;\n    color: #434343;\n    transform: scale(-1, 1);\n  }\n  ";
});
var templateObject_1;

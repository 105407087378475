var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ContextMenuItemTypes } from '@models/components/contextMenu';
import { useActions } from '../../../hooks/useActions';
import { useImgDownload } from '../../../hooks/useImgDownload';
export var useContextMenuActions = function () {
    var downloadImg = useImgDownload().downloadImg;
    var setIsQuoting = useActions().setIsQuoting;
    var handleClick = function (e, messageData) {
        var _a;
        e.preventDefault();
        var type = messageData.type, data = messageData.data;
        if (type === ContextMenuItemTypes.quote) {
            setIsQuoting({ data: __assign({}, data), state: true });
        }
        else if (type === ContextMenuItemTypes.imgDownload) {
            var imgUrl = data.type === 7 ? (_a = data.quotedMessage) === null || _a === void 0 ? void 0 : _a.text : data.responseText;
            downloadImg(imgUrl);
        }
    };
    return { handleClick: handleClick };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useDispatch } from 'react-redux';
import { initializationActions, webSocketActions, workStatesActions } from '@actions';
import { bindActionCreators } from 'redux';
export var useActions = function () {
    var dispatch = useDispatch();
    return bindActionCreators(__assign(__assign(__assign({}, workStatesActions), webSocketActions), initializationActions), dispatch);
};
